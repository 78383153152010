// .core
import React, {useState, useCallback, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import cx from 'classnames';
// components
import { Button, ButtonIcon, Icon, Flex, SearchFilter, Switch } from 'components';
// i18n
import i18n from 'i18n'
// styles
import css from './Sessions.module.scss'
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
//stores
import { useStore } from 'stores';
//utils & partials
import { changeTitle } from 'views/routes';
import { SessionsTable } from './partials/SessionsTable';
import { RecordingsTable } from './partials/RecordingsTable';
import { AppHeader } from './partials/AppHeader/AppHeader';
import { ThreadDumpsTable } from './partials/ThreadDumpsTable';

export const Sessions = observer(() => {
    const {globalStore, sessionsStore, permissionsStore } = useStore();
    const [tab, setTab] = useState<'sessions' | 'closedSessions' | 'recordings' | 'threadDumps'>('sessions');
    const {app} = useParams<{app:string}>();
    const {t} = useTranslation()

    const changeTab = useCallback((tab: 'sessions' | 'closedSessions' | 'recordings' | 'threadDumps') => {
        setTab(tab);
        //loadIssues(1, 'active' === tab ? 'ListActive' : 'ListHistory')
        if(tab==='closedSessions') {
            sessionsStore.setExtendTableToggle(false)
        }
    }, [setTab])

    const handleSeach = (event:React.ChangeEvent<HTMLInputElement>) => {
        sessionsStore.setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (!app && (tab === 'recordings' || tab === 'threadDumps')) {
            setTab('sessions');
        }
        globalStore.updateApp(app);
        sessionsStore.setAppPath(app);
        sessionsStore.refresh();
        return () => sessionsStore.clearInterval();
    },[app, tab]);

    changeTitle(i18n.t('routes.sessions'));

    return (
        <div className={css.root}>
            <AppHeader app={app} />
            <Flex className={css.headerRow} justifyContent="space-between" alignItems="center">
                <Flex className={css.btnstab}> 
                    <ButtonIcon 
                        className={css.rightSpace}
                        icon='check' 
                        active={'sessions' === tab} 
                        onClick={()=>changeTab('sessions')}
                        >
                        {t('status.running')}
                    </ButtonIcon>
                    <ButtonIcon
                        className={css.rightSpace}
                        icon='timer' 
                        active={'closedSessions' === tab} 
                        onClick={()=>changeTab('closedSessions')}
                        >
                        {t('status.finished')}
                    </ButtonIcon>
                    {
                        app && permissionsStore.get('playbackRecording') &&
                        <ButtonIcon
                            className={css.rightSpace}
                            icon='videocam' 
                            active={'recordings' === tab} 
                            onClick={()=>changeTab('recordings')}
                            >
                            {t('sessions.tab.recordings')}
                        </ButtonIcon>
                    }
                    {
                        app && permissionsStore.get('getThreadDump') &&
                        <ButtonIcon
                            className={css.rightSpace}
                            icon='view_list' 
                            active={'threadDumps' === tab} 
                            onClick={()=>changeTab('threadDumps')}
                        >
                            {t('sessions.tab.threadDumps')}
                        </ButtonIcon>
                    }
                </Flex>
                <Flex spacing={3}>
                    {
                        tab==='sessions'&&
                        <Switch value={sessionsStore.extendTableToggle} size='large' onClick={()=>sessionsStore.setExtendTableToggle()}>{t('sessions.extendedTable')}</Switch>
                    }
                    
                    <Button.UI color={'neutral'} className={cx(cssTab.wTab,css.refresh)} onClick={()=>sessionsStore.refresh()} >
                        <Icon size='small' name="cached" /> {sessionsStore.lastUpdated}
                    </Button.UI>
                    {!sessionsStore.filterToggle && <Button color='neutral' icon={'search'} onClick={()=>sessionsStore.setFilterToggle()} /> } 
                    {sessionsStore.filterToggle && <SearchFilter value={sessionsStore.searchTerm} onChange={handleSeach} onClose={()=>sessionsStore.setFilterToggle()} />}
                </Flex>
            </Flex>
            {
                (tab === 'sessions' || tab === 'closedSessions') && <SessionsTable tab={tab} app={app} />
            }
            {
                (tab === 'recordings') && permissionsStore.get('playbackRecording') && <RecordingsTable app={app} />
            }
            {
                (tab === 'threadDumps') && permissionsStore.get('getThreadDump') && <ThreadDumpsTable app={app} />
            }
        </div>
    )
})
