import { makeAutoObservable, runInAction } from 'mobx'
import { LogsService } from 'services'
import { LogsOverview as ILogsOverview, SessionLogInfo, SwingSession } from 'gen/models'
import { ApiServerUrl, ILogsEntry, ILogType, LogTypes, toPath, getSecuritySuffix } from 'utils';
import { IBtndropProps } from 'components/basic/Dropdown/Dropdown';
import { RootStore } from './RootStore';

class logsStore {
  private rootStore: RootStore | undefined;
  logSession?: SwingSession;
  logs?: ILogsEntry[];
  log?: any[];
  logTimer: NodeJS.Timeout | undefined;
  logStartOffset: number | undefined;
  logEndOffset: number = -1;
  instanceId: string = ''
  appInstanceIds: SessionLogInfo[] = []
  logsOverview: ILogsOverview = {};
  serverLogId: string | undefined;
  spLogId: string | undefined;
  loading: boolean = false;
  interval: NodeJS.Timeout | undefined;
  lastUpdated: string | undefined;
  type: ILogType | undefined;
  searchTerm: string = '';
  filterToggle: boolean = false;
  compact: boolean = true;
  logsToken: string | null | undefined;

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore;
  }

  setInstanceId(id: string) {
    if(id !== this.instanceId) {
      this.instanceId = id
    }
  }

  setCompact(bool?: boolean) {
    this.compact = bool ? bool : !this.compact;
  }

  setFilterToggle(bool?: boolean) {
    this.searchTerm = ''
    this.filterToggle = bool ? bool : !this.filterToggle;
  }

  setSearchTerm(term: string) {
    if(this.searchTerm !== term) {
      this.searchTerm = term;
    }
  }

  setServerLogId(id: string) {
    if(id !== this.serverLogId) {
      this.clearInterval()
      this.serverLogId = id
      this.refresh('',this.type?.url!)
    }
  }

  setSpLogId(id: string) {
    if(id !== this.spLogId) {
      this.clearInterval()
      this.spLogId = id
      this.refresh('','sessionpool')
    }
  }

  loadLogsOverview(app: string) {
    this.loading = true;
    return LogsService
      .getLogsOverview()
      .then((data: ILogsOverview | undefined) => {
        runInAction(() => {
          if(data) {
            this.logsOverview = data;
            this.serverLogId = this.serverLogId ? this.serverLogId : data.servers![0]?.id;
            this.spLogId = this.spLogId ? this.spLogId : data.sessionPools![0]?.id;
            this.logsToken = data.token;
            this.appInstanceIds = data.sessions!
            .filter((item) =>item.app === '/'+app)
            .sort((x, y)=> Number(y.lastModified) - Number(x.lastModified))
            this.loading = false;
          }
        })
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  load(size: number, start: number, backwards: boolean, app: string, type: string) {
    const params: any = {
      backwards,
      offset: start,
      max: size,
      instanceId: undefined
    };
    this.loading = true;
    if (backwards) {
      this.log = this.logs = [];
    }
    if (this.type?.url === 'sessionpool' && this.isCluster) {
      return LogsService
        .getSessionPoolLogs(this.spLogId!, params)
        .then((data: any) => {
          runInAction(() => {
            this.handleLogResponse(data, backwards, type);
          })
        })
        .catch((e: any) => {
          console.error(e);
        });
    }

    if (this.type && this.isSessionType(this.type) || (app !== undefined && this.instanceId)) {
      params.instanceId = this.instanceId || null;
      if (app) {
        return LogsService
          .getSessionLog(this.spLogId!, this.instanceId, toPath('',app), params)
          .then((data: any) => {
            runInAction(() => {
              this.handleLogResponse(data, backwards, type);
            })
          })
          .catch((e: any) => {
            console.error(e);
          });
      } else {
        return Promise.resolve();
      }
    } else if (this.type && this.isAdminConsoleType(this.type)) {
      return LogsService
        .getAdminConsoleLogs(params)
        .then((data: any) => {
          runInAction(() => {
            this.handleLogResponse(data, backwards, type);
          })
        })
        .catch((e: any) => {
          console.error(e);
      });
    } else {
      return LogsService
        .getServerLogs(this.serverLogId!, type, params)
        .then((data: any) => {
          runInAction(() => {
            this.handleLogResponse(data, backwards, type);
          })
        })
        .catch((e: any) => {
          console.error(e);
      });
    }
  }

  refresh(app: string,type: string) {
    this.load(100 * 1024, -1, true,app,type)
    .then(() => this.loadDelta(app,type))
    .catch((e: any) => {
      console.error(e);
    });
  }

  setType(type: string) {
      let t: ILogType;
      for (t of LogTypes) {
          if (t.url === type) {
            this.type = t;
          }
      }
  }

  loadSessionLogs(app: string, instanceId: string) {
    clearTimeout(this.logTimer!)
    this.instanceId = instanceId;
    this.logSession = this.rootStore?.sessionsStore.session;
    this.refresh(app, 'session');
  }

  loadDelta(app: string, type: string) {
    return this.load(100 * 1024, this.logEndOffset!, false, app, type)
      .then(() => {
        this.clearInterval();
        this.setInterval(() => this.loadDelta(app, type), 2000);
      })
      .catch(() => {
        this.clearInterval();
      });
  }

  downloadLogs(type: string) {
    if (type === 'sessionpool') {
      let url = this.rootStore!.globalStore.serverUrl! + "/rest/logs/sessionpool/" + encodeURIComponent(this.spLogId!) + '/' + (this.logsToken || '');
      url += ((url.indexOf("?") !== -1) ? "&" : "?") + "X-webswing-securitySuffix=" + getSecuritySuffix();
      window.open(url, "_blank");
    } else if (type === 'admin') {
      let url = ApiServerUrl + "/rest/logs/console/" + (this.logsToken || '');
      url += ((url.indexOf("?") !== -1) ? "&" : "?") + "X-webswing-securitySuffix=" + getSecuritySuffix();
      window.open(url, "_blank");
    } else {
      let url = ApiServerUrl + "/rest/logs/server/" + encodeURIComponent(this.serverLogId!) + '/' + encodeURIComponent(type) + '/' + (this.logsToken || '');
      url += ((url.indexOf("?") !== -1) ? "&" : "?") + "X-webswing-securitySuffix=" + getSecuritySuffix();
      window.open(url, "_blank");
    }
  }
  
  downloadSessionLogs(app: string, sessionPoolId?: string, instanceId?: string) {
    let url = this.rootStore!.globalStore.serverUrl! + '/rest/logs/session/download?appPath=' + encodeURIComponent(app);
    if (instanceId) {
      url += `&instanceId=${encodeURIComponent(instanceId)}`
    }
    if (!sessionPoolId) {
      sessionPoolId = this.spLogId;
    }
    url += `&sessionPoolId=${encodeURIComponent(sessionPoolId!)}`
    url += "&token=" + (this.logsToken || '');
    url += "&X-webswing-securitySuffix=" + getSecuritySuffix();
    window.open(url, "_blank");
  }

  get searchTermLowerCase() {
    return this.searchTerm.toLocaleLowerCase()
  }

  get dropdownServerButtons() {
    const arr: IBtndropProps[] = []
    this.logsOverview.servers?.forEach(btn => {
      arr.push({label:btn.id, onClick:()=>this.setServerLogId(btn.id!)})
    });
    return arr
  }
  
  get dropdownSpButtons() {
    const arr: IBtndropProps[] = []
    this.logsOverview.sessionPools?.forEach(btn => {
      arr.push({label:btn.id, onClick:()=>this.setSpLogId(btn.id!)})
    });
    return arr
  }

  get isCluster() {
    return this.logsOverview.servers ? this.logsOverview.servers[0].cluster : false
  }

  get isDropButtonVisible() {
    if (this.logsOverview && this.type?.url === "sessionpool") {
      return this.logsOverview.sessionPools!.length > 1
    } else {
      return this.logsOverview.servers!.length > 1
    }
  }

  private handleLogResponse(data: any, backwards: boolean, type: string) {
    if (data && data.log && data.log.length > 0) {
        const logl = data.log.split(this.type?.lineDelimiter);
        if (backwards) {
          this.log = logl?.concat(this.log!);
          this.logStartOffset = data?.startOffset;
          this.logEndOffset = Math.max(Number(this.logEndOffset), Number(data?.endOffset));
        } else {
          this.log = this.log?.concat(logl);
          this.logStartOffset = Math.min(Number(this.logStartOffset), Number(data?.startOffset));
          this.logEndOffset = Number(data?.endOffset);
        }
        this.setCollection(type);
      }
      this.lastUpdated = String(new Date());
      this.loading = false;
  }

  private setInterval(callback:any, delay:number) {
    this.interval = setInterval(() => {
      callback()
    }, delay);
  }
  clearInterval() {
    if(this.interval) {
      clearInterval(this.interval);
    }
  }

  private isSessionType = (type: ILogType) => {
    return type.url === "session";
  }

  private isAdminConsoleType = (type: ILogType) => {
    return type.url === "admin";
  }

  private split = (line: string) => {
    if (this.type?.delimiter != null) {
      return line ? line.split(this.type.delimiter):'';
    } else {
      return [line];
    }
  }

  private setCollection(type: string) {
    let logs = this.log?.map((line)=>this.split(line));
    logs = logs?.filter((entry) => Array.isArray(entry));
    if(type === 'audit') {
        this.logs = logs?.map(([timestamp, subject, value, user, secured_path, ip, ...detail]) => (
          {timestamp, subject, value, user, secured_path, ip, detail: detail.join(" | ")}
        ));
    } else {
        this.logs = logs?.map(([msgs]) => ({msgs}));
    }
  }

}

export default logsStore;
