// .core
import React, { ReactNode } from 'react'
// components
import { Icon } from '../Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './NoData.module.scss'
// utils
import { TIcon } from 'utils'

import i18n from 'i18n'

export interface INoDataProps {
  className?: string
  icon?: TIcon
  message?: string
  children?: ReactNode
}

export const NoData: React.FC<INoDataProps> = ({
  className,
  icon = 'info',
  message = i18n.t('general.noData'),
  children,
}: INoDataProps) => {
  return (
    <div className={cx(css.wNoData, className)}>
      {/* ICON */}
      <Icon name={icon} />
  
      {/* MESSAGE */}
      <span>{message}</span>
      {children}
    </div>
  )
}

