// .core
import React, { ReactNode } from 'react'
// components
import { Icon, IconColor, Spinner } from 'components';

// libraries
import cx from 'classnames'
// styles
import css from './ButtonDefault.module.scss'

export interface IButtonDefaultProps {
  bDisabled?: boolean
  bLoading?: boolean

  className?: string
  classNameLabel?: string

  label?: string
  icon?: string | ReactNode
  size?: 'small' | 'medium' | 'large' | string
  type?: 'submit' | 'reset' | 'button'
  color?: 'danger' | 'neutral' | 'blue' | 'neutralTrans' | 'white' | string
  iconColor?: IconColor
  hover?: 'shadow' | 'transparent'

  /**
   * for rare use cases, needed by <Avatar />
   */
  style?: React.CSSProperties
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

/**
 * Default button component w/ label & loading spinner support
 */
export const ButtonDefault = ({
  bDisabled,
  bLoading,
  color = 'blue',
  size = 'medium',
  iconColor = 'white',
  className,
  classNameLabel,
  hover,
  label,
  icon,
  type,
  style,
  onClick,
}: IButtonDefaultProps) => (
  <button
    className={cx(
      css.wButtonDefault,
      bLoading && css.bLoading,
      className,
      css[color],
      css[size],
      hover && css[hover],
      {
        [css.icon]: icon,
      }
    )}
    disabled={bDisabled || bLoading}
    style={style}
    type={type || 'button'}
    onClick={onClick}>
    {/* LOADING SPINNER */}
    {bLoading ? (
      <Spinner className={css.wSpinner} />
    ) : // LABEL
    label ? (
      <label className={cx(classNameLabel)}>{label}</label>
    ) : icon && typeof icon === 'string' ? (
      <Icon size={size} name={icon} color={iconColor} />
    ) : icon ? (
      icon
    ) : null}
  </button>
)
