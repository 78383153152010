// core
import React from 'react';
import cx from 'classnames';
// components
import { Card, AvatarWithTitle } from 'components';

// styles
import css from '../Applications.module.scss';
import { Point } from 'components/basic/Point/Point';
import { Link } from 'react-router-dom';
import { InstanceManagerStatus } from 'gen';
import { useStore } from 'stores';
export interface IAppsWidgetProps {
  className?:string
  title?:string
  subtitle?: string
  src?:string
  icon?: string | undefined
  status?:InstanceManagerStatus[]
  path:string
  url?:string
  openUrl?(): void
}

export const AppsWidget = ({title,subtitle,src,icon, openUrl, status, path, className}:IAppsWidgetProps) => {
  const { globalStore } = useStore();
  return (
    <Card className={cx(css.appsWidget, className)}>
        <Link to={path} >
        <AvatarWithTitle
          src={src}
          icon={icon}
          title={title}
          subtitle={subtitle}
          className={css.latestNews}
          classes={{ subtitle: css.subtitle, avatar:css.avatar }}
          onCLick={openUrl}
          />
        {status && <>
          { globalStore.countStatuses(status,'Running') ? <Point color='success' className={css.statusPoint}/>:null}
          { globalStore.countStatuses(status,'Stopped') ? <Point color='warning' className={css.statusPoint}/>:null}
          { globalStore.countStatuses(status,'Error') ? <Point color='danger' className={css.statusPoint}/>:null}
        </>
        }
      </Link>
    </Card>
  );
};

