import React, { useEffect } from "react";
import cx from 'classnames';
import { Input, showToastErr } from "components";
import { Flex } from "components/containers";
import css from '../LeftSideMenu.module.scss'
import appIcon from 'assets/images/appjava.png';
import { ButtonGroup } from "components/basic/Button/variants/ButtonGroup/ButtonGroup";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { convertToSlug } from "utils";
import { useStore } from "stores";

interface ICreateAppItemProps {
    onSubmit: (appPath: string, appName: string) => void
    onClose:  any
}
export const CreateApp = observer(({onSubmit,onClose}:ICreateAppItemProps) => {
  const [appname, setAppname] = React.useState<string>("");
  const [apppath, setAppPath] = React.useState<string>("/");
  const {globalStore} = useStore();
  const {t} = useTranslation();
  const handleEnter = (event:React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      submit()
    }
}

  const close = ()=> {
    setAppname('');
    setAppPath('/');
    onClose();
  }

  const submit = ()=> {
    const allPathsAreDifferent = globalStore.paths?.filter((path)=>path.path===apppath).length===0
    if(allPathsAreDifferent) {
      onSubmit(apppath,appname)
    } else {
      showToastErr(t('apps.valMessageChangePath'))
      return
    }

  }

  const onChangeNameInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAppname(e.currentTarget.value)
    setAppPath('/'+convertToSlug(e.currentTarget.value))
  }

  useEffect(()=> {
    if(globalStore.isDuplicate) {
      setAppname(globalStore.app)
      setAppPath('/'+convertToSlug(globalStore.app))
    }
  },[])
  
  return (
    <Flex className={cx(css.menuItem, css.activeLink, css.addApp)}>
      <img src={globalStore.isDuplicate? globalStore.info?.path+ '/rest/appicon':appIcon} alt="appIcon"/>
      <div className={css.inputGroup}>
        <Input 
          placeholder={t('apps.createPlaceholder')} 
          classNameInput={cx(css.inputCreate)} 
          value={appname} 
          onKeyPress={handleEnter} 
          onChange={onChangeNameInput} 
          bAutoFocus
        />
        <Input 
          classNameInput={cx(css.inputCreate, css.inputSmall)} 
          value={apppath} 
          onKeyPress={handleEnter} 
          onChange={(ev)=>setAppPath('/'+convertToSlug(ev.currentTarget.value))} 
        />
      </div>
      <ButtonGroup buttons={[
        {color:'blue', icon:'check', iconColor:'white', onClick:submit},
        {color:'neutral', icon:'close', onClick:close}
      ]} />
    </Flex>
  )
})