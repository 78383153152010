// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'
import classnames from 'classnames'

// components
import { IWrapperComponentProps } from 'components'

// styles
import css from '../Header.module.scss'

export interface IMenuItemProps extends IWrapperComponentProps {
  /**
   * link where will click redirect
   */
  path: string
  /**
   * path that is currently active
   */
  activePath: string

  target?: string
}

export const MenuItem = ({ children, path, activePath, target }: IMenuItemProps) => {
  return (
    <div className={css.menuItemParent}>
      {target ? (
        <a
          href={path}
          target={target}
          className={css.menuItem}
        >
          <div className={css.text}>{children}</div>
        </a>
      ) : (
        <Link
        className={classnames(
          css.menuItem, 
          { [css.activeLink]: activePath.includes(path.split("/")[0])  }
          )}
        to={`/${path}`}>
          {/* <div className={css.text}>{children}</div> */}
          <div className={css.line} />
          <span>{children}</span>
      </Link>
        )}
    </div>
  )
}
