// core
import { useState } from 'react';
import cx from 'classnames';
// components
import { Button, Badge, Icon, IWrapperComponentProps } from 'components';
// styles
import css from '../Applications.module.scss';
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export interface ITabButtonProps extends IWrapperComponentProps {
  icon:string;
  onClick?: () => void
  name: string;
  active: boolean
}

export const TabButton = observer(({children, icon, onClick, name, active}:ITabButtonProps) => {
  const [badgeTxt, setBadgeTxt] = useState<boolean>(false)
  const { configStore } = useStore();
  const {t} = useTranslation();
  const getContent = ()=> {
    const txt = badgeTxt?t('unsaved'):' ';
    if(name==='webConfig') {
      return configStore.wcChanged?txt:''
    } else {
      return configStore.acChanged?txt:''
    }
  }

  return (
    <Button.UI className={cx(css.appTab, {[css.active]: active})}>
      <Badge
        bOnBadge
        className={cx(css.changesTracking)}
        vertical='top'
        side='left'
        color='danger'
        size='small'
        onHover={()=>setBadgeTxt(!badgeTxt)}
        content={getContent() as string}
        onClick={onClick}
      >
        <Icon className={css.appTabIcon} name={icon} onClick={onClick} /> 
      </Badge>
        {children}
    </Button.UI>
  );
});
