// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'
//stores
import {useStore} from 'stores'
// logo
import WebswingLogoWText from 'assets/images/AdminConsole.svg'
import LogoIcon from 'assets/images/logo.svg'
import { observer } from 'mobx-react'
import { ApiServerUrl, toPath } from 'utils'

export const Logo = observer(() => {
  const {globalStore} = useStore()
  const getLogoPath = (path:string, fallbackPath:string) => {
    let resultPath = path?path:fallbackPath;

    return toPath(ApiServerUrl, resultPath)
  }

  return (
    <Link to="/overview">
      {
        globalStore.leftSideMenuOpen ?
        <img src={getLogoPath(globalStore.customization?.logo!,WebswingLogoWText)} alt="logo" />
        :
        <img src={getLogoPath(globalStore.customization?.logoCollapsed!,LogoIcon)} style={{maxWidth: '40px'}} alt="logo" />
      }
    </Link>
  )
})

