// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './CounterSqueare.module.scss'
import { Tooltip } from 'components'

interface ICounterSquareProps {
  count?:number | string;
  title: string;
  tooltip?: string;
  color?: 'danger' | 'neutral' | 'blue' | 'darkBlue' | 'darkerBlue' | 'lightBlue' | 'success' | 'warning';
  className?: string,
  onClick?(): void,
  enabled?: boolean
}

export const CounterSquare = ({
  count,
  title,
  tooltip,
  color='neutral',
  className,
  onClick,
  enabled
}: ICounterSquareProps) => {

  const SquareContent = () => {
    return (
      <div onClick={onClick} className={cx(
        css.root, 
        className,
        {[css.cursor]:onClick},
        {[css[color]]: enabled || (typeof count == 'number' && count)}
      )}
      >
        <h3>{count}</h3>
        <small>{title}</small>
      </div>
    );
  };

  return (
      tooltip ?
        <Tooltip content={tooltip}>
          <span>
            <SquareContent />
          </span>
        </Tooltip>
      :
      <SquareContent />
  )
}
