// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// style
import css from './AvatarWithTitle.module.scss'
import { IComponentProps } from 'components'
import { Avatar } from 'components/basic'


export interface IAvatarWithTitleInterface extends IComponentProps {
  /**
   * title to display
   */
  title?: ReactNode
  size?: 'small' | 'medium' | 'large' | 'huge'
  /**
   * subtitle
   */
  subtitle?: ReactNode
  /**
   * src of avatar
   */
  src?: string
  icon?: string

  onCLick?(): void
}

export const AvatarWithTitle = ({
  title,
  subtitle,
  src,
  icon,
  className,
  size = 'medium',
  classes = {},
  onCLick,
  ...passingProps
}: IAvatarWithTitleInterface) => {
  const doClick = onCLick ? onCLick : () => {}

  return (
    <div className={classnames(css.root, css[size], className)}>
      <Avatar
        className={classnames(css.avatar, classes.avatar)}
        icon={icon}
        size="huge"
        shape="square"
        src={src}
        onClick={doClick}
        {...passingProps}
      />
      <div className={classnames(css.title, classes.title)} onClick={doClick}>
        {title}
        {subtitle && <div className={classnames(css.subtitle, classes.subtitle)}>{subtitle}</div>}
      </div>
    </div>
  )
}
