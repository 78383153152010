import React from 'react';
import cx from 'classnames';
import css from '../SessionView.module.scss'
import {useStore} from 'stores';
import { observer } from 'mobx-react';
import { Card, Flex, Icon } from 'components';
import { ActionCell } from 'views/Sessions/partials/ActionCell';
import { AppCell } from 'views/Sessions/partials/AppCell';
import { UserCell } from 'views/Sessions/partials/UserCell';
import { dateFormat } from 'utils';
import { StatusCell } from 'views/Sessions/partials/StatusCell';
import { useTranslation } from 'react-i18next';
import { SwingSession, SwingSessionStatusEnum } from 'gen';

interface ISessionToolBarProps {
  app:string
  id:string
}

export const SessionToolBar = observer(({app,id}:ISessionToolBarProps) => {
    const {sessionsStore} = useStore();
    const {t} = useTranslation();
    const refresh=() =>{
        sessionsStore.refreshSession(app, id);
    }
    const isFinished = (session: SwingSession) => {
        return session.status !== SwingSessionStatusEnum.RUNNING;
    };
    
    return (
        <Card className={css.toolBar}>
            <Flex className={css.wRow}>
                <ActionCell bSession className={css.Acell} session={sessionsStore.session} />
                <Flex bColumn className={cx(css.smCol,css.appCol)}>
                    <AppCell bSession session={sessionsStore.session} />
                </Flex>
                <Flex bColumn className={css.smCol} flex={1}>
                    <span className={css.label}>{t('logs.user')}:</span>
                    <UserCell session={sessionsStore.session!} />
                </Flex>
                <Flex bColumn className={css.smCol} flex={1}>
                    <span className={css.label}>{t('logs.ip')}:</span>
                    {sessionsStore.session?.userIp}
                </Flex>
                <Flex bColumn className={cx(css.smCol,css.minW)} flex={1}>
                    <span className={css.label}>{t('sessions.startTime')}:</span>
                    {dateFormat(Number(sessionsStore.session?.startedAt))}
                </Flex>
                {
                    isFinished(sessionsStore.session!) &&
                    <Flex bColumn className={cx(css.smCol,css.minW)} flex={1}>
                        <span className={css.label}>{t('sessions.endTime')}:</span>
                        {dateFormat(Number(sessionsStore.session?.endedAt))}
                    </Flex>
                }
                <Flex bColumn flex={1} className={css.smCol}>
                    <span className={css.label}>{t('general.status')}:</span>
                    <StatusCell session={sessionsStore.session!} />
                </Flex>
                <Flex bColumn flex={1} className={css.smCol}>
                    <span className={css.label}>{t('general.refresh')}:</span>
                    <Flex onClick={refresh}><Icon size='small' name="cached" /> {sessionsStore.lastUpdated}</Flex>
                </Flex>
            </Flex>
        </Card>
    )
})