// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RefreshTokenResult,
    RefreshTokenResultFromJSON,
    RefreshTokenResultToJSON,
} from '../models';

export interface LoginApiLoginRequest {
    accessId: string;
}

/**
 * no description
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     * Check server health status, returns \"ok\", \"warning\" or \"error\"
     * Server health status
     */
    async healthRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check server health status, returns \"ok\", \"warning\" or \"error\"
     * Server health status
     */
    async health(): Promise<string> {
        const response = await this.healthRaw();
        return await response.value();
    }

    /**
     * Login callback from Webswing server after successful login
     * Login
     */
    async loginRaw(requestParameters: LoginApiLoginRequest): Promise<runtime.ApiResponse<RefreshTokenResult>> {
        if (requestParameters.accessId === null || requestParameters.accessId === undefined) {
            throw new runtime.RequiredError('accessId','Required parameter requestParameters.accessId was null or undefined when calling login.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.accessId !== undefined) {
            queryParameters['accessId'] = requestParameters.accessId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResultFromJSON(jsonValue));
    }

    /**
     * Login callback from Webswing server after successful login
     * Login
     */
    async login(requestParameters: LoginApiLoginRequest): Promise<RefreshTokenResult> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Logs out current admin console session
     * Logout
     */
    async logoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logs out current admin console session
     * Logout
     */
    async logout(): Promise<void> {
        await this.logoutRaw();
    }

    /**
     * Returns refreshed access and refresh token.
     * Refresh Token
     */
    async refreshTokenRaw(): Promise<runtime.ApiResponse<RefreshTokenResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/refreshToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResultFromJSON(jsonValue));
    }

    /**
     * Returns refreshed access and refresh token.
     * Refresh Token
     */
    async refreshToken(): Promise<RefreshTokenResult> {
        const response = await this.refreshTokenRaw();
        return await response.value();
    }

}
