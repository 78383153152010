// core
import React, { useCallback, useEffect } from 'react'
// components
import {Grid, TableVirtualized, IColumns, Icon, SearchFilter, Button, Flex, PageHeader, StatusBar } from 'components'
// routes
import { changeTitle } from '../routes';
// styles
import css from './Applications.module.scss'
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
import cssOverview from '../Overview/Overview.module.scss'
import cssSessions from '../Sessions/Sessions.module.scss'
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import { AppsWidget } from './Partials/AppsWidget';
import { BasicApplicationInfo, InstanceManagerStatus } from 'gen';
import { Dropdown } from 'components/basic/Dropdown/Dropdown';
import { useHistory } from 'react-router';
import { ApiServerUrl} from 'utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AppsView = observer(() => {
  const {configStore, globalStore} = useStore();
  const history = useHistory();
  const {t} = useTranslation();
  const showConfig = useCallback((params) => {
    history.push('apps'+params.rowData.path)
  },[history])

  const view = (path:string) => {
    history.push(path);
  }
  const filteredData = !configStore.searchTerm
    ? globalStore.paths
    : globalStore.paths?.filter(item =>
        item.path?.toLowerCase().includes(configStore.searchTerm.toLocaleLowerCase()) ||
        item.name?.toLowerCase().includes(configStore.searchTerm.toLocaleLowerCase())
    );

  changeTitle(t('routes.apps'));

  useEffect(()=> {
    globalStore.updateApp();

    if(globalStore.toggleAddApp) {
      globalStore.setToggleAddApp(false)
    }
  },[])

  return (
    <>
      <AppsWidget className={css.serverWidth} icon='dns' title={t('config.type', {type:'Server'})} path='overview/server-config' />
      <Flex justifyContent={'space-between'} alignItems='center'>
        <PageHeader title={t('routes.apps')} />
        <Flex spacing={2}>
          <Button icon="apps" color={'grid' === configStore.appView?'blue':'neutral'} onClick={() => configStore.setAppView('grid')} />
          <Button icon="list" color={'table' === configStore.appView?'blue':'neutral'} onClick={() => configStore.setAppView('table')} />
          {!configStore.filterToggle && <Button color='neutral' icon={'search'} onClick={()=>configStore.setFilterToggle()} /> }
          {
          configStore.filterToggle && 
              <SearchFilter
                  value={configStore.searchTerm} 
                  onChange={(e)=>configStore.setSearchTerm(e.target.value)} 
                  onClose={()=>configStore.setFilterToggle()} 
              />
          }
        </Flex>
      </Flex>
      <div className={cssTab.wTabContent}>
        {
          configStore.appView === 'grid' &&
          <Grid container spacing="sm">
            {filteredData?.map(item => (
            <Grid key={item.path} item xs={12} sm={6} md={4} lg={3}>
              <AppsWidget
                status={item.status}
                src={ApiServerUrl + item.path + '/rest/appicon'} 
                title={item.name} 
                subtitle={item.path} 
                path={'apps' + item.path}
              />
            </Grid>
              ))}
          </Grid>
        }
        {
          configStore.appView === 'table' &&
          <div className={cssOverview.table}>
            <TableVirtualized 
              columns={[
                {
                  dataKey:'name',
                  width: 35,
                  align: 'start',
                  label: '',
                  className: cssSessions.actionCell,
                  component: (name, row) => <Dropdown
                    className={cssSessions.actionCellDropdown}
                    buttons={[
                      {label: t('Config'), icon:'list_alt', onClick:()=>view('apps'+row.path)},
                      {label: t('Sessions'), icon:'groups', onClick:()=>view('sessions'+row.path)},
                    ]}
                    >
                      <Icon name={'more_vert'} />
                    </Dropdown>
                },
                {
                  label:t('apps.name'),
                  dataKey:'name',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                  component: (name, row) => <Link className={css.cell} to={'/apps'+row.path} >{name}</Link>
                },
                {
                  label:t('apps.path'),
                  dataKey:'path',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                  component: (path, row) => <a className={css.cell} href={row?.url} target='_blank' rel="noreferrer">{path}</a>
                },
                {
                  label:t('apps.running'),
                  dataKey:'runningInstances',
                  minWidth: 100,
                  align: 'start',
                  bSort:true
                },
                {
                  label:t('general.status'),
                  dataKey:'status',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                  component: (status) => <StatusBar status={status as InstanceManagerStatus[]} />
                },
              ] as IColumns<BasicApplicationInfo>[]}
              minTableWidth={435}
              rowHeight={60}
              threshold={500}
              rowClassName={css.ac}
              onRowDoubleClick={showConfig}
              collection={filteredData}
              className={css.tableHeight}
            />
          </div>
        }
      </div>
    </>
  )
})
