import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { IColumns, TableVirtualized } from "components";
import { useStore } from "stores";
import { SwingSession } from "gen";
import { ActionCell } from "./ActionCell";
import { dateFormat, useRootOverflow } from "utils";
import css from '../Sessions.module.scss'
import cx from 'classnames';
import { MetricsCell } from "./MetricsCell";
import { UserCell } from "./UserCell";
import { AppCell } from "./AppCell";
import { WarningsCell } from "./WarningsCell";
import { useHistory } from "react-router-dom";
import { StatusCell } from "./StatusCell";
import { useTranslation } from "react-i18next";

interface ISessionsTableProps {
    tab: 'sessions' | 'closedSessions';
    app:string;
}

export const SessionsTable = observer(({tab, app}:ISessionsTableProps) => {
    const history = useHistory();
    const { sessionsStore } = useStore();
    const {t} = useTranslation();
    const [columns, setColumns] = useState<IColumns<SwingSession>[]>([])
    const view = useCallback((params) => {
        history.push('/session' + params.rowData.applicationPath +'/'+ params.rowData.id)
        sessionsStore.setActiveTab('view');
    },[history, tab])
    
    const defaultColumns: IColumns<SwingSession>[] = [
        {
            dataKey: 'id',
            label: '',
            align: 'start',
            width: 35,
            className: css.actionCell,
            component: (id, row) => <ActionCell session={row} />,
        },
        {
            dataKey: 'application',
            label: t('sessions.app'),
            minWidth: 200,
            align: 'start',
            bSort:true,
            className: cx(css.defaultCell, css.gridAppCell),
            component: (app, row) => <AppCell session={row} />
        },
        {
            dataKey: 'user',
            label: t('logs.user'),
            minWidth: 100,
            align: 'start',
            bSort:true,
            component: (user, row) => <UserCell session={row} />
        },
        {
            dataKey: 'userIp',
            label: t('logs.ip'),
            minWidth: 100,
            align: 'start',
            className: css.defaultCell,
        },
        {
            dataKey: 'startedAt',
            label: t('sessions.startTime'),
            minWidth: 135,
            align: 'start',
            bSort:true,
            component: (startedAt) => <div className={css.Cell}>{dateFormat(Number(startedAt))}</div>
        },
    ];
    const status: IColumns<SwingSession> = {
        dataKey: 'connected',
        label: t('general.status'),
        align: 'start',
        minWidth: 100,
        bSort:true,
        component: (connected,row) => <StatusCell session={row} />
    }
    const warnings: IColumns<SwingSession> = {
        dataKey: 'warnings',
        label: t('sessions.warning_plural'),
        align: 'start',
        minWidth: 160,
        bSort:true,
        component: (warnings, section) => <WarningsCell session={section} bFinished={tab !== 'sessions'}  />
    }
    const updateColumns = useCallback(() => {
        const columnsSet = [...defaultColumns];
        switch(tab) { 
            case 'sessions': {
                columnsSet.push(status); 
                columnsSet.push(warnings); 
                if (sessionsStore.extendTableToggle) {
                    columnsSet.push(
                        {
                        dataKey: 'id',
                        label: t('sessions.metrics'),
                        align: 'start',
                        minWidth: 500,
                        className: css.actionCell,
                        component: (id, row) => (
                            <MetricsCell session={row} />
                            ),
                        },
                        {
                            dataKey: 'sessionPoolId',
                            label: t('sessions.spId'),
                            minWidth: 278,
                            align: 'start',
                            className: css.defaultCell,
                        },
                        {
                            dataKey: 'pid',
                            label: t('sessions.processId'),
                            minWidth: 100,
                            align: 'start',
                            className: css.defaultCell,
                        },
                        {
                            dataKey: 'id',
                            label: t('sessions.instanceId'),
                            minWidth: 340,
                            align: 'start',
                            className: css.defaultCell,
                        },
                    ); 
                    }
                    break;
                }
                case 'closedSessions': {
                    columnsSet.push({
                        dataKey: 'endedAt',
                        label: t('sessions.endTime'),
                        align: 'start',
                        minWidth: 100,
                        bSort: true,
                        component: (endedAt) => <div className={css.Cell}>{dateFormat(Number(endedAt))}</div>
                    }); 
                    columnsSet.push(status); 
                    columnsSet.push(warnings); 
                break; 
            } 
            default: { 
                break; 
            } 
        }
        setColumns(columnsSet);
    }, [defaultColumns, tab]);

    useRootOverflow();

    useEffect(() => {
        updateColumns()
    },[tab, sessionsStore.extendTableToggle]);

    const filteredList = !sessionsStore.searchTerm
    ? sessionsStore.sessions![tab]
    : (sessionsStore.sessions![tab]as any[])?.filter(item =>
        item.application?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase())||
        item.id?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase()) ||
        item.user?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase()) ||
        item.status?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase())
    );

    return (
        <div style={{overflowX: 'auto'}}>
            <TableVirtualized
            collection={filteredList}
            minTableWidth={sessionsStore.extendTableToggle?2273:1055}
            // bLoading={sessionsStore.loading}
            rowHeight={sessionsStore.extendTableToggle?100:60}
            rowClassName={css.ac}
            threshold={500}
            columns={columns}
            onRowDoubleClick={view}
            onRowRightClick={view}
            className={cx(css.tableHeight, css.vTable)}
            // onReachEnd={nextPage}
            />
        </div>
    )
})
