import { ReactNode } from 'react'
import { makeAutoObservable, runInAction } from 'mobx'
import { ApplicationInfo, BasicApplicationInfo, InstanceManagerStatus } from 'gen/models'
import { ConfigService } from 'services'
import { computedFn } from 'mobx-utils'
import { ApiServerUrl, getServerStatuses, jsonToCssVariables, toPath } from 'utils'
import i18n from 'i18n'

interface IErrorView {
  header?:string,
  bLoginBtn?:boolean,
  bTryAgainBtn?:boolean,
  subtitle?: string,
}

class GlobalStore {

  serverUrl: string = ''
  loader: boolean = false
  leftSideMenuOpen: boolean = true
  rightSideMenuOpen: boolean = false
  modalOpen: boolean = false
  toggleSearch:boolean = true;
  toggleAddApp:boolean = false;
  appL: string = ''
  paths?: Array<BasicApplicationInfo> = [];
  info?: ApplicationInfo;
  interval: NodeJS.Timeout | undefined
  filterTableToggle:boolean = false;
  showErrorView:boolean= false; 
  errorView:IErrorView | undefined; 
  availableLangs:{code:string,name:string}[]=[]
  customization:{logo:string,logoCollapsed:string,footerHTML:string,themeVariables:string} | undefined;
  isDuplicate:boolean= false; 

  constructor() {
    makeAutoObservable(this)
  }

  toggleModal = () => {
    this.modalOpen = !this.modalOpen
  }

  setIsDuplicate = (state:boolean) => {
    this.isDuplicate = state
  }

  toggleErrorView(state?:boolean, errorView?:IErrorView) {
    this.showErrorView = typeof state === 'boolean'  ? state : !this.showErrorView
    if(this.showErrorView) {
      this.errorView = errorView
    } else {
      this.errorView = undefined
    }
  };

  setToggleSearch = (state?:boolean) => {
    if(!this.toggleSearch) {
      this.leftSideMenuOpen = true;
    }
    this.toggleSearch = typeof state === 'boolean'  ? state : !this.toggleSearch
  }

  setToggleAddApp = (state?:boolean) => {
    if(!this.toggleAddApp) {
      this.leftSideMenuOpen = true;
    }
    this.toggleAddApp = typeof state === 'boolean'  ? state : !this.toggleAddApp
  }

  toggleRightSideMenu = () => {
    this.rightSideMenuOpen = !this.rightSideMenuOpen
  }

  toggleLeftSideMenu = (state?:boolean) => {
    if(this.toggleAddApp) {
      this.toggleAddApp = false;
    }
    this.leftSideMenuOpen = typeof state === 'boolean' ? state : !this.leftSideMenuOpen;
    this.toggleSearch = this.leftSideMenuOpen;
  }

  updateApp = (app?:string) => {
    this.appL = app ? app: ''
  }
  get app() {
    return this.appL ? this.appL : ''
  }

  createApp = async (appPath:string, appName:string, copyApp?:string) => {
    if(appPath) {
      ConfigService.create(appPath,appName).then(async () => {
        this.clearInterval();
        if(this.isDuplicate && copyApp) {
          const data = await ConfigService.getConfig(copyApp);
          if(data) {
            if(data.webConfig?.data?.name) {
              data.webConfig.data.name = appName as any
            }
            ConfigService.setConfig(data, appPath)
          }
          this.setIsDuplicate(false);
        }
        this.loadPaths();
      })
    }
    return
  }

  removePath(path:string) {
    this.clearInterval()
    this.paths = this.paths?.filter((app)=> app.path !== '/'+path)
  }

  loadAvailableLangs = async () => {
    try {
      await fetch(toPath(ApiServerUrl,'locales/select.json'))
        .then(response => response.json())
        .then(data => {
          if(data) {
            runInAction(() => {
              this.availableLangs = data
            })

            // set prefered lang
            if(!localStorage.getItem('langsWereLoaded') || data.length <= 1) {
              localStorage.setItem('langsWereLoaded','true');
              if(data.filter((l:any)=>l.code === navigator.language).length === 0) {
                i18n.changeLanguage(data[0].code)
                if(!i18n.getDataByLanguage(i18n.language)) {
                  const fallbackLang = i18n.options.fallbackLng as string[];
                  i18n.changeLanguage(fallbackLang[0])
                }
              } else {
                i18n.changeLanguage(navigator.language)
              }
            }
          }
          return data
        });
    } catch (err) {
      console.log('apiError', err)
    }
  }

  loadCustomizationConfig = async () => {
    try {
      await fetch(toPath(ApiServerUrl,'custom/settings.json'))
        .then(response => response.json())
        .then(data => {
          if(data) {
            runInAction(() => {
              this.customization = data
              this.customization!.themeVariables = jsonToCssVariables(data.themeVariables)
            })
          }
          return data
        });
    } catch (err) {
      console.log('apiError', err)
    }
  }
  loadServerUrl = async () => {
    try {
      const data = await ConfigService.getServerUrl();
      if(data) {
        runInAction(() => {
          this.serverUrl = data
        })
      }
    } catch (err) {
      console.log('apiError', err)
    }
  }

  loadPaths = async () => {
    try {
      const data = await ConfigService.getPaths();
      if(data) {
        runInAction(() => {
          this.paths = data
        })
      }
    } catch (err) {
      console.log('apiError', err)
    }
  }

  loadInfo = async (appPath?:string) => {
    try {
      const data = await ConfigService.getInfo(appPath ? appPath :'');
      if(data) {
        runInAction(() => {
          this.info = data
        })
      }
    } catch (err) {
      console.log('apiError', err)
    }
  }
  
  setInterval(callback:any, delay:number) {
    this.interval = setInterval(() => {
      callback()
    }, delay);
  }

  clearInterval() {
    if(this.interval) {
      clearInterval(this.interval);
    }
  }

  setEnabled(bool:true) {
    this.info!.enabled = bool;
  }

  get isCluster():boolean {
    return this.info?.cluster ? true:false
  }

  countStatuses = computedFn(function countStatuses(this: GlobalStore, status:InstanceManagerStatus[], which: string):number {
    const obj:any = getServerStatuses(status)
    return obj[which] ? obj[which] : null;
  });

}

export default GlobalStore;