import { makeAutoObservable, runInAction } from 'mobx'
import { SessionsService } from 'services'
import { RecordingInfo, Sessions, SwingSession } from 'gen/models'
import { ApiServerUrl, getStatsDataset, toPath } from 'utils';
import { IColumns } from 'components';
import { RootStore } from './RootStore';

class SessionsStore {
  private rootStore: RootStore | undefined;

  // sessions
  sessions: Sessions = {};
  collumns: IColumns<SwingSession>[] = []
  loading: boolean = false;
  interval: NodeJS.Timeout | undefined
  lastUpdated: string | undefined;
  extendTableToggle?: boolean = false;
  appPath: string | undefined;

  // session
  session: SwingSession={};
  activeTab: string= 'view';
  statisticsLoggingEnabled: boolean |undefined;
  mirrorIframeSrc: string='';
  searchTerm: string=''
  filterToggle: boolean = false;

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore;
  }

  setSearchTerm(term:string) {
    if (this.searchTerm !== term) {
      this.searchTerm = term;
    }
  }

  setActiveTab(tab:string) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
    }
  }

  setAppPath(appPath: string | undefined) {
    this.appPath = appPath;
  }

  setExtendTableToggle(bool?:boolean) {
    this.extendTableToggle = typeof bool === 'boolean' ? bool : !this.extendTableToggle;
  }
  
  setFilterToggle(bool?:boolean) {
    this.filterToggle = bool ? bool : !this.filterToggle;
  }

  showPlayback(app: string, item: RecordingInfo) {
    this.rootStore?.globalStore.loadInfo(app).then(() => {
      const fileUrl = toPath(this.rootStore?.globalStore.serverUrl!, app) + "/rest/recording/" + item.token + "/" 
          + encodeURIComponent(item.sessionPoolId!) + "/" + false + "/" + encodeURIComponent(item.fileName!);
      window.open(fileUrl, "_blank");
    });
  }
  
  toggleStatisticsLoggingEnabled(bool?:boolean) {
    this.statisticsLoggingEnabled = bool ? bool : !this.statisticsLoggingEnabled;
    SessionsService.toggleStatisticsLogging(this.session.applicationPath!,this.session.id!,this.statisticsLoggingEnabled)
  }

  kill(session: SwingSession) {
    SessionsService.killSession(session.applicationPath!, session.id!)
      .then(() => {
        runInAction(() => {
          this.clearInterval();
          this.sessions.sessions = this.sessions.sessions?.filter((s: SwingSession)=>s.id !== session.id)
        })
        this.refresh()
      })
      .catch((e: any) => {
        console.error(e);
    });
  }

  forceKill(session: SwingSession) {
    SessionsService.forceKillSession(session.applicationPath!, session.id!)
      .then(() => {
        runInAction(() => {
          this.clearInterval();
          this.sessions.sessions = this.sessions.sessions?.filter((s: SwingSession)=>s.id !== session.id)
        })
        this.refresh()
      })
      .catch((e: any) => {
        console.error(e);
    });
  }

  requestThreadDump(session: SwingSession, bSession?:boolean) {
    SessionsService.requestThreadDump(session.applicationPath!,session.id!)
      .then(() => {
        bSession ? this.refreshSession(session.applicationPath!, session.id!) : this.refresh()
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  startRecording(session: SwingSession, bSession?:boolean){
    return SessionsService
      .startRecording(session.applicationPath!, session.id!)
      .then((data:SwingSession | undefined) => {
        if(data) {
          this.clearInterval();
          bSession ? this.refreshSession(session.applicationPath!, session.id!) : this.refresh()
        }
      })
      .catch((e: any) => {
        console.error(e);
    });
  }

  stopRecording(session: SwingSession, bSession?:boolean){
    return SessionsService
      .stopRecording(session.applicationPath!, session.id!)
      .then((data:SwingSession | undefined) => {
        bSession ? this.refreshSession(session.applicationPath!, session.id!) : this.refresh()
      })
      .catch((e: any) => {
        console.error(e);
    });
  }

  loadSessions = async () => {
    this.loading = true;
    const data = await SessionsService.getSessions(this.appPath || '');
      if(data) {
        runInAction(() => {
          this.sessions = data
          this.loading = false
          this.lastUpdated = (new Date).toLocaleTimeString();
        })
      }
  }

  loadSession = async (path:string, sessionId:string) => {
    const data = await SessionsService.getSession(path, sessionId);
    if(data) {
      runInAction(() => {
        this.session = data
        this.lastUpdated = (new Date).toLocaleTimeString();
        this.statisticsLoggingEnabled = data.statisticsLoggingEnabled;
        this.mirrorIframeSrc = ApiServerUrl+'/mirror.html?clientId=' + data.id
        if (this.rootStore && this.rootStore.logsStore && data.sessionPoolId) {
          this.rootStore.logsStore.setSpLogId(data.sessionPoolId);
        }
      })
    }
  }

  canShowSessionDetail(id: string) {
    if (this.sessions && this.sessions.sessions) {
      if (this.sessions.sessions?.filter((s: SwingSession) => s.id === id).length) {
        return true;
      }
    }
    return false;
  }

  setInterval(callback:any, delay:number) {
    this.interval = setInterval(() => {
      callback()
    }, delay);
  }
  clearInterval() {
    if(this.interval) {
      clearInterval(this.interval);
    }
  }

  refresh() {
    this.loadSessions()
    .then(()=>{
        this.clearInterval();
    })
    .then(()=> {
        this.setInterval(() => {
        this.refresh();
      }, 5000)
    })
    .catch(()=>{
        this.clearInterval();
    })
  }

  refreshSession(app:string, sessionId:string) {
    this.loadSession(app, sessionId)
    .then(()=>{
        this.clearInterval();
    })
    .then(()=> {
        this.setInterval(() => {
        this.refreshSession(app, sessionId);
      }, 3000)
    })
    .catch(()=>{
        this.clearInterval();
    })
  }

  getWarningsNum(session?: SwingSession) {
    if (!session) {
      session = this.session;
    }

    let sum = 0;
    if (session) {
      sum += session?.warnings ? session.warnings.length : 0;
      sum += session.warningHistory ? session.warningHistory.length : 0;
      if (this.rootStore?.permissionsStore.get("getThreadDump")) {
        sum += session.threadDumps ? Object.keys(session?.threadDumps!).length : 0;
      }
    }
    return sum;
  }

  get getMemoryStats() {
    return {
      names: ["Max Memory", "Used Memory"],
      keys: ["memoryAllocated", "memoryUsed"],
      dataset: getStatsDataset(this.session?.stats, [
        "memoryAllocated",
        "memoryUsed"
      ]),
      tickFormat: (value: number, index: number) => {
        if (value > 999) {
          return (value / 1024).toFixed(1) + "GB";
        } else {
          return value + "MB";
        }
      }
    };
  }

  get getCpuStats() {
      return {
        names: ["CPU Utilization"],
        keys: ["cpuUtilization"],
        dataset: getStatsDataset(this.session?.stats, ["cpuUtilization"]),
        tickFormat: (value: number, index: number) => {
          return Math.floor(value) + "%";
        }
      };
  }

  get getBandwidthStats() {
      return {
        names: ["Inbound trafic", "Outbound trafic"],
        keys: ["inboundSize", "outboundSize"],
        dataset: getStatsDataset(this.session?.stats, ["inboundSize", "outboundSize"]),
        tickFormat: (value: number, index: number) => {
          return Math.floor(value / 1024) + "k";
        }
      };
  }

  get getLatencyStats() {
      return {
        names: [
          "Network ping latency",
          "Network transfer latency",
          "Server rendering latency",
          "Client rendering latency"
        ],
        keys: [
          "latencyPing",
          "latencyNetworkTransfer",
          "latencyServerRendering",
          "latencyClientRendering"
        ],
        dataset: getStatsDataset(this.session?.stats, [
          "latencyPing",
          "latencyNetworkTransfer",
          "latencyServerRendering",
          "latencyClientRendering"
        ]),
        tickFormat: (value: number, index: number) => {
          return value + "ms";
        }
      };
  }

  get mirroringMessageKey() {
    switch (this.session.mirroringStatus) {
      case 'DENIED_MIRRORING_BY_USER':
        return 'sessions.mirror.status.denied';
        break;
      case 'WAITING_FOR_MIRRORING_APPROVAL':
        return 'sessions.mirror.status.waiting';
        break;
      default:
        return null;
    }
  }

}

export default SessionsStore;