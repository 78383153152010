// .core
import React, { useCallback, useState } from 'react'
// libraries
import cx from 'classnames'
// styles
import cssTable from '../../../components/complex/Table/Table.module.scss'
import css from '../Overview.module.scss'
import { observer } from 'mobx-react'
import { Dialog } from 'components'

interface IAppsSPCellProps{
  className?: string,
  onClick?(): void,
  label:string,
  dialogHeader:string
  children?: React.ReactNode
}

export const AppsSPCell = observer(({className, onClick, label,dialogHeader, children}:IAppsSPCellProps)=> {
  const [open, setOpen] = useState(false)
  const toggle = useCallback((state?: boolean) => {
    if (open !== state) {
      setOpen(state ? state : !open)
    }
  }, [open])
  return (
    <>
      <span onClick={()=>setOpen(!open)} className={cx(cssTable.wCell, css.link)} style={{justifyContent:'start'}}>
        {label}
      </span>
      <Dialog
        bOpen={open}
        title={dialogHeader}
        onToggle={toggle}
        footer={false}
      >
        {children}
      </Dialog>
    </>
  )
})