// .core
import React from 'react'
// components
import { Avatar, ButtonIcon, Chip, Flex, IColumns, Icon, StatusBar, TableVirtualized } from 'components'
// libraries
import cx from 'classnames'
// styles
import css from '../Overview.module.scss'
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
import cssSessions from '../../Sessions/Sessions.module.scss'
import { BasicApplicationInfo, InstanceManagerStatus, SessionPoolInfo } from 'gen'
import { AppsSPCell } from './AppsSPCell'
import cssTable from '../../../components/complex/Table/Table.module.scss'
import { Dropdown } from 'components/basic/Dropdown/Dropdown'
import { observer } from 'mobx-react'
import { useStore } from 'stores'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { ApiServerUrl } from 'utils';

interface ITableViewProps {
  sp:SessionPoolInfo[]
}


export const TableView = observer(({
  sp
}: ITableViewProps) => {
  const { overviewStore, logsStore, globalStore } = useStore();
  const history = useHistory();
  const {t} = useTranslation();
  const getSpSum = (apps:object) => {
    let sum:number = 0
    Object.entries(apps).forEach((item)=>{
      sum += item[1]
    });
    return sum
  }
  const view = (path:string) => {
    history.push(path);
  }
  const showLogs = (id:string) => {
    logsStore.setSpLogId(id);
    history.push('logs/sessionpool')
  }
  const drainModeTxt = (sp: SessionPoolInfo) => {
    return sp.drainMode ? t('overview.resume') : t('overview.drainMode')
  }
  
  const resultsApps = !overviewStore.searchTerm
    ? globalStore.paths
    : globalStore.paths?.filter(item =>
        item.name?.toLowerCase().includes(overviewStore.searchTerm.toLocaleLowerCase())||
        item.path?.toLowerCase().includes(overviewStore.searchTerm.toLocaleLowerCase())
    );
  return (
    <>
    <Flex spacing={2}>
      <ButtonIcon icon='filter_list' active={'sp' === overviewStore.orderBy} onClick={()=>overviewStore.setOrderBy('sp')}>{t('overview.bySPs')}</ButtonIcon>
      <ButtonIcon icon='filter_list' active={'app' === overviewStore.orderBy} onClick={()=>overviewStore.setOrderBy('app')}>{t('overview.byapps')}</ButtonIcon>
    </Flex>
    <div className={cx(cssTab.wTabContent)}>
      {
        overviewStore.orderBy === 'sp' &&
        <div className={css.table}>
          <TableVirtualized 
            columns={[
              {
                dataKey:'id',
                width: 35,
                align: 'start',
                label: '',
                className: cssSessions.actionCell,
                component: (id, sp) => <Dropdown
                  className={cssSessions.actionCellDropdown}
                  buttons={[
                    {label: t('overview.verboseMode'), icon:'info', onClick: () => overviewStore.toggleVerbose(sp,'sp'), shouldShow: !sp.stopped},
                    {label: t('routes.logs'), icon:'list_alt', onClick: () => showLogs(sp.id!)},
                    {label: drainModeTxt(sp), icon:'opacity', onClick: () => overviewStore.toggleDrain(sp), shouldShow: !sp.stopped},
                    {label: t('overview.stop'), icon:'power_settings_new', onClick: () => overviewStore.stop(sp.id!), shouldShow: !overviewStore.hasRunningInstances(sp.apps!) && !sp.stopped},
                    {label: t('overview.shutdownSessions'), icon:'power_settings_new', onClick: () => overviewStore.shutdown(sp), shouldShow: overviewStore.hasRunningInstances(sp.apps!) && !sp.stopped}
                  ]}
                  >
                    <Icon name={'more_vert'} />
                  </Dropdown>
              },
              {
                label: t('general.name'),
                dataKey:'id',
                minWidth: 200,
                align: 'start',
                bSort:true,
              },
              {
                label:t('overview.appsRunning'),
                dataKey:'apps',
                minWidth: 100,
                align: 'start',
                component: (apps) => <span className={cssTable.wCell} style={{justifyContent:'start'}}>
                    { apps? getSpSum(apps as any):null}
                  </span>
              },
              {
                label:t('overview.maxSessinos'),
                dataKey:'maxInstances',
                minWidth: 100,
                align: 'start',
                component: (item) => <span className={cssTable.wCell} style={{justifyContent:'start'}}>{item == -1 ? 'Unlimited': item }</span>
              },
              {
                label:t('general.priority'),
                dataKey:'priority',
                minWidth: 100,
                align: 'start',
                bSort:true,
              },
              {
                label:t('general.status'),
                dataKey:'stopped',
                minWidth: 100,
                align: 'start',
                component: (item) => 
                  item ? 
                  <Chip size='small' color='danger'>{t('overview.stopped')}</Chip>
                  :
                  <Chip size='small' color='success'>{t('overview.running')}</Chip>
              },
              {
                label:t('routes.apps'),
                dataKey:'apps',
                minWidth: 100,
                align: 'start',
                component: (apps) => <AppsSPCell dialogHeader={t('overview.availableApps')} label={Object.entries(apps!).length + ' / ' + overviewStore.servers![0].apps!.length}>
                  <table className={css.spTable}>
                    <thead>
                      <tr>
                        <th>{t('sessions.app')}</th>
                        <th>{t('status.running')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apps ? Object.entries(apps).map((e) => {
                        const path = globalStore.paths?.find((path)=>path.path === e[0])
                        return (
                          <tr key={e[0]}>
                          <td>
                          <Flex spacing={2}>
                            <Avatar shape='square' src={ApiServerUrl +  e[0] + '/rest/appicon'} />
                            <div>
                              {path?.name}
                              <small>{e[0]}</small>
                            </div>
                          </Flex>
                          </td>
                          <td>{e[1] as any}</td>
                        </tr>
                        )
                      }):null}
                    </tbody>
                  </table>  
                </AppsSPCell>
              },
            ] as IColumns<SessionPoolInfo>[]}
            minTableWidth={650}
            rowHeight={60}
            threshold={500}
            rowClassName={css.ac}
            collection={overviewStore.filteredSPs}
          />
        </div>
      }
      {
        overviewStore.orderBy === 'app' &&
        <div className={css.table}>
          <TableVirtualized 
              columns={[
                {
                  dataKey:'name',
                  width: 35,
                  align: 'start',
                  label: '',
                  className: cssSessions.actionCell,
                  component: (name, row) => <Dropdown
                    className={cssSessions.actionCellDropdown}
                    buttons={[
                      {label: t('general.config'), icon:'list_alt', onClick:()=>view('apps'+row.path)},
                      {label: t('routes.sessions'), icon:'groups', onClick:()=>view('sessions'+row.path)},
                    ]}
                    >
                      <Icon name={'more_vert'} />
                    </Dropdown>
                },
                {
                  label:t('overview.appName'),
                  dataKey:'name',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                },
                {
                  label:t('overview.path'),
                  dataKey:'path',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                },
                {
                  label:t('overview.running'),
                  dataKey:'runningInstances',
                  minWidth: 100,
                  align: 'start',
                  bSort:true,
                },
                {
                  label:t('sessions.sp_plural'),
                  dataKey:'path',
                  minWidth: 100,
                  align: 'start',
                  component: (path, row) => <AppsSPCell dialogHeader={t('overview.sPsForApp')} label={overviewStore.appOnSP(row.path) + ' / ' + overviewStore.sessionPools!.length}>
                  <table className={css.spTable}>
                    <thead>
                      <tr>
                        <td>{t('sessions.sp')}</td>
                        <td>{t('status.running')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {path ? overviewStore.sessionPools?.map((e) => (
                        <tr key={e.id}>
                          <td>{e.id}</td>
                          <td>{e.apps![row.path!] as any}</td>
                      </tr>
                      )):null}
                    </tbody>
                  </table>  
                </AppsSPCell>
                },
                {
                  label:t('general.status'),
                  dataKey:'status',
                  minWidth: 100,
                  align: 'start',
                  component: (status) => <StatusBar status={status as InstanceManagerStatus[]} />
                },
              ] as IColumns<BasicApplicationInfo>[]}
              minTableWidth={435}
              rowHeight={60}
              threshold={500}
              rowClassName={css.ac}
              collection={resultsApps}
            />
        </div>
      }
    </div>
    </>
  )
})