// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'
import classnames from 'classnames'

// components
import { Icon } from 'components/basic'
import { IWrapperComponentProps } from 'components'

// styles
import css from '../LeftSideMenu.module.scss'
import { ApiServerUrl } from 'utils'

export interface IMenuItemProps extends IWrapperComponentProps {
  /**
   * link where will click redirect
   */
  path?: string
  /**
   * image to show
   */
  image?: string
  /**
   * icon to show
   */
  icon?: string
  /**
   * path that is currently active
   */
  activePath?: string
  active?: boolean

  /**
   * callback after click
   */
  onClick?: () => void
}

export const MenuItem = ({ children, path, image, icon, activePath, onClick, className, active }: IMenuItemProps) => {
  return (
    <li className={className}>
      {
        path ? 
        <Link
          className={classnames(css.menuItem, { [css.activeLink]: activePath === `/${path}` })}
          to={`/${path}`}
          onClick={onClick}>
          {image && <img src={ApiServerUrl + image + '/rest/appicon'} alt="appIcon"/> }
          {icon && <Icon className={css.menuItemIcon} name={icon} />}
          <div className={css.text}>{children}</div>
        </Link>
        : 
        <span
          className={classnames(css.menuItem, { [css.activeLink]: active })}
          onClick={onClick}>
          {image && <img src={ApiServerUrl + image + '/rest/appicon'} alt="appIcon"/> }
          {icon && <Icon className={css.menuItemIcon} name={icon} />}
          <div className={css.text} >{children}</div>
        </span>

      }
    </li>
  )
}