// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionPoolLogInfo
 */
export interface SessionPoolLogInfo {
    /**
     * 
     * @type {string}
     * @memberof SessionPoolLogInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPoolLogInfo
     */
    logDir?: string;
}

export function SessionPoolLogInfoFromJSON(json: any): SessionPoolLogInfo {
    return SessionPoolLogInfoFromJSONTyped(json, false);
}

export function SessionPoolLogInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionPoolLogInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'logDir': !exists(json, 'logDir') ? undefined : json['logDir'],
    };
}

export function SessionPoolLogInfoToJSON(value?: SessionPoolLogInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'logDir': value.logDir,
    };
}


