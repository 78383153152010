// core
import React from 'react'
import Formium from 'formium-component';
// import Formium from '../../../../../../webswing-formium/formium-js/public/formium';
// services
import { ConfigService} from 'services';

// components
import { IComponentProps } from 'components'

// styles
import 'formium-component/public/formium.css'
// import '../../../../../../webswing-formium/formium-js/public/formium.css'
import { MetaObject, ResolveTypeEnum, SearchVariablesTypeEnum } from 'gen';
import { store } from 'stores';
import css from './FormiumConfig.module.scss';


export interface IFormiumProps extends IComponentProps {
    identify: string;
    path?: string;
    value?: MetaObject;
    list?: { [key: string]: MetaObject; };
    variables?: string[];
    readonly: boolean;
    istab?: boolean;
    hide?: string[];
    onConfigChange?: any;
    translations?:any;
    lang?:string;
    // onRequestUpdate?: any
}

export default class FormiumConfig extends React.Component<IFormiumProps> {
    private formiumRef = React.createRef<HTMLDivElement>()
    private form: Formium | undefined
    
    constructor(props: IFormiumProps) {
        super(props);
    }

    componentDidMount() {
        setTimeout(()=>{
            this.form = new Formium({
                "target": this.formiumRef.current as HTMLElement,
                "props": {
                  "value": this.props.value,
                  "list": this.props.list,
                  "readonly": this.props.readonly,
                  "identify": this.props.identify,
                  "hiddenConfigFields": this.props.hide || [],
                  "translations": this.props.translations,
                 // "lang": this.props.lang
                }
            });
    
            this.form.$on('requestUpdate', (event: any) => {
                store.configStore.onRequestUpdate(this.props.path!);
            });
            
            this.form.$on('configChange', (event: any) => {
                store.configStore.compareConfig(this.props.identify);
            });

            this.form.$set({
                onVariableSearch: (variables: SearchVariablesTypeEnum, valToSearch: string) => {
                  const val = valToSearch;
                  const cleanVal = val.substr(val.lastIndexOf('$'), val.length);
                  const searchSeq = cleanVal.substr(0, 0) + cleanVal.substr(0 + 2);
                  let id;
                  if (this.props.identify != 'allID') {
                      id = this.props.identify;
                  }
                  return ConfigService.getVariables(this.props.path!, variables, searchSeq, id).then((data: any) => {
                    return data;
                  });
                }
            });
        
            this.form.$set({
                onVariableResolve: (variables: ResolveTypeEnum, valueToResolve: string) => {
                    const val = valueToResolve;
                    let id;
                    if (this.props.identify != 'allID') {
                        id = this.props.identify;
                    }
                    return ConfigService.resolve(this.props.path!, variables, val, id).then((data) => {
                    let result = '';
    
                    if (!data || data === null) {
                        return result;
                    }
    
                    const multiVar = Object.keys(data).length > 1;
                    if(multiVar) { 
                        result += '<ul class="var-res-list">'
                        for (const property in data) {
                            // console.log(`${property}: ${object[property]}`);
                            const whatAreYou = this.props.istab ? ' (on server ': ' (on session pool '
                            result += '<li>'+ property + whatAreYou;
                            data[property].forEach((idServer: string, i:number)=>{
                                result += i === data[property].length-1 ? idServer : idServer + ', ';  
                            })
                            result+= ')</li>'
                        }
                        result += '</ul>'
                    } else {
                        result += Object.keys(data)[0];
                    }
                    return result;
                    });
                }
            });
        },10);
    }

    componentDidUpdate(prevProps: IFormiumProps) {

        this.form?.$set({
            readonly: this.props.readonly,
            value: this.props.value,
            list: this.props.list,
            identify: this.props.identify,
            hide: this.props.hide,
            translations: this.props.translations,
            lang: this.props.lang
        })
    }

    componentWillUnmount() {
        if(this.form) {
            this.form.$destroy()
        }
    }
  
    render() {
      return <div className={css.root} id={this.props.identify} ref={this.formiumRef} />
    }
  }