export interface INav {
  name: string, 
  path: string, 
  icon: string, 
  hasParam:boolean, 
  permission: string | null 
}
export const MENU_ITEMS: INav[] = [
  {
    name: 'routes.overview',
    path: 'overview',
    icon: 'dashboard',
    permission: 'overview',
    hasParam: false,
  },
  {
    name: 'routes.apps',
    path: 'apps',
    icon: 'settings',
    permission: 'configView',
    hasParam: true,
  },
  {
    name: 'routes.sessions',
    path: 'sessions',
    icon: 'cloud',
    permission: 'sessions',
    hasParam: true,
  },
  {
    name: 'routes.logs',
    path: 'logs/admin',
    icon: 'cancel',
    permission: 'logsView',
    hasParam: true,
  },
]
