// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IWrapperComponentProps } from 'components'

// styles
import css from './Coin.module.scss'

export type CoinColor = 'white' | 'neutral' | 'primary' | 'success' | 'blue' | 'danger' | 'warning'

export type CoinSize = 'small' | 'medium' | 'large'

export interface ICoinProps extends IWrapperComponentProps {
  /**
   * Styling of the coin, default theme color will be used if none specified.
   */
  color?: CoinColor
  /**
   * Coin size
   */
  size?: CoinSize

  onClick?: () => void
  toggleHover?: () => void
}

export const Coin = ({ children, onClick,toggleHover, color = 'primary', className, size = 'medium' }: ICoinProps) => {
  return <div onClick={onClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover} className={classnames(css.root, css[color], css[size], className)}>{children}</div>
}
