// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstanceManagerStatus,
    InstanceManagerStatusFromJSON,
    InstanceManagerStatusFromJSONTyped,
    InstanceManagerStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationInfo
 */
export interface ApplicationInfo {
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationInfo
     */
    cluster?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    runningInstances?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationInfo
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    icon?: string;
    /**
     * 
     * @type {Array<InstanceManagerStatus>}
     * @memberof ApplicationInfo
     */
    status?: Array<InstanceManagerStatus>;
    /**
     * 
     * @type {object}
     * @memberof ApplicationInfo
     */
    config?: object;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    connectedInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    maxRunningInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    finishedInstances?: number;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ApplicationInfo
     */
    stats?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ApplicationInfo
     */
    warnings?: { [key: string]: Array<string>; };
}

export function ApplicationInfoFromJSON(json: any): ApplicationInfo {
    return ApplicationInfoFromJSONTyped(json, false);
}

export function ApplicationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': !exists(json, 'path') ? undefined : json['path'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cluster': !exists(json, 'cluster') ? undefined : json['cluster'],
        'runningInstances': !exists(json, 'runningInstances') ? undefined : json['runningInstances'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(InstanceManagerStatusFromJSON)),
        'config': !exists(json, 'config') ? undefined : json['config'],
        'connectedInstances': !exists(json, 'connectedInstances') ? undefined : json['connectedInstances'],
        'maxRunningInstances': !exists(json, 'maxRunningInstances') ? undefined : json['maxRunningInstances'],
        'finishedInstances': !exists(json, 'finishedInstances') ? undefined : json['finishedInstances'],
        'stats': !exists(json, 'stats') ? undefined : json['stats'],
        'warnings': !exists(json, 'warnings') ? undefined : json['warnings'],
    };
}

export function ApplicationInfoToJSON(value?: ApplicationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'url': value.url,
        'name': value.name,
        'cluster': value.cluster,
        'runningInstances': value.runningInstances,
        'enabled': value.enabled,
        'message': value.message,
        'icon': value.icon,
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(InstanceManagerStatusToJSON)),
        'config': value.config,
        'connectedInstances': value.connectedInstances,
        'maxRunningInstances': value.maxRunningInstances,
        'finishedInstances': value.finishedInstances,
        'stats': value.stats,
        'warnings': value.warnings,
    };
}


