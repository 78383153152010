// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServerLogInfo,
    ServerLogInfoFromJSON,
    ServerLogInfoFromJSONTyped,
    ServerLogInfoToJSON,
    SessionLogInfo,
    SessionLogInfoFromJSON,
    SessionLogInfoFromJSONTyped,
    SessionLogInfoToJSON,
    SessionPoolLogInfo,
    SessionPoolLogInfoFromJSON,
    SessionPoolLogInfoFromJSONTyped,
    SessionPoolLogInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LogsOverview
 */
export interface LogsOverview {
    /**
     * 
     * @type {Array<string>}
     * @memberof LogsOverview
     */
    apps?: Array<string>;
    /**
     * 
     * @type {Array<ServerLogInfo>}
     * @memberof LogsOverview
     */
    servers?: Array<ServerLogInfo>;
    /**
     * 
     * @type {Array<SessionPoolLogInfo>}
     * @memberof LogsOverview
     */
    sessionPools?: Array<SessionPoolLogInfo>;
    /**
     * 
     * @type {Array<SessionLogInfo>}
     * @memberof LogsOverview
     */
    sessions?: Array<SessionLogInfo>;
    /**
     * 
     * @type {string}
     * @memberof LogsOverview
     */
    token?: string;
}

export function LogsOverviewFromJSON(json: any): LogsOverview {
    return LogsOverviewFromJSONTyped(json, false);
}

export function LogsOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogsOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': !exists(json, 'apps') ? undefined : json['apps'],
        'servers': !exists(json, 'servers') ? undefined : ((json['servers'] as Array<any>).map(ServerLogInfoFromJSON)),
        'sessionPools': !exists(json, 'sessionPools') ? undefined : ((json['sessionPools'] as Array<any>).map(SessionPoolLogInfoFromJSON)),
        'sessions': !exists(json, 'sessions') ? undefined : ((json['sessions'] as Array<any>).map(SessionLogInfoFromJSON)),
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function LogsOverviewToJSON(value?: LogsOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': value.apps,
        'servers': value.servers === undefined ? undefined : ((value.servers as Array<any>).map(ServerLogInfoToJSON)),
        'sessionPools': value.sessionPools === undefined ? undefined : ((value.sessionPools as Array<any>).map(SessionPoolLogInfoToJSON)),
        'sessions': value.sessions === undefined ? undefined : ((value.sessions as Array<any>).map(SessionLogInfoToJSON)),
        'token': value.token,
    };
}


