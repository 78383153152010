import i18n from 'i18n';
import { viewLogsApi, getAuthenticationHeaders, handleRestError} from 'utils';
import {AccessService} from './AccessHandlerService';


class LogRestService {
  
  private static _instance:LogRestService = new LogRestService();
  
  
  constructor() {
    if(LogRestService._instance){
      throw new Error("Error: Instantiation failed: Use LogRestService.getInstance() instead of new.");
    }
    LogRestService._instance = this;
  }
  
  static getInstance():LogRestService
  {
    return LogRestService._instance;
  }

  async getLogsOverview() {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.getLogsOverview();
    } catch (e) {
      handleRestError(i18n.t('error.loadLogsOverview'),e)
    }
  }
  
  async getServerLogs(id:string, type: string, query: any) {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.getServerLogs({id, type, logRequest:query});
    } catch (e) {
      handleRestError(`${i18n.t('error.loadLog',{type:type})}`,e)
    }
  }
  
  async getAdminConsoleLogs(query: any) {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.getAdminConsoleLogs({logRequest: query});
    } catch (e) {
      handleRestError(`${i18n.t('error.loadLog',{type: 'admin'})}`,e)
    }
  }
  
  async getSessionPoolLogs(id:string, query: any) {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.getSessionPoolLogs({id, logRequest:query});
    } catch (e) {
      handleRestError(i18n.t('error.sessionPoolLogs'),e)
    }
  }
  
  async getSessionLog(sessionPoolId: string, instanceId: string, path: string, query: any) {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.getSessionLogs({sessionPoolId, instanceId, appPath:path, logRequest: query});
    } catch (e) {
      handleRestError(i18n.t('error.sessionLog'),e)
    }
  }
  
  async downloadServerLogs(id: string, type: string, token: string) {
    try {
      const logsApi = viewLogsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await logsApi.downloadServerLogs({id, type, token});
    } catch (e) {
      handleRestError(`${i18n.t('error.downloadServerLogs',{type:type})}`,e)
    }
  }
  
  private async getAccessToken() {
    return await AccessService.getAccessToken();
  }
}

export const LogsService = LogRestService.getInstance();