import { showToastErr } from './../components/basic/Toast/Toast';
import { AccessService } from 'services';
import {
  Configuration,
  BasicApi,
  ManageConfigurationApi,
  ManageSessionsApi,
  ManageApplicationsApi,
  ViewLogsApi,
  OverviewApi,
  LoginApi,
  HTTPHeaders,
} from "../gen";

import { toPath } from '.'
import { store } from 'stores';

export let ApiServerUrl = "";
export let ApiContextPath = "/";

type HTTPCodes =
  | 0 //   HTTP request failed - `catch` was reached
  | 200 // ok
  | 201 // created
  | 204 // no_content
  | 400 // bad_request
  | 401 // unauthorized
  | 402 // payment_required
  | 403 // forbidden
  | 404 // not_found
  | 408 // request_timeout
  | 409 // conflict
  | 422 // conflict
  | 500 // internal_server_error
  | 502 // bad_gateway
  | 503 // service_unavailable


export async function initBaseLocation(): Promise<void> {
  let origin = window.location.origin;
  if (!origin) {
    origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  origin = origin.indexOf("/", origin.length - 1) !== -1 ? origin.substring(0, origin.length - 1) : origin;
  
  let contextPath = window.location.pathname;
  contextPath = contextPath.indexOf("/", contextPath.length - 1) !== -1 ? contextPath.substring(0, contextPath.length - 1) : contextPath;
  
  ApiServerUrl = origin + contextPath;
  ApiContextPath = contextPath + "/";
}

export const basicApi = (headers: HTTPHeaders, path?: string) => {
  const req = new BasicApi(new Configuration({
    basePath: toPath(ApiServerUrl, path!),
    credentials: "include", headers
  }));
  return req;
}

export const manageConfigurationApi = (path: string, headers: HTTPHeaders) => {
  const req = new ManageConfigurationApi(new Configuration({
    basePath: toPath(ApiServerUrl, path),
    credentials: "include", headers
  }))
  return req;
}

export const manageApplicationsApi = (headers: HTTPHeaders, path?: string) => {
  const req = new ManageApplicationsApi(new Configuration({
    basePath: toPath(ApiServerUrl, path!),
    credentials: "include", headers
  }))
  return req;
}

export const manageSessionsApi = (path: string, headers: HTTPHeaders) => {
  const req = new ManageSessionsApi(new Configuration({
    basePath: toPath(ApiServerUrl, path),
    credentials: "include", headers
  }))
  return req;
}

export const viewLogsApi = (headers: HTTPHeaders) => {
  const req = new ViewLogsApi(new Configuration({ basePath: ApiServerUrl, credentials: "include", headers }))
  return req;
}

export const loginApi = (headers: HTTPHeaders) => {
  const req = new LoginApi(new Configuration({ basePath: ApiServerUrl, credentials: "include", headers }))
  return req;
}

export const overviewApi = (headers: HTTPHeaders) => {
  const req = new OverviewApi(new Configuration({ basePath: ApiServerUrl, credentials: "include", headers }))
  return req;
}

export function getAuthenticationHeaders(accessToken: string): HTTPHeaders {
  const headers: any = {};

  headers["X-webswing-securitySuffix"] = getSecuritySuffix();
  
  if (accessToken && accessToken !== null && accessToken.length) {
    headers["Authorization"] = "Bearer " + accessToken;
  }
  
  return headers;
}

export function getSecuritySuffix() {
  if (!window.name || window.name.substr(0, 4) !== 'tid_') {
      window.name = 'tid_' + GUID();
  }

  if (window.name.startsWith("tid_u_")) {
      return window.name.split("-")[2];
  }

  return window.name;
}

function GUID() {
  const S4 = () => {
      return Math.floor(Math.random() * 0x10000).toString(16);
  };
  return (S4() + S4() + S4());
}

export function handleRestError(operation: string, data: any) {
  switch (data.status as HTTPCodes) {
    // Action failed - something went wrong
    case 400:
    case 401:
    case 403:
      console.error(operation, data)
      showToastErr(operation)
      if (!store.permissionsStore.isLoggedIn()) {
        AccessService.displayLogin();
      } else {
        store.globalStore.toggleErrorView(true);
      }
      break;
    case 404:
    case 408:
    case 409:
    case 422:
      console.error(operation, data)
      showToastErr(operation)
      break;
    // Error occured on the server side
    case 500:
    case 502:
    case 503:
      console.error(operation, data)
      showToastErr(operation)
      store.globalStore.toggleErrorView(true,{header:operation});
      break;
    default:
  }
}
