import React from "react";
import { Button, Flex, Heading, Icon, SearchFilter, Switch } from "components";
import css from '../Logs.module.scss'
import { Dropdown, IBtndropProps } from "components/basic/Dropdown/Dropdown";
import { observer } from "mobx-react";
import { useStore } from "stores";
import { useTranslation } from "react-i18next";
interface IheadingRowProps {
    title:string
    buttons?:IBtndropProps[]
    dropdownLabel?:string
    onDownloadClick:()=>void,
    type?:string
}

export const HeadingRow = observer(({title, dropdownLabel, buttons, onDownloadClick, type}:IheadingRowProps) => {
    const {logsStore} = useStore()
    const {t} = useTranslation()
    const handleSeach = (event:React.ChangeEvent<HTMLInputElement>) => {
        logsStore.setSearchTerm(event.target.value);
    };
    return (
        <Flex className={css.headingRow} justifyContent={logsStore.isCluster ? 'space-between':'flex-end'} flexWrap='wrap'>
            {
            logsStore.isCluster && (buttons && buttons.length >1) ?
            <Flex>
                <Heading className={css.pr2} variant='h2' title={`${dropdownLabel}:`} />
                <Dropdown className={css.dropdown} color='neutral' size='medium' buttons={buttons} active={title} />
            </Flex>
            :
            logsStore.isCluster &&
            <Heading variant='h2' title={dropdownLabel? `${dropdownLabel}: ${title}`:title} />
            }
            <Flex spacing={2} alignItems='center'>
                {
                    type !== 'audit' &&
                    <Switch value={logsStore.compact} size='large' onClick={()=>logsStore.setCompact()}>{logsStore.compact? t('general.wrapEnabled'):t('general.wrapDisabled')}</Switch>
                }
                <Button icon={'cloud_download'} onClick={onDownloadClick} />
                {!logsStore.filterToggle && <Button color='neutral' icon={'search'} onClick={()=>logsStore.setFilterToggle()} /> }
                {logsStore.filterToggle && <SearchFilter value={logsStore.searchTerm} onChange={handleSeach} onClose={()=>logsStore.setFilterToggle()} />}
            </Flex>
        </Flex>
    )
})