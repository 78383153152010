// .core
import React, { useEffect, useState } from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './ProgressBar.module.scss'

interface IProgressBarProps {
  className?: string,
  label?:string,
  now:number, 
  min?:number, 
  max?:number,
  children?:React.ReactNode
  /**
   * Styling of the point, default theme color will be used if none specified.
   */
  color: 'primary' | 'danger' | 'success' | 'warning' | 'info',
  onClick?(): void
}


export const ProgressBar = ({
  color,
  label,
  children,
  now,
  min=0,
  max=100,
  className,
  onClick,
}: IProgressBarProps) => {
  const ROUND_PRECISION = 1000;
  const [ calculated, setCalculated ] = useState<number>(0);
  const getPercentage = (now:number, min:number, max:number) => {
    const percentage = ((now - min) / (max - min)) * 100;
    return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
  }
  useEffect(()=>{
    setCalculated(getPercentage(now,min,max));
  },[now,min,max])

  return (
    <div className={cx(css.root, className)}>
      <div className={cx(css.progress, css[color])} style={{width:`${calculated}%`}} />
      <div className={css.content}>{children? children : now }</div>
    </div>
  )
}

