// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionPoolInfo
 */
export interface SessionPoolInfo {
    /**
     * 
     * @type {string}
     * @memberof SessionPoolInfo
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPoolInfo
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPoolInfo
     */
    maxInstances?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPoolInfo
     */
    drainMode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPoolInfo
     */
    verboseLogging?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPoolInfo
     */
    stopped?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionPoolInfo
     */
    connectedServers?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SessionPoolInfo
     */
    apps?: { [key: string]: number; };
}

export function SessionPoolInfoFromJSON(json: any): SessionPoolInfo {
    return SessionPoolInfoFromJSONTyped(json, false);
}

export function SessionPoolInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionPoolInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'maxInstances': !exists(json, 'maxInstances') ? undefined : json['maxInstances'],
        'drainMode': !exists(json, 'drainMode') ? undefined : json['drainMode'],
        'verboseLogging': !exists(json, 'verboseLogging') ? undefined : json['verboseLogging'],
        'stopped': !exists(json, 'stopped') ? undefined : json['stopped'],
        'connectedServers': !exists(json, 'connectedServers') ? undefined : json['connectedServers'],
        'apps': !exists(json, 'apps') ? undefined : json['apps'],
    };
}

export function SessionPoolInfoToJSON(value?: SessionPoolInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'priority': value.priority,
        'maxInstances': value.maxInstances,
        'drainMode': value.drainMode,
        'verboseLogging': value.verboseLogging,
        'stopped': value.stopped,
        'connectedServers': value.connectedServers,
        'apps': value.apps,
    };
}


