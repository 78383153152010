// .core
import React from 'react'
// components
import { NoData } from 'components'
// i18n
import i18n from 'i18n'
// libraries
import { Route } from 'react-router-dom'
// styles
import css from './PageNotFound.module.scss'

/**
 * Wrapper for 404 not found page
 */
export const PageNotFound = () => (
  <Route path="*">
    <div className={css.wPageNotFound}>
      <NoData message={i18n.t('TOAST_404')} />
    </div>
  </Route>
)
