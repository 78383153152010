// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServerLogInfo
 */
export interface ServerLogInfo {
    /**
     * 
     * @type {string}
     * @memberof ServerLogInfo
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerLogInfo
     */
    cluster?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerLogInfo
     */
    logDir?: string;
}

export function ServerLogInfoFromJSON(json: any): ServerLogInfo {
    return ServerLogInfoFromJSONTyped(json, false);
}

export function ServerLogInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerLogInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cluster': !exists(json, 'cluster') ? undefined : json['cluster'],
        'logDir': !exists(json, 'logDir') ? undefined : json['logDir'],
    };
}

export function ServerLogInfoToJSON(value?: ServerLogInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cluster': value.cluster,
        'logDir': value.logDir,
    };
}


