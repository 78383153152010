// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecordingInfo,
    RecordingInfoFromJSON,
    RecordingInfoFromJSONTyped,
    RecordingInfoToJSON,
    ThreadDumpInfo,
    ThreadDumpInfoFromJSON,
    ThreadDumpInfoFromJSONTyped,
    ThreadDumpInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwingSession
 */
export interface SwingSession {
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    pid?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    userIp?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    userOs?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    userBrowser?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    application?: string;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    applicationPath?: string;
    /**
     * 
     * @type {number}
     * @memberof SwingSession
     */
    startedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof SwingSession
     */
    endedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    connected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    applet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SwingSession
     */
    disconnectedSince?: number;
    /**
     * 
     * @type {RecordingInfo}
     * @memberof SwingSession
     */
    recordingFile?: RecordingInfo;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    recordingStatus?: SwingSessionRecordingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    mirroringStatus?: SwingSessionMirroringStatusEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SwingSession
     */
    stats?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SwingSession
     */
    metrics?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    status?: SwingSessionStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwingSession
     */
    warnings?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwingSession
     */
    warningHistory?: Array<string>;
    /**
     * 
     * @type {Array<ThreadDumpInfo>}
     * @memberof SwingSession
     */
    threadDumps?: Array<ThreadDumpInfo>;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    applicationUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    loggingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    statisticsLoggingEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SwingSession
     */
    sessionPoolId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    canRecord?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    canMirror?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwingSession
     */
    detached?: boolean;
}

export function SwingSessionFromJSON(json: any): SwingSession {
    return SwingSessionFromJSONTyped(json, false);
}

export function SwingSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwingSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'pid': !exists(json, 'pid') ? undefined : json['pid'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'userIp': !exists(json, 'userIp') ? undefined : json['userIp'],
        'userOs': !exists(json, 'userOs') ? undefined : json['userOs'],
        'userBrowser': !exists(json, 'userBrowser') ? undefined : json['userBrowser'],
        'application': !exists(json, 'application') ? undefined : json['application'],
        'applicationPath': !exists(json, 'applicationPath') ? undefined : json['applicationPath'],
        'startedAt': !exists(json, 'startedAt') ? undefined : json['startedAt'],
        'endedAt': !exists(json, 'endedAt') ? undefined : json['endedAt'],
        'connected': !exists(json, 'connected') ? undefined : json['connected'],
        'applet': !exists(json, 'applet') ? undefined : json['applet'],
        'disconnectedSince': !exists(json, 'disconnectedSince') ? undefined : json['disconnectedSince'],
        'recordingFile': !exists(json, 'recordingFile') ? undefined : RecordingInfoFromJSON(json['recordingFile']),
        'recordingStatus': !exists(json, 'recordingStatus') ? undefined : json['recordingStatus'],
        'mirroringStatus': !exists(json, 'mirroringStatus') ? undefined : json['mirroringStatus'],
        'stats': !exists(json, 'stats') ? undefined : json['stats'],
        'metrics': !exists(json, 'metrics') ? undefined : json['metrics'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'warnings': !exists(json, 'warnings') ? undefined : json['warnings'],
        'warningHistory': !exists(json, 'warningHistory') ? undefined : json['warningHistory'],
        'threadDumps': !exists(json, 'threadDumps') ? undefined : ((json['threadDumps'] as Array<any>).map(ThreadDumpInfoFromJSON)),
        'applicationUrl': !exists(json, 'applicationUrl') ? undefined : json['applicationUrl'],
        'loggingEnabled': !exists(json, 'loggingEnabled') ? undefined : json['loggingEnabled'],
        'statisticsLoggingEnabled': !exists(json, 'statisticsLoggingEnabled') ? undefined : json['statisticsLoggingEnabled'],
        'sessionPoolId': !exists(json, 'sessionPoolId') ? undefined : json['sessionPoolId'],
        'canRecord': !exists(json, 'canRecord') ? undefined : json['canRecord'],
        'canMirror': !exists(json, 'canMirror') ? undefined : json['canMirror'],
        'detached': !exists(json, 'detached') ? undefined : json['detached'],
    };
}

export function SwingSessionToJSON(value?: SwingSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pid': value.pid,
        'user': value.user,
        'userIp': value.userIp,
        'userOs': value.userOs,
        'userBrowser': value.userBrowser,
        'application': value.application,
        'applicationPath': value.applicationPath,
        'startedAt': value.startedAt,
        'endedAt': value.endedAt,
        'connected': value.connected,
        'applet': value.applet,
        'disconnectedSince': value.disconnectedSince,
        'recordingFile': RecordingInfoToJSON(value.recordingFile),
        'recordingStatus': value.recordingStatus,
        'mirroringStatus': value.mirroringStatus,
        'stats': value.stats,
        'metrics': value.metrics,
        'status': value.status,
        'warnings': value.warnings,
        'warningHistory': value.warningHistory,
        'threadDumps': value.threadDumps === undefined ? undefined : ((value.threadDumps as Array<any>).map(ThreadDumpInfoToJSON)),
        'applicationUrl': value.applicationUrl,
        'loggingEnabled': value.loggingEnabled,
        'statisticsLoggingEnabled': value.statisticsLoggingEnabled,
        'sessionPoolId': value.sessionPoolId,
        'canRecord': value.canRecord,
        'canMirror': value.canMirror,
        'detached': value.detached,
    };
}

/**
* @export
* @enum {string}
*/
export enum SwingSessionRecordingStatusEnum {
    NOTRECORDING = 'NOT_RECORDING',
    WAITINGFORRECORDINGAPPROVAL = 'WAITING_FOR_RECORDING_APPROVAL',
    DENIEDRECORDINGBYUSER = 'DENIED_RECORDING_BY_USER',
    RECORDING = 'RECORDING'
}
/**
* @export
* @enum {string}
*/
export enum SwingSessionMirroringStatusEnum {
    NOTMIRRORING = 'NOT_MIRRORING',
    WAITINGFORMIRRORINGAPPROVAL = 'WAITING_FOR_MIRRORING_APPROVAL',
    DENIEDMIRRORINGBYUSER = 'DENIED_MIRRORING_BY_USER',
    MIRRORING = 'MIRRORING'
}
/**
* @export
* @enum {string}
*/
export enum SwingSessionStatusEnum {
    NOTSTARTED = 'NOT_STARTED',
    EXITING = 'EXITING',
    RUNNING = 'RUNNING',
    FORCEKILLED = 'FORCE_KILLED',
    FINISHED = 'FINISHED'
}


