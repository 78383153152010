import { toPath, basicApi, manageConfigurationApi, manageApplicationsApi, 
  getAuthenticationHeaders,
  handleRestError} from 'utils';
import { SearchVariablesTypeEnum, ResolveTypeEnum, Config } from "../gen";
import {AccessService} from './AccessHandlerService';
import i18n from 'i18n';


class ConfigRestService {
  
  private static _instance:ConfigRestService = new ConfigRestService();
  public serverUrl: string | undefined;
  
  constructor() {
    if(ConfigRestService._instance){
      throw new Error("Error: Instantiation failed: Use ConfigRestService.getInstance() instead of new.");
    }
    ConfigRestService._instance = this;
  }
  
  static getInstance():ConfigRestService
  {
    return ConfigRestService._instance;
  }

  async getAccessToken() {
    return await AccessService.getAccessToken();
  }

  async getServerUrl() {
	  if (!this.serverUrl || this.serverUrl === null) {
		  await this.getUrl().then((data: any) => {
        if (data) {
          this.serverUrl = data;
        }
		  });
	  }
	  return this.serverUrl;
  }
  
  async getPaths() {
    try {
      const globalApi = basicApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await globalApi.getPaths();
    } catch (e) {
      handleRestError(i18n.t('error.getPath'),e)
    }
  }

  async getUrl() {
    try {
      const appApi = basicApi({});
      return await appApi.getUrl();
    } catch (e) {
      handleRestError(i18n.t('error.getUrl'),e)
    }
  }
  
  async getInfo(path: string) {
    try {
      const appApi = basicApi(getAuthenticationHeaders(await this.getAccessToken()), path);
      return await appApi.getInfo();
    } catch (e) {
      handleRestError(i18n.t('error.getInfo'),e)
    }
  }
  
  async getConfig(path: string) {
    try {
      const appApi = manageConfigurationApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      return await appApi.getConfig();
    } catch (e) {
      handleRestError(i18n.t('error.getConfig'),e)
    }
  }
  
  async setConfig(config: Config, path: string) {
    try {
      const appApi = manageConfigurationApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      await appApi.saveConfig({"config": config});
    } catch (e) {
      handleRestError(i18n.t('error.setConfig'),e)
    }
  }
  
  async start(path: string) {
    try {
      const appApi = manageApplicationsApi(getAuthenticationHeaders(await this.getAccessToken()))
      appApi.startApp({appPath:toPath('', path)});
    } catch (e) {
      handleRestError(i18n.t('error.startApp'),e)
    }
  }
  
  async stop(path: string) {
    try {
      const appApi = manageApplicationsApi(getAuthenticationHeaders(await this.getAccessToken()));
      appApi.stopApp({appPath:toPath('', path)});
    } catch (e) {
      handleRestError(i18n.t('error.stopApp'),e)
    }
  }

  async create(path: string, name:string) {
    try {
      const appApi = manageApplicationsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await appApi.createApp({appPath:toPath('', path),appName:name});
    } catch (e) {
      handleRestError(`${i18n.t('apps.toastCreateFailed')} ${name}`,e)
    }
  }

  async remove(path: string) {
    try {
      const appApi = manageApplicationsApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await appApi.removeApp({appPath:toPath('', path)});
    } catch (e) {
      handleRestError(`${i18n.t('error.removeApp',{app:path})}`,e)
    }
  }

  async getVariables(
    path: string,
    type: SearchVariablesTypeEnum,
    searchSequence: string,
    sessionPoolId?: string
  ) {
    try {
      const appApi = manageConfigurationApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      return await appApi.searchVariables({type, search:searchSequence, sessionPoolId});
    } catch (e) {
      handleRestError(i18n.t('error.getVar'),e)
    }
  }

  async resolve(path: string, type: ResolveTypeEnum, searchSequence: string, sessionPoolId?:string) {
    try {
      const swingAppApi = manageConfigurationApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      return await swingAppApi.resolve({type, resolve:searchSequence, sessionPoolId});
    } catch (e) {
      handleRestError(i18n.t('error.resolve'),e)
    }
  }

  async getMeta(path: string, config: Config) {
    try {
      const swingAppApi = manageConfigurationApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      return await swingAppApi.getMeta({"config": config});
    } catch (e) {
      handleRestError(i18n.t('error.getMeta'),e)
    }
  }

  async getVersion() {
    try {
      const globalApi = basicApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await globalApi.getVersion();
    } catch (e) {
      handleRestError(i18n.t('error.getVersion'),e)
    }
  }
  
}

export const ConfigService = ConfigRestService.getInstance();