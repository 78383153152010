import { SessionView } from './SessionView/SessionView';
// .core
import React from 'react';
// utils
import { IPageProps, TIcon } from 'utils';
import { Overview } from './Overview/Overview';
import { Sessions } from './Sessions/Sessions';
import { Logs } from './LogsView/Logs';
import { ConfigServer } from './Overview/ConfigServer';
import { AppsSwing } from './Applications/AppsSwing';
import { AppsView } from './Applications/AppsView';
import { LogsInstancesList } from './LogsView/LogsInstancesList';
/**
 * Config for UI general elements of a page
 */
export interface IUIConfig {
  bShowBreadcrumbs?: boolean
  bShowFooter?: boolean
  bShowHeader?: boolean
  bShowHeaderSearchbar?: boolean
  /**
   * Icon to use in sidebar - if the app has one
   */
  icon?: TIcon
}

/**
 * Config for navigation route (a page)
 */
export interface IRoute<RouteStack> {
  component: React.LazyExoticComponent<any> | React.FunctionComponent<IPageProps> | React.ComponentClass<IPageProps>
  path: RouteStack
  exact?: boolean
  /**
   *
   * ! WORK IN PROGRESS ! - NOT YET IMPLEMENTED !
   *
   * Collection of subroutes for this page - Home -> Table View -> Entry Detail -> Entry Detail Tab etc.
   * Also used for generatinng breadcrumbs
   */
  subroutes?: IRoute<RouteStack>[]
  UIConfig?: IUIConfig
}

/**
 * List of routes
 */
export type TRoutes = 'overview' | 'overview/servers' | 'overview/sessionpools' | 'overview/server-config' | 'apps'| 'apps/:app' | 'logs/:type?/:app?/:id?/:spId?' | 'logs/session/:app'| 'sessions/:app?' | 'session/:app/:id?'

/**
 * Routing config for authentificated pages - core of the app - when user is already logged in yet
 */
export const ROUTES: IRoute<TRoutes>[] = [
  {
    component: Overview,
    path: 'overview',
    exact: true,
  },
  {
    component: Overview,
    path: 'overview/servers',
    exact: true,
  },
  {
    component: Overview,
    path: 'overview/sessionpools',
    exact: true
  },
  {
    component: ConfigServer,
    path: 'overview/server-config',
    exact: true
  },
  {
    component: Sessions,
    path: 'sessions/:app?',
    exact: true,
  },
  {
    component: SessionView,
    path: 'session/:app/:id?',
    exact: true,
  },
  {
    component: LogsInstancesList,
    path: 'logs/session/:app',
    exact: true
  },
  {
    component: Logs,
    path: 'logs/:type?/:app?/:id?/:spId?',
  },
  {
    component: AppsView,
    path: 'apps',
    exact: true,
  },
  {
    component: AppsSwing,
    path: 'apps/:app',
  },
];


export const changeTitle = (title: string) => {
  document.title = `${title} | Admin Console | Webclient`
}
