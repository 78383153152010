// .core
import React, { ReactNode } from 'react'
// libs
import classnames from 'classnames'
// navigation
import { IUIConfig } from 'views/routes'
// styles
import css from './Content.module.scss'
import { observer } from 'mobx-react';
import { useStore } from 'stores';

interface IContentProps extends Pick<IUIConfig, 'bShowHeader'> {
  children: ReactNode
  className?: string
}

export const Content: React.FC<IContentProps> = observer(({ className, children }: IContentProps) => {
  const {globalStore} = useStore()
  return (
    <div className={classnames(css.content, className, {[css.collapsed]: !globalStore.leftSideMenuOpen})}>
      <main>
        {children}
      </main>
    </div>
  )
})
