// core
import React from 'react'

// libraries
import cx from 'classnames'

// components
import { IWrapperComponentProps } from 'components'

// styles
import css from './Switch.module.scss'

export type SwitchColor = 'white' | 'neutral' | 'primary' | 'success' | 'blue'

export type SwitchSize = 'small' | 'medium' | 'large'

export interface ISwitchProps extends IWrapperComponentProps {
  value?: boolean
  /**
   * Coin size
   */
  size?: SwitchSize
  position?: 'right' | 'left'
  /**
   * onClick event
   */
  onClick?(): void
}

export const Switch = ({ children, className, size = 'medium',position='left', value = false, onClick }: ISwitchProps) => {
  return (
    // <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
    <div className={cx(css.wrapper, css[position], className)}>
        <span className={cx(css.root, css[size], {[css.on]: value})} onClick={onClick}
      //class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
       aria-pressed="false">
        {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
        <span aria-hidden="true" className={css.circle}
        //class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        ></span>
      </span>
      {children}
    </div>
  )
  // </span><div className={classnames(css.root, css[color], css[size], className)} onClick={()=>value=!value}>{children}</div>
}

