import { makeAutoObservable, runInAction } from 'mobx'
import { AccessService } from 'services'
import { basicApi, getAuthenticationHeaders, handleRestError } from 'utils';
import { computedFn } from 'mobx-utils'
import {Permissions as IPermissions} from 'gen'
import { RootStore } from './RootStore';

class PermissionsStore {
  private rootStore: RootStore | undefined;
  private permissionsL: IPermissions = this.newPermissions();
  checkingPermissions:boolean = true

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore;
  }

  get permissions() {
    return this.permissionsL
  }

  reload = async() => {
    try {
      const accessToken = await this.getAccessToken();
      const appApi = basicApi(getAuthenticationHeaders(accessToken));
      const data = await appApi.getPermissions();
      if (data) {
        runInAction(() => {
          this.permissionsL = {...this.permissionsL, ...data}
          this.checkingPermissions = false;
        })
      }
    } catch (e) {
      handleRestError('failed to reload permissions', e)
    }
  }

  get = computedFn(function (this: PermissionsStore, item:keyof IPermissions) {
    return this.permissionsL[item] as boolean | null | string
  })

  public isLoggedIn() {
    const perm = this.permissions;
    return perm != null && perm.user != null;
  }

  private newPermissions() {
	  return {
	    configEdit: false,
	    configSwingEdit: false
	  };
  }

  private async getAccessToken() {
    return await AccessService.getAccessToken();
  }
  
}

export default PermissionsStore;