// .core
import React from 'react'
// components
import {} from '../Button/Button'
import { Icon } from '../Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './Avatar.module.scss'
// utils
import { TIcon } from 'utils'

interface IAvatarProps {
  className?: string
  icon?: TIcon | string
  /**
   * Src path to an image
   */
  src?: string
  /**
   * 1-2 letter long label to display instead of an image
   *
   * Can also be used as a image fallback for `alt` if it fails to load
   */
  label?: string
  size?: 'small' | 'medium' | 'large' | 'huge'
  shape?: 'circle' | 'round' | 'square' | 'expandableCircle'
  style?: React.CSSProperties
  onClick?(): void
  bExpanded?:boolean
}

export const Avatar = React.forwardRef(
  (
    {
      className,
      icon,
      label,
      size = 'medium',
      src,
      shape = 'circle',
      style,
      onClick,
      bExpanded=false
    }: IAvatarProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={cx(css.wAvatar, css[shape], css[size], className, {[css.expanded]:bExpanded})}
      style={style}
      onClick={onClick}>
      {/* ICON */}
      {icon && !src && <Icon name={icon} />}

      {/* LABEL */}
      {label && !icon && !src && <span>{label}</span>}

      {/* IMAGE */}
      {src && !icon && <img alt={label || 'avatar'} className={css.wImg} src={src} />}
      {!src && !label && !icon && <Icon name="person" />}
    </div>
  )
)

interface IAvatarGroupProps {
  avatars: IAvatarProps[]
  className?: string
  /**
   * Ammount of avatars to display - all remaining ones will be grouped into 1 w/ label of their count
   */
  limit?: number
}

export const AvatarGroup = ({ avatars, className, limit }: IAvatarGroupProps) => (
  <div className={cx(css.wAvatarGroup, className)}>
    {avatars.slice(0, limit).map((avatar, index) => (
      <Avatar
        {...avatar}
        key={'avatar_' + index}
        className={cx(css.wAvatarStack, avatar.className)}
        style={{ zIndex: avatars.length - index }}
        onClick={avatar.onClick}
      />
    ))}

    {/* REMAINING GROUPED AVATARS */}
    {limit && avatars.length > limit && <Avatar label={`+${avatars.length - limit}`} />}
  </div>
)
