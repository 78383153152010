import React, { useCallback } from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'

type IntervalFunction = () => ( unknown | void )

export function useInterval( callback: IntervalFunction, delay: number ) {

  const savedCallback = useRef<IntervalFunction| null>( null )

  // Remember the latest callback.
  useEffect( () => {
    savedCallback.current = callback
  } )

  // Set up the interval.
  useEffect( () => {
    function tick() {
      if ( savedCallback.current !== null ) {
        savedCallback.current()
      }
    }
    const id = setInterval( tick, delay )
    return () => clearInterval( id )

  }, [ delay ] )
}

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el: any, initialState:boolean) => {
  const [isActive, setIsActive]:[boolean,Dispatch<SetStateAction<boolean>>] = useState<boolean>(initialState);

  useEffect(() => {
    const onClick = (e: { target: any }) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

export const useTimer = (initialState = 0) => {
  const [timer, setTimer] = React.useState(initialState)
  const [isActive, setIsActive] = React.useState(false)
  const [isPaused, setIsPaused] = React.useState(false)
  const countRef = React.useRef<IntervalFunction| any>(null)

  const handleStart = () => {
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const handlePause = () => {
    clearInterval(countRef.current)
    setIsPaused(false)
  }

  const handleResume = () => {
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const handleReset = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }

  return { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset }
}

export const useRootOverflow = () =>{
  useEffect(() => {
    const root = document.getElementById('root')!
    root.style.overflow = 'hidden';
    return () => {root.style.overflow = ''}
  },[])
}

export const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}