import React, { useState } from "react";
import cx from 'classnames';
import css from '../Sessions.module.scss';
import { SwingSession } from "gen";
import { Dialog, Icon } from "components";
import { useHistory } from "react-router-dom";
import { useStore } from "stores";
import { Warnings } from "views/SessionView/partials/Warnings";
import { useTranslation } from "react-i18next";
// OS




interface IWarningsCellProps {
    session:SwingSession,
    bFinished?:boolean,
}

export const WarningsCell = ({session, bFinished=false}:IWarningsCellProps) => {
    const { sessionsStore } = useStore();
    const history = useHistory();
    const {t} = useTranslation();
    const [warningOpen, setWarningOpen] = useState<boolean>(false)

    const viewWarnings = (session: SwingSession) => {
        if (sessionsStore.getWarningsNum(session) && !bFinished) {
            history.push('/session' + session.applicationPath +'/'+ session.id)
            sessionsStore.setActiveTab('warnings');
        }
        if (sessionsStore.getWarningsNum(session) && bFinished) {
            setWarningOpen(b=>!b)
        }
        return
    }

    return (
        <>
            <div className={cx(css.Cell, css.warningsCell, {[css.has]:sessionsStore.getWarningsNum(session)})} onClick={()=>viewWarnings(session)}>
                <Icon name='warning' /> 
                {sessionsStore.getWarningsNum(session) ? t('sessions.warningWithCount', {count: sessionsStore.getWarningsNum(session)}) : t('sessions.noWarnings') }
            </div>
            <Dialog
                bOpen={warningOpen}
                title={t('sessions.warning_plural')}
                onToggle={(b:boolean)=>setWarningOpen(b)}
                footer={false}
            >
                <Warnings session={session} />
            </Dialog>
      </>
    )
}