import { makeAutoObservable, runInAction } from 'mobx'
import { SessionsService } from 'services'
import { RecordingInfo, ThreadDumpInfo } from 'gen/models'

class RecordingsAndThreadDumpsStore {

  recordings: RecordingInfo[] = [];
  threadDumps: ThreadDumpInfo[] = [];
  loading: boolean = false;
  interval: NodeJS.Timeout | undefined;

  constructor() {
    makeAutoObservable(this)
  }

  initData = (app: string, sessionId?: string) => {
    this.recordings = [];
    this.threadDumps = [];
    this.refresh(app, sessionId);
  }

  loadData = async (app: string, sessionId?: string) => {
    this.loading = true;
    const recData = await SessionsService.getRecordings(app, sessionId);
    if (recData) {
      runInAction(() => {
        this.recordings = recData;
        this.loading = false;
      });
    }
    this.loading = true;
    const tdData = await SessionsService.getThreadDumps(app, sessionId);
    if (tdData) {
      runInAction(() => {
        this.threadDumps = tdData;
        this.loading = false;
      });
    }
  }

  setInterval(callback: any, delay: number) {
    this.interval = setInterval(() => {
      callback()
    }, delay);
  }
  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  refresh(app: string, sessionId?: string) {
    this.loadData(app, sessionId).then(() => {
      this.clearInterval();
    })
    .then(() => {
        this.setInterval(() => {
          this.refresh(app, sessionId);
        }, 5000)
    })
    .catch(()=>{
        this.clearInterval();
    })
  }

}

export default RecordingsAndThreadDumpsStore;