import React from "react";
import { Flex, ProgressBar } from "components";
import css from '../Sessions.module.scss'
import { SwingSession } from "gen";
import { useTranslation } from "react-i18next";

interface IMetricsCellProps {
   session:SwingSession
}

export const MetricsCell = ({session}:IMetricsCellProps) => {
    const {t} = useTranslation();
    
    return (
        <Flex flexWrap={'nowrap'} spacing={5} alignItems={'center'} className={css.MetricsCellChild}>
            <Flex bColumn spacing={1} flex={1} justifyContent={'center'}> 
                <h4>{t('sessions.default')}</h4>
                <ProgressBar color={'primary'} max={session.metrics?.memoryAllocated} now={session.metrics?.memoryUsed!}>
                <span>{'MEM: '+(session.metrics?.memoryUsed || 0)+'MB'}</span>
                <small>({session.metrics!['memoryUsed.MIN']||0}|{session.metrics!['memoryUsed.AVG']||0}|{session.metrics!['memoryUsed.MAX']||0})</small>
                </ProgressBar>
                <ProgressBar color={'success'} now={session.metrics?.cpuUtilization!}>
                {'CPU: '+(session.metrics?.cpuUtilization!||0) +'% '}
                <small>({session.metrics!['cpuUtilization.MIN']||0}|{session.metrics!['cpuUtilization.AVG']||0}|{session.metrics!['cpuUtilization.MAX']||0})</small>
                </ProgressBar>
            </Flex>
            <Flex bColumn spacing={1} flex={1} justifyContent={'center'}> 
                <h4>{t('sessions.bandwidth')}</h4>
                <ProgressBar color={'success'} max={102400} now={session.metrics?.inboundSize!}>
                {'IN: '+(Math.round((session.metrics!.inboundSize/1024)||0))+'k/s'}
                <small>({Math.round(session.metrics!['inboundSize.MIN']/1024)}|{Math.round(session.metrics!['inboundSize.AVG']/1024)}|{Math.round(session.metrics!['inboundSize.MAX']/1024)})</small>
                </ProgressBar>
                <ProgressBar color={'primary'} max={102400} now={session.metrics!?.outboundSize!}>
                {'OUT: '+((Math.round(session.metrics!.outboundSize/1024)))+'k/s'}
                <small>({Math.round(session.metrics!['outboundSize.MIN']/1024)}|{Math.round(session.metrics!['outboundSize.AVG']/1024)}|{Math.round(session.metrics!['outboundSize.MAX']/1024)})</small> 
                </ProgressBar>
            </Flex>
            <Flex bColumn spacing={1} flex={1} justifyContent={'center'}> 
                <h4>{t('sessions.latency')}</h4>
                <ProgressBar color={'danger'} max={700} now={session.metrics!.latency!}>
                {'E2E: '+(session.metrics!.latency||0)+'ms'}
                <small>({session.metrics!['latency.MIN']||0}|{session.metrics!['latency.AVG']||0}|{session.metrics!['latency.MAX']||0})</small>
                </ProgressBar>
                <ProgressBar color={'danger'} max={500} now={session.metrics!?.latencyPing!}>
                {'PING: '+(session.metrics!.latencyPing||0)+'ms'}
                <small>({session.metrics!['latencyPing.MIN']||0}|{session.metrics!['latencyPing.AVG']||0}|{session.metrics!['latencyPing.MAX']||0})</small>
                </ProgressBar>
            </Flex>
        </Flex>
    )
}