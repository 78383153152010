// .core
import React, { useEffect, useState } from 'react'
// components
import { Button } from 'components'
// styles
import css from '../Overview.module.scss'
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts'
import { useTranslation } from 'react-i18next'

interface IServerCardProps {
  className?: string,
  width?: number,
  height?: number,
  onClick?(): void,
  /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SessionPoolInfo
     */
   apps?: { [key: string]: number; }
}


export const SpPie = ({
  className,
  width,
  height,
  onClick,
  apps,
}: IServerCardProps) => {
  const [allZero, setAllZero] = useState(true)
  const {t} = useTranslation()

  const getSpApps = () => {
    return Object.entries(apps!).map((e) => ( { name:e[0], value:e[1] } ));
  }
  const getSpSum = () => {
    let sum:number = 0
    Object.entries(apps!).forEach((item)=>{
      sum += item[1]
    });
    return sum
  }

  useEffect(()=> {
    setAllZero(getSpSum()?false:true);
  },[apps]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  return (
    <>
      {/* <ResponsiveContainer width="100%" height="100%"> */}
        <PieChart onClick={onClick} className={className} width={width} height={height}>
          <Pie
            data={allZero?[{name:'no',value:1}]:getSpApps()}
            innerRadius={46}
            outerRadius={58}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            <Label 
            value={getSpSum()} position="centerBottom"  className={css.cakeLabelTop} fontSize='27px'
            />
            <Label 
            value={t('overview.running') as string} position="centerTop" className={css.cakeLabelBottom}
            />
            {getSpApps().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={allZero? '#E5E5E6':COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          {!allZero && <Tooltip />}
        </PieChart>
      {/* </ResponsiveContainer> */}
    </>
  )
}

const HeaderButton = () => <Button icon={'expand_more'}  />