// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadDumpInfo
 */
export interface ThreadDumpInfo {
    /**
     * 
     * @type {number}
     * @memberof ThreadDumpInfo
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    appPath?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    instanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof ThreadDumpInfo
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreadDumpInfo
     */
    sessionPoolId?: string;
}

export function ThreadDumpInfoFromJSON(json: any): ThreadDumpInfo {
    return ThreadDumpInfoFromJSONTyped(json, false);
}

export function ThreadDumpInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThreadDumpInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'appPath': !exists(json, 'appPath') ? undefined : json['appPath'],
        'instanceId': !exists(json, 'instanceId') ? undefined : json['instanceId'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sessionPoolId': !exists(json, 'sessionPoolId') ? undefined : json['sessionPoolId'],
    };
}

export function ThreadDumpInfoToJSON(value?: ThreadDumpInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'reason': value.reason,
        'fileName': value.fileName,
        'token': value.token,
        'appPath': value.appPath,
        'instanceId': value.instanceId,
        'size': value.size,
        'sessionPoolId': value.sessionPoolId,
    };
}


