// .core
import React, { useState, useCallback } from 'react'
// components
import { Icon } from '../../..'
// libraries
import cx from 'classnames'
// styles
import css from './InputDefault.module.scss'
// utils
import { ISelectItem, TIcon } from 'utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
// declarations
type TValue = string | number

interface IInputIcon {
  className?: string
  name: TIcon
  position: 'labelLeft' | 'labelRight' // 'inputLeft' | 'inputRight' |
}

export interface IInputDefaultProps {
  bAutoFocus?: boolean
  bClearable?: boolean
  bDisabled?: boolean
  bReadOnly?: boolean
  bRequired?: boolean
  bTextArea?: boolean

  className?: string
  classNameError?: string
  classNameInput?: string
  classNameLabel?: string

  error?: string
  id?: string
  label?: string | React.ReactNode
  icon?: IInputIcon
  max?: number
  maxLength?: number
  min?: number
  minLength?: number
  name?: string
  placeholder?: string
  value: TValue
  options?: ISelectItem[]
  type?: 'email' | 'text' | 'number' | 'password' | 'checkbox' | 'select' | 'hidden'
  onClose?(event?: any):void
  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onKeyPress?(event:React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>):void
}

/** #TODO: turn into `React.FC`
 * Default Input component
 */
export const InputDefault = ({
  bAutoFocus,
  bClearable,
  bDisabled,
  bReadOnly,
  bRequired,
  bTextArea,

  className,
  classNameError,
  classNameInput,
  classNameLabel,

  error,
  icon,
  id,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  placeholder = i18n.t('ENTER_VALUE'),
  type = 'text',
  value = '',

  onClose,
  onBlur,
  onChange,
  onKeyPress
}: IInputDefaultProps) => {
  const input = React.createRef<HTMLInputElement | HTMLTextAreaElement>()
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
  const handleToggleVisibility = useCallback(() => {
    setVisiblePassword(prev => !prev)
  }, [setVisiblePassword])

  const _onClear = () => {
    const event = new Event('input', { bubbles: true })

    if (input.current && onChange) {
      input.current.value = ''
      input.current.dispatchEvent(event)
      onChange(event as any)
    }
  }

  return (
    <div className={cx(css.wInput, className, type)}>
      {/* LABEL - container */}
      {label && 'hidden' !== type && (
        <div className={css.wLabel}>
          {/* ICON - left from label */}
          {icon && icon.position === 'labelLeft' && <Icon {...icon} />}

          {/* LABEL - text */}
          <label
            className={cx(css.tLabel, bRequired && 'gInputRequired', classNameLabel, {
              [css.disabled]: bDisabled,
            })}
            htmlFor={id || 'input'}>
            {label}
          </label>

          {/* ICON - right from label */}
          {icon && icon.position === 'labelRight' && <Icon {...icon} />}
        </div>
      )}

      {/* TEXT AREA / INPUT FIELD */}
      {bTextArea ? (
        <textarea
          ref={input as React.Ref<HTMLTextAreaElement>}
          autoFocus={bAutoFocus}
          className={cx(css.fInput, classNameInput)}
          disabled={bDisabled}
          id={id}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          readOnly={bReadOnly}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      ) : (
        <div className={css.inputDiv}>
          <input
            ref={input as React.Ref<HTMLInputElement>}
            autoFocus={bAutoFocus}
            className={cx(css.fInput, classNameInput, { [css.password]: type === 'password' })}
            disabled={bDisabled}
            id={id}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            name={name}
            placeholder={placeholder}
            readOnly={bReadOnly}
            type={
              type === 'password' && !visiblePassword
                ? 'password'
                : type === 'password'
                ? 'text'
                : type
            }
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
          />
          {type === 'password' && (
            <Icon
              className={css.visibility}
              color={visiblePassword ? 'primary' : 'neutral'}
              name="visibility"
              size="small"
              onClick={handleToggleVisibility}
            />
          )}
          {/* X - clear icon */}
          {bClearable ?
          <Icon className={css.visibility} name="close" size="small" onClick={_onClear} />
          : onClose ?
          <Icon className={css.visibility} name="close" size="small" onClick={()=>{
            _onClear();
            onClose();
          }} />
          :null
          }
        </div>
      )}

      {/* ERROR MESSAGE */}
      {error && <span className={cx(css.tError, classNameError)}>{error}</span>}
    </div>
  )
}
