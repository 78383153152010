import React, { createContext, FC, ReactElement, ReactNode, useContext } from 'react';
import {store, RootStore} from './RootStore'
export * from './RootStore'


type StoreComponent = FC<{
    store: RootStore;
    children: ReactNode;
}>;

/* Store Context */
const StoreContext = createContext(store);


/* store Provider */
export const StoreProvider: StoreComponent = ({
    children,
    store
  }): ReactElement => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* Hook to use store in any functional component */
export const useStore = () => useContext(StoreContext);