// core
import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
// libraries
import { useLocation } from 'react-router-dom'
import css from './Header.module.scss'

// stores
import { observer } from 'mobx-react'
// partials
import { MenuItem } from './partials/MenuItem'

import {useStore} from 'stores'
// import { toJS } from 'mobx'
import { MENU_ITEMS, INav } from './data'
import { RightToggleMenu } from '../RightToggleMenu/RightToggleMenu'
import { useTranslation } from 'react-i18next'
import { Icon, Popover } from 'components'

export const Header = observer(function Header() {
  const {permissionsStore, globalStore} = useStore();
  const [small, setSmall] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  
  const getPath = (item:INav) => {
    if(item.hasParam && globalStore.app) {
      return item.path.includes('logs') ? 
      'logs/session/'+ globalStore.app
      :
      item.path + '/'+ globalStore.app
    } else {
      return item.path
    }
  }
  const handleExpandMenu = useCallback(() => {
    setExpandMenu(val=>!val);
  }, [setExpandMenu, expandMenu]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.getElementById('root')?.addEventListener("scroll", () => {
        setSmall(document.getElementById('root')?.scrollTop! > 30)
      }
      );
    }
  }, []);
  
  return (
    <header className={cx(css.root, {[css.headerSmaller]:small})}>
      <div className={css.wrap} >
        <Icon name='menu' className={css.hamburger} onClick={handleExpandMenu}></Icon>
        <Popover
          darkBP
          backdrop
          width={240}
          //className={cx(css.popover,{[css.smaller]:bsmall})}
          open={expandMenu}
          side="left"
          onBlur={handleExpandMenu}>
          {MENU_ITEMS.filter(item => null === item.permission || permissionsStore.get(item.permission as any) ).map(item => (
            <MenuItem
              key={item.path}
              activePath={pathname}
              path={getPath(item)}
            >
              {t(item.name)}
            </MenuItem>
          ))}
        </Popover>
        <nav className={css.menu}>
          {MENU_ITEMS.filter(item => null === item.permission || permissionsStore.get(item.permission as any) ).map(item => (
            <MenuItem
              key={item.path}
              activePath={pathname}
              path={getPath(item)}
            >
              {t(item.name)}
            </MenuItem>
          ))}
        </nav>
        
        <RightToggleMenu bsmall={small} />
      </div>
    </header>
  )
})