// core
import React, { HTMLAttributes, useEffect } from 'react'
// libraries
import { useLocation, useHistory, useParams } from 'react-router-dom'
import cx from 'classnames'
// import i18n from 'i18n'
//utils
import { useInterval } from 'utils'
// components
import { Icon, Input, Button, Flex, showToastOk, showToastErr } from 'components'
// partials
import { MenuItem } from './MenuItem'
// styles
import css from '../LeftSideMenu.module.scss'
// data (will be fetched from somewhere later)
import { observer } from 'mobx-react'
import {useStore} from 'stores'
import { CreateApp } from './CreateApp'
import {Scrollbars} from 'rc-scrollbars'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'components/basic/Dropdown/Dropdown'
export const Menu = observer(function AppsMenu() {
  const {permissionsStore, globalStore, logsStore} = useStore();
  const { pathname } = useLocation()
  const history = useHistory()
  const {t} = useTranslation()
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  
  const isConfig = pathname.includes('apps')
  const isLogs = pathname.includes('logs')
  const isAppView = pathname === '/apps'

  const results = !searchTerm
    ? globalStore.paths
    : globalStore.paths?.filter(item =>
        item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        item.path?.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );

  const getAppPaths = (path:string):string => {
    return (isConfig?'apps':isLogs? 'logs/session':'sessions')+path
  }

  const renderThumb = ({ style, ...props }: HTMLAttributes<HTMLDivElement>) => {
    const finalStyle = {
      ...style,
      cursor: 'pointer',
      backgroundColor: 'rgba(255,255,255,.5)',
    };
    return <div style={finalStyle} {...props} />;
  }

  const create = (appPath:string, appName:string) => {
    if(appPath && appName) {
      const duplicateApp = globalStore.isDuplicate ? globalStore.app : undefined
      globalStore.createApp(appPath, appName, duplicateApp)
        .then(() => {
          globalStore.setToggleAddApp()
          showToastOk(`${t('apps.toastCreated')} ${appName}`)
          history.push('/apps');
        })
    }
  }

  const showCreateInput = (appToCopy?:string)=> {
    if(appToCopy) {
      globalStore.setIsDuplicate(true);
    }
    globalStore.setToggleAddApp(true)
  }

  useEffect(()=>{
    globalStore.loadPaths();
  },[])

  useInterval(() => {
    globalStore.loadPaths();
  }, 5000);

  return (
    <>
      <div className={css.menuFlex}>
        <ul className={cx(css.menuItems)}>
          {
            isConfig ?
              <>
                <MenuItem
                  key={'server'}
                  activePath={pathname}
                  path={'apps'}
                  icon={'apps'}
                  >
                    {t('apps.all')}
                  </MenuItem>
              </>
            :isLogs ?
            <>
              <MenuItem key={'admin'} activePath={pathname} path={'logs/admin'} className={css.wfull} icon={'settings_applications'}>
                <div className={css.downloadFlex}>
                  {t('logs.admin')}
                  <Icon className={css.absIcon} name={'cloud_download'} onClick={()=>logsStore.downloadLogs('admin')} />
                </div>
              </MenuItem>
              <MenuItem key={'audit'} activePath={pathname} path={'logs/audit'} className={css.wfull} icon={'verified_user'}>
                <div className={css.downloadFlex}>
                  {t('logs.audit')}
                  {!logsStore.isCluster &&  <Icon className={css.absIcon} name={'cloud_download'} onClick={()=>logsStore.downloadLogs('audit')} /> }
                </div>
              </MenuItem>
              <MenuItem key={'server'} activePath={pathname} path={'logs/server'} className={css.wfull} icon={'dns'}>
                <div className={css.downloadFlex}>
                  {t('overview.server')}
                  {!logsStore.isCluster &&  <Icon className={css.absIcon} name={'cloud_download'} onClick={()=>logsStore.downloadLogs('server')} /> }
                </div>
              </MenuItem>
              {
                logsStore.isCluster &&
                <MenuItem key={'sessionpool'} activePath={pathname} path={'logs/sessionpool'} className={css.wfull} icon={'view_module'}>
                  <div className={css.downloadFlex}>
                    {t('sessions.sp')}
                    {!logsStore.isCluster &&  <Icon className={css.absIcon} name={'cloud_download'} onClick={()=>logsStore.downloadLogs('sessionpool')} /> }
                  </div>
                </MenuItem>
              }
            </>
            :
            <MenuItem key={'sessions'} activePath={pathname} path={'sessions'} icon={'apps'}>
              {t('sessions.all')}
            </MenuItem>
          }
          <div className={css.divider}></div>
          <Flex className={css.toolRow} spacing={2} bColumn={!globalStore.leftSideMenuOpen}>
            {
              (isConfig && permissionsStore.get('create')) && 
              <Dropdown
                className={css.addAppDropdown}
                buttons={[
                  {label: t('apps.createNew'), icon:'add_circle_outline', onClick:()=>showCreateInput()},
                  {
                    sDisabled: isAppView? t('apps.selectAppFirst') : '', 
                    label: t('apps.duplicateConfig') + (isAppView?'':' '+ globalStore.info?.name), 
                    icon:'content_copy', 
                    onClick:()=>showCreateInput(globalStore.app)
                  },
                ]}
                >
                  <Button color={globalStore.toggleAddApp?'neutral':'blue'} icon={'add'} />
              </Dropdown>
            }
            <div className={css.appsFilter}>
              {!globalStore.toggleSearch && <Button color='neutralTrans' icon={'search'} onClick={()=>globalStore.setToggleSearch()} /> } 
              {globalStore.toggleSearch &&
              <Input
                classNameInput={css.input}
                type="text"
                placeholder={t('general.search')}
                value={searchTerm}
                onChange={handleChange}
                onClose={()=>globalStore.setToggleSearch()}
                icon={{name:'x', position:'labelRight'}}
                bAutoFocus
                // onFocus={()=>globalStore.toggleLeftSideMenu(true)}
              />}
            </div>
          </Flex>
          {globalStore.toggleAddApp && isConfig && <CreateApp onSubmit={create} onClose={()=>globalStore.setToggleAddApp()} />}
          <div className={css.scrollable} style={isLogs? {height:'calc(100vh - 324px)'}:{}}>
          <Scrollbars
            renderThumbVertical={renderThumb}
            renderThumbHorizontal={renderThumb}
          style={{height:'100%', width:'100%'}}>
            {results?.map(item => (
              <MenuItem
                key={item.path}
                activePath={pathname}
                path={getAppPaths(item.path!)}
                image={item.path}
              >
                <div className={css.downloadFlex}>
                  <Flex bColumn>
                    {item.name}
                    <small onClick={()=>history.push(item.url!)} >{item.path}</small>
                  </Flex>
                  { isLogs &&  <Icon className={css.absIcon} name={'cloud_download'} onClick={()=>logsStore.downloadSessionLogs(item.path!)} /> }
                </div>

              </MenuItem>
            ))}
          </Scrollbars>
          </div>
        </ul>
      </div>
      <div className={css.sideMenuArrow}>
        <Icon
          size="small"
          color="white"
          className={css.arrowIcon}
          name="keyboard_arrow_left"
          onClick={globalStore.toggleLeftSideMenu}
        />
      </div>
    </>
  )
})
