// .core
import React from 'react'
// components
import { Button, Card, Heading } from 'components'
// i18n
// import i18n from 'i18n'
// styles
import css from './ErrorView.module.scss'
import { Logo } from 'components/layout/Header/partials/Logo'
import { observer } from 'mobx-react'
import {AccessService} from 'services'
import { useTranslation } from 'react-i18next'
import { useStore } from 'stores'
/**
 * Wrapper for 500 and other errors
 */

interface IErrorView {
  header?:string
  bTryAgainBtn?:boolean
  bLoginBtn?:boolean
  subtitle?:string
}

export const ErrorView = observer(({header,bTryAgainBtn=true, bLoginBtn=true, subtitle}:IErrorView) => {
  const {t} = useTranslation()
  const {permissionsStore} = useStore();

  const login = () => {
    if (permissionsStore.isLoggedIn()) {
      AccessService.logout();
    } else {
      AccessService.displayLogin()
    }
  }

  return (
    <div className={css.root}>
      <Card className={css.card} classes={{ content: css.cardFlex }}>
        <Logo/>
        <Heading title={header?t(header):t('error.header')}/>
        <p>
          <p>{subtitle? t(subtitle) : t('error.clickBelow')}</p>
          <small dangerouslySetInnerHTML={{ __html: t('error.contactUs') }} />
        </p>
        <Button.Group className={css.buttons} buttons={[
          {show:bLoginBtn, size:'small', color:'blue', label:t('general.login'), onClick:login},
          {show:bTryAgainBtn, size:'small', color:'neutral', label:t('error.tryAgain'), onClick:()=>window.location.reload()}
        ]} />
  
      </Card>
    </div>
  )

})
