// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionLogInfo
 */
export interface SessionLogInfo {
    /**
     * 
     * @type {string}
     * @memberof SessionLogInfo
     */
    app?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionLogInfo
     */
    sessionPoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionLogInfo
     */
    instanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionLogInfo
     */
    lastModified?: number;
}

export function SessionLogInfoFromJSON(json: any): SessionLogInfo {
    return SessionLogInfoFromJSONTyped(json, false);
}

export function SessionLogInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionLogInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'app': !exists(json, 'app') ? undefined : json['app'],
        'sessionPoolId': !exists(json, 'sessionPoolId') ? undefined : json['sessionPoolId'],
        'instanceId': !exists(json, 'instanceId') ? undefined : json['instanceId'],
        'lastModified': !exists(json, 'lastModified') ? undefined : json['lastModified'],
    };
}

export function SessionLogInfoToJSON(value?: SessionLogInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app': value.app,
        'sessionPoolId': value.sessionPoolId,
        'instanceId': value.instanceId,
        'lastModified': value.lastModified,
    };
}


