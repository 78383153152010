// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Status.module.scss'
import { HighlightedText } from 'views/LogsView/partial/HighlightedText'
// utils
interface IStatusProps {
  className?: string,
  label:string | undefined,
  /**
   * Styling of the point, default theme color will be used if none specified.
   */
  color?: 'new' | 'opened' | 'success'| 'danger' | 'resolved' | 'closed' | 'reopened' | 'exiting' | string,
  onClick?(): void
}

export const Status = ({
  label='',
  color=label.toLowerCase(),
  className,
  onClick,
}: IStatusProps) => {
  
  const getLabel = () => {
    return label ? label.split('_').join(' ').toLowerCase():''
  }

  return (
    <span onClick={onClick} className={cx(css.root, className)}>
      <div className={cx(css.dot, css[color ? color.trim().toLowerCase(): ''])} />
      <HighlightedText text={getLabel()} />
    </span>
  )
}

