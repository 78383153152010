// .core
import React from 'react'
// components
import { InputDefault, IInputDefaultProps } from './variants/InputDefault/InputDefault'
// ---variants
// import { Select } from './variants/Select/Select'

export class Input extends React.Component<IInputDefaultProps> {
  // static Select = Select

  render() {
    return <InputDefault {...this.props} />
  }
}
