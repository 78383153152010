import React from 'react';
import { i18next } from 'i18n';
import ReactDOM from 'react-dom';
import { store, StoreProvider } from 'stores';
import { initBaseLocation } from 'utils';
import App from './App';

initBaseLocation()
  .then(i18next)
  .then(() => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <App />
    </StoreProvider>,
    document.getElementById('root')
  );
}).catch(e => {
  console.error(e);
});


