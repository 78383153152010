import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
import { useStore } from "stores";
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
// styles
import css from '../SessionView.module.scss'
// components
import { Button, Card, Flex, Switch, Icon, SearchFilter, ButtonIcon } from 'components';
import { Charts } from './Charts';
import { LogsTable } from 'views/LogsView/partial/LogsTable';
import { Warnings } from './Warnings';
// utils
import { ApiServerUrl, toPath } from 'utils';
import { RecordingsTable } from 'views/Sessions/partials/RecordingsTable';
import { ThreadDumpsTable } from 'views/Sessions/partials/ThreadDumpsTable';
import { SwingSession, SwingSessionStatusEnum } from 'gen';

interface ISessionViewProps {
    app: string
    id: string
}

export const SessionTabs = observer(({app,id}:ISessionViewProps) => {
    const {t} = useTranslation();
    const {permissionsStore, sessionsStore, logsStore} = useStore();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [max, setMax] = useState<boolean>(false)
    const [mirrorReady, setMirrorReady] = useState<boolean>(false)
    const [wasMirrorOpen, setWasMirrorOpen] = useState<boolean>(false)
    const [control, setControl] = useState<boolean>(false)

    const openMirrorView = () => {
        if(!wasMirrorOpen) {
            setWasMirrorOpen(true);
            window.removeEventListener('message', (event) => receiveMessage(event), false);
            window.addEventListener('message', (event) => receiveMessage(event), false);
        }
        sessionsStore.setActiveTab('mirror');
    }

    const sendIframeMessage = (msg: any) => {
        const iframe = iframeRef.current;
        if (iframe && (iframe as any).contentWindow) {
            (iframe as any).contentWindow.postMessage(msg, sessionsStore.mirrorIframeSrc);
        }
    }
    const receiveMessage = (evt:any) => {
        if (!evt.data || !evt.data.mirror) {
            return;
        }
        
        if (evt.data.height) {
          const iframe = document.querySelector("#mirrorIframe");
          (iframe as any).style.height = evt.data.height;
          (iframe as any).height = evt.data.height;
          (iframe as any).style.width = evt.data.width;
          (iframe as any).width = evt.data.width;
        } else if (evt.data.init) {
            setMirrorReady(true);
        }
    }
    const isFinished = (session: SwingSession) => {
        return session.status !== SwingSessionStatusEnum.RUNNING;
    };

    useEffect(()=> {
        sendIframeMessage({control: control});
    },[control])

    useEffect(()=> {
        if (mirrorReady) {
        	setMirrorReady(false);
            const mirrorUrl = toPath(ApiServerUrl,app);
            sendIframeMessage({baseUrl: sessionsStore.session.applicationUrl, adminWsUrl: mirrorUrl});
        }
    },[mirrorReady])

return (
    <>
    <Flex spacing={2} className={css.btnGroupRow}>
        {
            <ButtonIcon icon='view_compact' active={'view' === sessionsStore.activeTab} onClick={()=>sessionsStore.setActiveTab('view')}>{t('routes.overview')}</ButtonIcon>
        }
        {
            sessionsStore.session.loggingEnabled &&
            <ButtonIcon icon='list' active={'logs' === sessionsStore.activeTab} onClick={()=>sessionsStore.setActiveTab('logs')}>{t('routes.logs')}</ButtonIcon>
        }
        {
            sessionsStore.getWarningsNum() > 0 &&
            <ButtonIcon 
                icon='warning' 
                badge={sessionsStore.getWarningsNum()} 
                badgeColor={'warning'} 
                active={'warnings' === sessionsStore.activeTab} 
                onClick={()=>sessionsStore.setActiveTab('warnings')}
            >
                {t('sessions.warning_plural')}
            </ButtonIcon>
        }
        {
            permissionsStore.get('startMirrorView') && sessionsStore.session.canMirror && !isFinished(sessionsStore.session) &&
            <ButtonIcon icon='visibility' active={'mirror' === sessionsStore.activeTab} onClick={openMirrorView}>{t('sessions.mirrorView')}</ButtonIcon>
        }
        {
            permissionsStore.get('playbackRecording') &&
            <ButtonIcon icon='videocam' active={'recordings' === sessionsStore.activeTab} onClick={()=>sessionsStore.setActiveTab('recordings')}>{t('sessions.tab.recordings')}</ButtonIcon>
        }
        {
            permissionsStore.get('getThreadDump') &&
            <ButtonIcon icon='view_list' active={'threadDumps' === sessionsStore.activeTab} onClick={()=>sessionsStore.setActiveTab('threadDumps')}>{t('sessions.tab.threadDumps')}</ButtonIcon>
        }
        {
            'logs' === sessionsStore.activeTab && 
            <>
                {!logsStore.filterToggle && <Button className={css.m7} color='neutral' icon={'search'} onClick={()=>logsStore.setFilterToggle()} /> }
                {
                logsStore.filterToggle && 
                    <SearchFilter 
                        className={css.m7}
                        value={logsStore.searchTerm} 
                        onChange={(e)=>logsStore.setSearchTerm(e.target.value)} 
                        onClose={()=>logsStore.setFilterToggle()} 
                    />
                }
                {
                    <Switch value={logsStore.compact} size='large' onClick={()=>logsStore.setCompact()}>
                        {logsStore.compact? t('general.wrapEnabled'):t('general.wrapDisabled')}
                    </Switch>
                }
            </>
        }
    </Flex>
    <div className={cx(cssTab.wTabContent)}>
        {
            sessionsStore.activeTab === 'view' &&
            <Card className={cx(css.cardContent, css.cardSpacer)}>
                <Card className={css.toolBar}>
                    <Flex className={css.wRow}>
                        <Flex bColumn className={cx(css.smCol, css.noBorder)} flex={1}>
                            <span className={css.label}>{t('sessions.instanceId')}:</span>
                            {sessionsStore.session?.id}
                        </Flex>
                        <Flex bColumn className={css.smCol} flex={1}>
                            <span className={css.label}>{t('sessions.spId')}:</span>
                            {!isFinished(sessionsStore.session) && sessionsStore.session?.sessionPoolId}
                        </Flex>
                        <Flex bColumn className={css.smCol} flex={1}>
                            <span className={css.label}>{t('sessions.processId')}:</span>
                            {!isFinished(sessionsStore.session) && sessionsStore.session?.pid}
                        </Flex>
                    </Flex>
                </Card>
                {
                    !isFinished(sessionsStore.session) && 
                    <Switch value={sessionsStore.statisticsLoggingEnabled} size='large' onClick={()=>sessionsStore.toggleStatisticsLoggingEnabled()}>{t('sessions.statistics')}</Switch>
                }
                {
                    !isFinished(sessionsStore.session) && 
                    <Charts />
                }
            </Card>
        }
        {
            (sessionsStore.activeTab === 'recordings') && permissionsStore.get('playbackRecording') && <RecordingsTable app={app} sessionId={id} />
        }
        {
            (sessionsStore.activeTab === 'threadDumps') && permissionsStore.get('getThreadDump') && <ThreadDumpsTable app={app} sessionId={id} />
        }
        {
            sessionsStore.activeTab === 'logs' &&
            <LogsTable className={css.tableHeight} type={'session'} app={app} id={id} />
        }
        {
            permissionsStore.get('startMirrorView') && wasMirrorOpen &&
            <div className={cx(css.hiddenMirror,{[css.activeContent]:sessionsStore.activeTab === 'mirror'})}>
                <div className={cx(
                    css.mirrorView,
                    {[css.fullScreen]:max}, 
                    {[css.hiddenMirror]:false}
                )}>
                    <Flex className={css.mirrorRow} alignItems='center' spacing={3} >
                        <Icon className={cx(cssTab.wTab, css.minMax)} name={max?'fullscreen_exit':'fullscreen'} onClick={()=>setMax(!max)} />
                        <Switch className={css.switch} value={control} size='large' onClick={()=>setControl(!control)}>{t('sessions.control')}</Switch>
                        {
                            sessionsStore.mirroringMessageKey != null &&
                            {
                                'sessions.mirror.status.waiting': <div className={css.statusInfo}><Icon name='info' size='mini' color='blue' />{t(sessionsStore.mirroringMessageKey)}</div>,
                                'sessions.mirror.status.denied': <div className={css.statusInfo}><Icon name='error' size='mini' color='danger' />{t(sessionsStore.mirroringMessageKey)}</div>
                            }[sessionsStore.mirroringMessageKey]
                        }
                    </Flex>
                    <Card className={css.cardContent}>
                        <div className={css.iframeWrap}>
                            <iframe ref={iframeRef} id="mirrorIframe" width="100%" height="768" scrolling="yes" src={sessionsStore.mirrorIframeSrc}></iframe>
                        </div>
                    </Card>
                </div>
            </div>
        }
        {
            (sessionsStore.activeTab === 'warnings' && sessionsStore.getWarningsNum() > 0) &&
            <Warnings session={sessionsStore.session} />
        }
    </div>

    </>
)
})