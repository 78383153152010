// .core
import React from 'react'
// components
import { ButtonDefault, IButtonDefaultProps } from '../ButtonDefault/ButtonDefault'
// libraries
import cx from 'classnames'
// styles
import css from './ButtonGroup.module.scss'
import { Tooltip } from 'components'

interface IButtons extends IButtonDefaultProps {
  tooltip?:string,
  show?:boolean | null,
}

interface IButtonGroupProps {
  buttons: IButtons[]
  className?: string
}

export const ButtonGroup: React.FC<IButtonGroupProps> = ({
  buttons,
  className,
}: IButtonGroupProps) => (
  <div className={cx(css.wButtonGroup, className)}>
    {buttons.filter((btn)=>(null == btn.show || btn.show)).map((btn, index) => (
      <React.Fragment key={'btn_' + index}>
      {
        btn.tooltip ?
          <Tooltip content={btn.tooltip}>
            <span className={css.wSubButton}>
              <ButtonDefault {...btn} className={cx(btn.className)} />
            </span>
          </Tooltip>
        :
        <ButtonDefault {...btn} className={cx(css.wSubButton, btn.className)} />
      }
      </React.Fragment>
    ))}
  </div>
)
