// .core
import React, { useCallback, useState } from 'react'
// components
import { Avatar, Card, CardContent, Chip, Dialog, Flex, Heading, Icon, Status } from 'components'
// libraries
import cx from 'classnames'
// styles
import css from '../Overview.module.scss'
import cssButton from 'components/basic/Button/variants/ButtonDefault/ButtonDefault.module.scss'
import {ServerInfo} from 'gen/models'
import { CounterSquare } from './CounterSquare'
import { Dropdown } from 'components/basic/Dropdown/Dropdown'
import { useHistory } from 'react-router'
import { useStore } from 'stores'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiServerUrl } from 'utils'

interface IServerCardProps {
  className?: string,
  onClick?(): void,
  server: ServerInfo,
}


export const ServerCard = observer(({
  server,
}: IServerCardProps) => {

  const countRunningStatus = () => {
    let countRunning: number = 0;
    if(server.apps) {
      for (const app of server.apps) {
        for (const item of app.status as any) {
          if(item.status === 'Running') {
            countRunning++
          }
        }
      }
    }
    return countRunning
  }

  const squareColor = () => {
    const running = countRunningStatus();
    return ( running === server.apps?.length) ? 'success'
    : running === 0 && running !== server.apps?.length ? 'danger' 
    : 'warning'
  }
  const getSpvsCluster = () => {
    return countRunningStatus() + ' / ' + server.apps?.length;
  }
  const getSessionsCount = () => {
    return server.instancesConnected + ' / ' + server.instances;
  }
  const {logsStore, overviewStore} = useStore();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [openAppsDialog, setOpenAppsDialog] = useState(false)
  const [openUsersDialog, setOpenUsersDialog] = useState(false)
  const toggleAppsDialog = useCallback(() => {
    setOpenAppsDialog(!openAppsDialog)
  }, [openAppsDialog])
  const toggleUsersDialog = useCallback(() => {
    setOpenUsersDialog(!openUsersDialog)
  }, [openUsersDialog])

  const showLogs = useCallback((id) => {
    logsStore.setServerLogId(id);
    history.push('logs/server')
  }, [history])


  return (
    <Card className={css.serverCard}>
      <CardContent>
        <Flex justifyContent='space-between'>
          <Heading
            variant={'h2'}
            classes={{ heading: css.cardHeading }}
            title={server.cluster ? server.id! : 'Webclient Server'}
          />
          <Dropdown
            position='right'
            buttons={[
              {label: t('overview.verboseMode'), icon:'info', onClick:()=>overviewStore.toggleVerbose(server,'server')},
              {label: t('routes.logs'), icon:'list_alt', onClick:()=>showLogs(server.id!)},
            ]}
            >
              <span className={cx(cssButton.wButtonDefault,cssButton.medium, cssButton.neutral, cssButton.icon)}>
                <Icon name={'more_vert'} />
              </span>
            </Dropdown>
        </Flex>
        <span className={css.neuralSub}>{server.websocketUrl}</span>
        <Flex spacing={2} className={cx(css.header, {[css.chipsLine]:overviewStore.isThereAnyServerVerbose})}>
          {
            server.verboseLogging &&
            <Chip size='small'>
              <Icon name='check_circle_outline' size='mini' /> {t('overview.verboseMode')}
            </Chip>
          }
        </Flex>
        <Flex spacing={3} flexWrap={'wrap'}>
          <CounterSquare title={t('sessions.sp_plural')} count={server.sessionPools?.length} color={'darkerBlue'} />
          <CounterSquare title={t('overview.users')} count={server.users} color={'darkBlue'} tooltip={openUsersDialog?'':t('overview.users.tooltip')} onClick={toggleUsersDialog} />
          <CounterSquare title={t('overview.sessions')} count={getSessionsCount()} color={'blue'} enabled={server!.instances! > 0} tooltip={t('overview.sessions.tooltip')} />
          <CounterSquare title={t('overview.connections')} count={server.connections} color={'lightBlue'} tooltip={t('overview.connections.tooltip')} />
          <CounterSquare title={t('overview.appsRunning')} count={getSpvsCluster()} color={squareColor()} enabled={true} onClick={toggleAppsDialog} />
          <Dialog
            bOpen={openAppsDialog}
            title={t('overview.availableApps')}
            onToggle={toggleAppsDialog}
            footer={false}
          >
            <div className={css.spTable}>
              <table>
                <thead>
                  <tr>
                    <td>{t('routes.apps')}</td>
                    <td>{t('general.status')}</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    server.apps!.map((e) => (
                      <tr key={e.name}>
                        <td>
                          <Flex spacing={2}>
                            <Avatar shape='square' src={ApiServerUrl +  e.path + '/rest/appicon'} />
                            <div>
                              {e.name}<small>{e.path}</small>
                            </div>
                          </Flex>
                        </td>
                        <td><Status label={e.status![0].status!} /></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </Dialog>
          <Dialog
            bOpen={openUsersDialog}
            title={t('overview.users')}
            onToggle={toggleUsersDialog}
            footer={false}
          >
            <div className={css.userListTable}>
              <table>
                <tbody>
                  {server.userList &&
                    server.userList!.map((user: string) => (
                      <tr key={user}>
                        <td>{user}</td>
                      </tr>
                    ))
                  }
                  {!server.userList &&
                    <tr>
                      <td>{t('overview.users.empty')}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Dialog>
        </Flex>
      </CardContent>
    </Card>
  )
})
