import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { ConfigService } from 'services'
import { Config, MetaObject } from 'gen/models'
import { ApiServerUrl, getAllConfigData, ITab, parseObject } from 'utils';
import { showToastOk } from 'components';
import i18n from 'i18n';

class ConfigStore {

  config?: Config;
  origConfig?: Config;
  tabs: ITab[] = [];
  origTabs?: ITab[] = [];
  activeTab:string ='webConfig';
  lastTab:string='';
  wcChanged: boolean = false;
  acChanged: boolean = false;
  allConfigWasEdited: boolean = false;
  filterToggle:boolean = false;
  searchTerm:string=''
  appView:string='grid'

  constructor() {
    makeAutoObservable(this)
  }

  setFilterToggle(bool?:boolean) {
    this.searchTerm = ''
    this.filterToggle = bool ? bool : !this.filterToggle;
  }

  setSearchTerm(term:string) {
    if(this.searchTerm !== term) {
      this.searchTerm = term;
    }
  }

  setAppConfig(configs: { [key: string]: MetaObject; }) {
    this.config!.appConfig = {
      ...this.config!.appConfig,
      ...configs
    }
  }

  setAppView(view:string) {
    if(this.appView !== view) {
      this.appView = view;
    }
  }

  get activeApply() {
    return (this.acChanged || this.wcChanged);
  }

  compareConfig(identify:string) {
    if(this.origConfig && this.config) {
      parseObject(getAllConfigData(toJS(this.origConfig))).isEqual(getAllConfigData(toJS(this.config))) 
      ? 
      this.activeTab !== 'webConfig' ? this.acChanged = false :  this.wcChanged = false
      :
      this.activeTab !== 'webConfig' ? this.acChanged = true :  this.wcChanged = true
    }
    // SP tabs
    if(identify !== 'webConfig') {
      this.tabs = this.tabs.map(tab => {
        if(this.acChanged) {
          this.allConfigWasEdited = 'allID' === identify ? true : this.allConfigWasEdited;
          tab = tab.key === identify ? { ...tab, wasEdited:true } : tab
        } else {
          this.allConfigWasEdited = 'allID' === identify ? false : this.allConfigWasEdited;
          tab = tab.key === identify ? { ...tab, wasEdited:false } : tab
        }
        return tab
      });
    }
  }

  activeSpSwitch(pTab:ITab) {
    this.tabs.map((tab)=>{
      if(tab.key === pTab.key) {
        tab.activeSP = !tab.activeSP
      }
    })

    if(this.hasOnlyOneActive) {
      this.tabs.map((t:ITab)=>t.tabVisible = false);
      this.openTab(this.tabs.filter(t => t.activeSP === true)[0].key);
    } else {
      this.openTab('allID');
    }
    // compare with original
    const origTab = this.origTabs!.filter(t => t.key === pTab.key)[0].activeSP;
    if(origTab === pTab.activeSP && !this.activeApply) {
      this.acChanged = true;
    } else {
      this.acChanged = true;
    }
  }

  clearConfigData = () => {
    this.origConfig = {};
    this.config = {};
    this.acChanged = false;
    this.wcChanged = false;
    this.allConfigWasEdited = false;
    this.tabs = []
    this.origTabs = []
  }

  loadConfig = async (app?:string) => {
    try {
      const data = await ConfigService.getConfig(app?app:'');
      if(data) {
        runInAction(() => {
          this.clearConfigData()
          this.origConfig = data
          this.config = data
          this.acChanged = false
          this.wcChanged = false
          this.allConfigWasEdited = false
          if(app && !this.tabs.length) {
            for(const prop in this.config.appConfig) {
              this.tabs.push({
                key:prop,
                wasEdited:false,
                tabVisible:false,
                activeSP: this.config.appConfig[prop].data? true : false
              })
            }
          }

          if(!this.activeTabExists) {
            this.openTab('webConfig');
          }
          this.origTabs = parseObject(this.tabs!).clone();
        })
      }
    } catch (err) {
      console.log('apiError', err)
    }
  }

  updateConfigStore = (config:any, property?:'webConfig'| 'appConfig') => {
    if(property) {
      this.config![property] = config[property]
    } else {
      this.config = config
    }
  }

  resetConfig = (app?:string) => {
    this.config = parseObject(this.origConfig!).clone();
    this.tabs = JSON.parse(JSON.stringify(this.origTabs))
    this.loadConfig(app);
  }

  setConfig = (app?:string) => {
    this.tabs.map(tab => {
      if(!tab.activeSP) {
        delete this.config?.appConfig![tab.key];
      }
      if(tab.wasEdited) {
        tab.wasEdited = false;
      }
    });
    this.allConfigWasEdited = false;
    this.updateFormStructure(getAllConfigData(this.config),app)
    .then(()=>{
      ConfigService.setConfig(this.config!, app?app:ApiServerUrl)
      .then(()=>{
        setTimeout(async() => await this.loadConfig(app), 50);
        showToastOk(i18n.t('ok.changesAppliedSuccess'));
      })
    })
  }

  updateFormStructure = async (config:Config, app?:string) => {
    const data = await ConfigService.getMeta(app?app:ApiServerUrl, config);
    if(data) {
      runInAction(() => {
        this.config = {...this.config, ...data}
      })
    }
  }

  onRequestUpdate = (app?:string) => {
    this.updateFormStructure(getAllConfigData(this.config), app)
  }

  // TABS SP Maniupulation for cluster

  public openTab(key: string) {
    if(key !== 'webConfig') {
      this.lastTab = key
    }
    if(key !== 'allID' && key !== 'webConfig') {
      const tabIndex = this.tabs.findIndex((tab => tab.key === key));
      if(tabIndex !== -1) {
        this.tabs[tabIndex].tabVisible = true;
      }
    }
    this.activeTab = key;
  }

  isTab(key?:string) {
    if(this.activeTab === 'allID' || this.activeTab === 'webConfig') {
      return false
    }
    return key ? this.activeTab === key : true;
  }

  get hasOnlyOneActive() {
    return this.tabs.filter(tab => tab.activeSP === true).length === 1;
  }

  get activeTabExists() {
    return this.tabs.filter(t=>t.key==this.activeTab).length > 0;
  }

  get activeTabs() {
    return this.tabs.filter(t=>t.activeSP==true);
  }
  get inactiveTabs() {
    return this.tabs.filter(t=>t.activeSP==false);
  }

  get hasAllActive() {
    return this.activeTabs.length === this.tabs.length;
  }

  get hasAllInactive() {
    return this.inactiveTabs.length === this.tabs.length;
  }

  get hideALL() {
    return this.hasOnlyOneActive || this.hasAllInactive;
  }

  get allOrOne() {
    if(this.lastTab && this.tabs.filter(t=>t.key===this.lastTab).length) {
      return this.lastTab
    } else {
      if(this.isTab() || this.hasOnlyOneActive) {
        if(this.hasOnlyOneActive) {
          return this.tabs[0].key
        }
        return this.activeTab
      }
      return 'allID'
    }
  }
}

export default ConfigStore;