// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './SearchFilter.module.scss'
import { Input } from 'components'
import { useTranslation } from 'react-i18next'

export interface ISearchFilterProps {
  value:string,
  onChange:(event:React.ChangeEvent<HTMLInputElement>)=>void
  onClose?:()=>void
  className?:string
}

/**
 * Simple line that divides containers w/ custom margin and vertical or horizontal placement
 */
export const SearchFilter = ({ value='',onChange, onClose, className }: ISearchFilterProps) => {
  const {t} = useTranslation();
  return (
  <div className={cx(css.appsFilter, className)}>
    <Input
      classNameInput={css.input}
      type="text"
      placeholder={t('general.search')}
      value={value}
      onChange={onChange}
      onClose={onClose}
      bAutoFocus
      // onFocus={()=>globalStore.toggleLeftSideMenu(true)}
    />
  </div>
)}
