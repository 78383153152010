import { Config } from 'gen'
// libraries
import { find, findIndex, get, isEqual, omit, omitBy } from 'lodash'
// utils
import { gRegex } from './regexes'

//
// ==================== Data parsing ====================
//
/**
 * a pure function that returns modified version of provided array based on selected parse method
 * @param array a collection of data (both primitive & complex) that will be parsed
 *
 * @example
 * const updatedValues = parseArray<string>(previousValues).add(newValues, 'end')
 */
export const parseArray = <T>(collection: T[]) => {
  const arr: T[] = [...collection]
  const bObjectArray: boolean = typeof arr[0] === 'object'

  return {
    /**
     * Adds element(s) to the array at given place / index
     * @param values elements to add to the array
     * @param at place || index where to add said elements
     * @param bUniqueOnly whether to check for existing duplicates, element is added only if it's unique
     * @returns collection w/ added elements at specified place/index
     */
    add: (values: T[], at: 'start' | 'end' | number = 'end', bUniqueOnly?: boolean): T[] => {
      const duplicates = parseArray([...arr, ...values]).duplicates('get')
      let valuesToAdd = [...values]

      if (bUniqueOnly && bObjectArray) {
        console.warn(
          'parseArray.add - type object && bUniqueOnly \n\t Adding uniques only to object[] is not yet supported ! \n\t Returning default array.'
        )

        return collection
      }

      if (bUniqueOnly && duplicates.length) {
        valuesToAdd = parseArray(values).remove(duplicates.length, undefined, [...duplicates])

        console.warn(
          "parseArray.add - bUniqueOnly \n\t VALUE you're trying to add already exists within the array !"
        )
      }

      if (at === 'start') {
        arr.unshift(...valuesToAdd)
      } else if (at === 'end') {
        arr.push(...valuesToAdd)
      } else {
        arr.splice(at, 0, ...valuesToAdd)
      }

      return [...arr]
    },

    /**
     * Based on passed action, either removes the duplictes from collection, or returns them
     * @param action what to do with the duplicates, either `remove` or `get` them
     * @returns all duplicate items within an array `||` array without any duplicates
     */
    duplicates: (action: 'get' | 'remove'): T[] => {
      // Working w/ object-type array                   // #TODO
      if (bObjectArray) {
        return arr
      }

      // Working w/ primitive-type array
      return action === 'get'
        ? arr.filter((i, ii) => arr.indexOf(i) !== ii)
        : arr.filter((i, ii) => arr.indexOf(i) === ii)
    },

    /**
     * Maps provided array of objects and extracts the values of provided props from each object and returns them as array
     *
     * Does support deep nesting using `_lodash.get()` ! However passing `props[]` need to be redeclared as `(keyof T)[]`
     *
     * @param props names of the properties to filter out from the objects
     *
     * @example
     * arr = [
     *   { name: 'John', age: 23, job: 'artist' },
     *   { name: 'Tom', age: 38, job: 'doctor' }
     * ]
     * props = 'name'
     *
     * @returns `['John', 'Tom']`
     *
     * ====================
     *
     * @example
     *
     * props = ['name', 'age']
     *
     * @returns `[ { name: 'John', age: 23 }, { name: 'Tom', age: 38 } ]`
     */
    extract: (props: (keyof T)[]): T[] | T[keyof T][] => {
      // If the array consists of primitive values & not objects
      if (typeof arr[0] !== 'object') {
        console.warn(
          'parseArray().extract() \n\t provided array has PRIMITIVE values ! \n\t Returning original array'
        )

        return collection
      }

      // Looking for a single property
      if (props.length === 1) {
        return arr.map(i => get(i, props[0]))
      }

      // Looking for more than 1 property
      return [
        ...arr.map(i => {
          const obj: T = {} as T
          ;(props as (keyof T)[]).map(p => (obj[p] = get(i, p)))
          return obj
        }),
      ]
    },

    /**
     * Loops through provided collection of values and tries to find them in the original array
     * @param values collection of values to find
     * @param bIndexOnly whether to return only indexes of searched values or the values themselves
     * @param functionIterator iteration method that gets executed within `_.findIndex` if arr[0] === object
     * @returns either a collection w/ indexes of searched values or values themselves found in the orinal array
     */
    find: (
      values: T[],
      bIndexOnly?: boolean,
      functionIterator?: (i: T) => boolean
    ): T[] | number[] => {
      const res: T[] | number[] = []

      if (bObjectArray) {
        if (!functionIterator) {
          arr.forEach((originalElement, originalElementIndex) =>
            values.forEach(searchedElement => {
              if (isEqual(originalElement, searchedElement)) {
                bIndexOnly
                  ? (res as number[]).push(originalElementIndex)
                  : (res as T[]).push(searchedElement)
              }
            })
          )
          return res
        }

        const foundObject = find(arr, functionIterator)

        if (bIndexOnly) return [findIndex(arr, functionIterator)]

        return foundObject ? [foundObject] : []
      }

      values.forEach(val => {
        if (bIndexOnly) {
          const index = arr.findIndex(i => i === val)
          if (index >= 0) (res as number[]).push(index)
        } else {
          const element = arr.find(i => i === val)
          if (element) (res as T[]).push(element)
        }
      })

      return res
    },

    /**
     * Oposite to `add()`, removes elements from the array at given place / index
     * @param count number of elements to remove
     * @param values elements to remove from the array
     * @param at place || index from where the elements should be removed
     */
    remove: (count: number, at: 'start' | 'end' | number = 'end', values?: T[]): T[] => {
      if (values) {
        ;(parseArray(arr).find(values, true) as number[]).map((valueIndex, index) =>
          arr.splice(valueIndex - index, 1)
        )
        return [...arr]
      }

      if (at === 'start') {
        arr.splice(0, count)
      } else if (at === 'end') {
        arr.splice(arr.length - 1, count)
      } else if (at >= 0) {
        arr.splice(at, count)
      }

      return [...arr]
    },

    /**
     * Searches for provided `property` through collection of entries, and compares it to specified `searchTerm`
     * @param property property to search for within entries of the collection
     * @param searchTerm value of the `property` to search for -- ! ONLY STRINGS ARE SUPPORTED !
     */
    searchBy: (property: keyof T, searchTerm: string): T[] => {
      if (!bObjectArray) {
        console.warn(
          'parseArray.searchBy - array type is NOT an object ! \n\t searchBy method works only with object[], please provided correct array type. \n\t Returning default array.'
        )

        return collection
      }

      return arr.filter(i => {
        const foundValue = get(i, property)

        return (
          typeof foundValue === 'string' &&
          foundValue.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
      })
    },
  }
}

export const parseNumber = (num?: number | string, fallbackValue: number = 0) => {
  /**
   * Checker for cases when a pure string/word (non-number wrapped in quotation marks, e.g: "hello") is passed as a `num` parameter
   */
  const bInvalid = isNaN(Number(num))
  const number = bInvalid ? fallbackValue : Number(num)

  bInvalid &&
    console.error(
      'parseNumber()\n' +
        '\n\t Provided number is a pure string and cannot be converted to a number !\n' +
        '\n\t Please provide either a valid number (int, float) or a number wrapped in quotation marks.\n' +
        '\n\t Using fallback value (0) instead.'
    )

  return {
    /**
     * Retrieves the max value from number array
     */
    getMax: (numbers: number[]) => (numbers.length ? Math.max(...numbers) : undefined),

    /**
     * Retrieves the min value from number array
     */
    getMin: (numbers: number[]) => (numbers.length ? Math.min(...numbers) : undefined),

    isInt: () => (bInvalid ? false : Number.isInteger(number)),

    isNan: (): boolean => bInvalid, // isNaN(number),

    isNegative: (bZeroIncluded: boolean = false): boolean =>
      bZeroIncluded ? number <= 0 : number < 0,

    isPositive: (bZeroIncluded: boolean = false): boolean =>
      bZeroIncluded ? number >= 0 : number > 0,

    isValid: () => !bInvalid,

    /**
     * Whether the number is within specified range
     */
    isWithinRange: (min: number, max: number): boolean =>
      bInvalid ? false : number >= min && number <= max,

    /**
     * Generates a random number (can be restricted)
     * @param min bottom limit
     * @param  max upper limit
     * @return A random number, restricted within bounds of `min` && `max` - if provided
     */
    random: (min: number = 0, max: number = 0) => Math.floor(Math.random() * (max - min + 1) + min),

    /**
     * Rounds the number
     * @param decimalCount how many decimals should be left after rounding
     * @param to either force-rounds the number up or down, regardless of the actual decimal values
     */
    round: (decimalCount: number = 0, to?: 'up' | 'down'): number => {
      // Rounding down - regardless of 1st decimal value (e.g.: passing `12.9` will return `12`)
      if (to === 'down') return Math.floor(number)

      // Rounding up - regardless of 1st decimal value (e.g.: passing `12.1` will return `13`)
      if (to === 'up') return Math.ceil(number)

      // Default rounding - based on 1st decimal, also limits the number of decimals, def.: 0
      return Number(number.toFixed(decimalCount))
    },
    /**
     * Converts the number into an integer - removes any decimals
     */
    toInt: () => Math.trunc(number),

    toNumber: () => number,

    toString: () => String(number),

    /**
     * Converts the number into a percentage value
     * @param bRound whether to round the value
     * @param bAddSpacing whether to add single empty space between the number and the percentage mark
     */
    toPercent: (bRound?: boolean, bAddSpacing?: boolean): string =>
      `${bRound ? parseNumber(number).round() : number}${bAddSpacing ? ' ' : ''}%`,
  }
}

export const parseObject = <T extends object>(object: T) => {
  return {
    // clone: () => new Object(object),
    clone: () => object,

    get: (propPath: string, fallbackValue: any) => get(object, propPath, fallbackValue),

    isEmpty: (propertiesToCheck?: (keyof T)[]): boolean | boolean[] => {
      if (propertiesToCheck) {
        return propertiesToCheck.map(property => !!get(object, property))
      }

      return Object.keys(object).length === 0
    },

    isEqual: (compareObject: object): boolean => isEqual(object, compareObject),

    /**
     * Maps through the source object and removes provided values/props
     * @param deleteIterator list of props to remove from the source object
     * @param functionIterator iteration method that gets executed within `_.omitBy`
     */
    purge: (propPaths: (keyof T)[] = [], omitByIterator?: (i: T) => boolean): T[] | object =>
      omitByIterator ? omitBy<T>(object as any, omitByIterator) : omit<T>(object, propPaths),
  }
}

export const parseString = (string: string) => {
  return {
    findIndexOf: (substring: string, searchStartIndex: number = 0): number | undefined => {
      const substringIndex: number = string.indexOf(substring, searchStartIndex)
      return parseNumber(substringIndex).isPositive(true) ? substringIndex : undefined
    },

    /**
     * Converts provided `string` into a floating number, if possible
     *
     * If number converstion isn't possible, reverts to `fallbackValue`
     *
     * @why If there was no validation, this: `parseString('word').toFloat()` would return `NaN`, this way it returns `0` or custom fallback value
     */
    toFloat: (fallbackValue: number = 0) =>
      Number.parseFloat(parseNumber(string, fallbackValue).toString()),

    /**
     * Converts provided `string` into a integer number, if possible
     *
     * If number converstion isn't possible, reverts to `fallbackValue`
     *
     * @why If there was no validation, this: `parseString('word').toInt()` would return `NaN`, this way it returns `0` or custom fallback value
     */
    toInt: (fallbackValue: number = 0) =>
      Number.parseInt(parseNumber(string, fallbackValue).toString()),

    toLowerCase: () => string.toLowerCase(),

    toUpperCase: () => string.toUpperCase(),

    trim: () => string.trim(),

    truncate: (length: number, addElipsis?: boolean) =>
      string.length > length ? (string.substring(0, length) + addElipsis ? '...' : '') : string,
  }
}

//
// ==================== HTML events-related ====================
//
/**
 * Stops the `propagation` and prevents `default` behaviour of a provided event
 * Executes a callback method afterwards, if provided
 * @param e any type of HTML event
 * @param callback an optional method called after the event has stopped
 * @example
 *  <div onClick={stopEvent(this.updateEntry)} />
 */
export const stopEvent = (callback?: Function) => (e: any) => {
  e.preventDefault()
  e.stopPropagation()
  callback?.()
}

//
// ==================== Color conversion ====================
//
export const RGBToHex = (red: any, green: any, blue: any) => {
  let r = red.toString(16)
  let g = green.toString(16)
  let b = blue.toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r + g + b
}

export const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : { red: 0, blue: 0, green: 0 }
}

/**
 * Creates a contrast version of provided HEX color be reversing it
 * @param hex HEX value of a color
 * @returns the contrast (opossite) version of the provided color
 */
export function reverseColor(hex: string): string {
  if (!gRegex.hex.test(hex)) {
    console.error('reverseColor \n\t provided color was not HEX !')
    return '#fff'
  }

  return (Number(`0x1${hex.slice(1)}`) ^ 0xffffff)
    .toString(16)
    .substr(1)
    .toUpperCase()
}

/**
 * Check if proprety is function
 * @param func Property to check
 */
export const isFunction = (func: any) => {
  return typeof func === 'function'
}

/**
 * Run callback if it is callable
 * @param  callback Function to run if it is a function
 * @param  args Argument which we pass to callback function
 */
export const runCallback = (callback: any, ...args: any[]) => {
  return isFunction(callback) ? callback.apply(callback, args) : undefined
}

export const toPath = (basePath: string, path: string) => {
  if (path) {
    if (basePath.endsWith(path)) {
      return basePath;
    }
    if (path.substr(0, 7) === "http://" || path.substr(0, 8) === "https://") {
      return path;
    }
    if (path.substr(0, 1) !== "/") {
      path = "/" + path;
    }
    if (path.length === 1) {
      path = "/";
    }

    return basePath ? basePath + path : path;
  } else {
    return basePath;
  }
};

export const getStatsDataset = (stats: any, names: string[]) => {
  const result = [];
  if (stats) {
    const keysObj: any = {};
    for (const n of names) {
      if (stats[n] != null) {
        for (const item in stats[n]) {
          if (stats[n].hasOwnProperty(item)) {
            keysObj[item] = null;
          }
        }
      }
    }
    const keys = getKeys(keysObj).sort();
    for (const key of keys) {
      const entry: any = {
        x: new Date(parseInt(key, 10))
      };
      for (const name of names) {
        const dataset1 = stats[name];
        const value =
          dataset1 != null && dataset1[key] != null ? dataset1[key] : 0;
        entry[name] = Math.floor(value);
      }
      result.push(entry);
    }
  }
  return result;
};

export const getKeys = (obj: any) => {
  const keys = [];
  if (obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
      }
    }
  }
  return keys;
};

export const dateFormat = (date:number) => {
  if(!date) {
    return 'N/A'
  }
  const dateOptions: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  };

  const result = new Date(date);
  return result.toLocaleString('en-US', dateOptions);
}

export const extractValues = (config: any, data?: any): any => {
  data = config.data || data;
  if (config != null && config.fields != null) {
    const result = data || {};
    for (const field of config.fields) {
      if (field.value == null) {
        result[field.name] = null;
      } else if (field.type === "Object") {
        result[field.name] = extractValues(field.value, result[field.name]);
      } else if (field.type === "ObjectList") {
        const list = [];
        for (const value of field.value) {
          list.push(extractValues(value));
        }
        result[field.name] = list;
      } else if (field.type === "ObjectMap") {
        const map: any = {};
        for (const mapKey in field.value) {
          if (field.value.hasOwnProperty(mapKey)) {
            map[mapKey] = extractValues(field.value[mapKey]);
          }
        }
        result[field.name] = map;
      } else {
        result[field.name] = field.value;
      }
    }
    return result;
  } else {
    return null;
  }
};

export const getAllConfigData = (config: any) => {
  const data: any = {};
  if(config.hasOwnProperty('webConfig')) {
    data.webConfig = { 'data' : extractValues(config.webConfig) };
  } 
  if(config.hasOwnProperty('appConfig')) {
    data.appConfig = {}
    for (const [key, value] of Object.entries(config.appConfig)) {
      data.appConfig[key] = { 'data' : extractValues(value) };
    }
  }
  return data as Config;
}

export const getOsIcon = (os: string) => {
  if (os != null) {
    if (os === "Windows") {
      return "windows";
    }
    if (os === "Mac" || os === "IPhone") {
      return "apple";
    }
    if (os === "Linux") {
      return "linux";
    }
    if (os === "Android") {
      return "android";
    }
    return "unknown";
  }
}

export const getBrowserIcon =(b: string) => {
  if (b != null) {
    if (b.indexOf("IE") >= 0) {
      return "edge";
    }
    b = b.toLowerCase();
    if (b.indexOf("edge") >= 0) {
      return "edge";
    }
    if (b.indexOf("chrome") >= 0) {
      return "chrome";
    }
    if (b.indexOf("firefox") >= 0) {
      return "firefox";
    }
    if (b.indexOf("safari") >= 0) {
      return "safari";
    }
    if (b.indexOf("opera") >= 0) {
      return "opera";
    }
    return "unknown";
  }
}

export const getServerStatuses = (statuses:any) => {
  let countRunning: number = 0;
  let countError: number = 0;
  let countStopped: number = 0;
  if(statuses) {
    for (const item of statuses) {
      switch(item.status) {
        case 'Running': {
          countRunning++;
            break; 
        } 
        case 'Error': {
          countError++
            break;
        } 
        case 'Stopped': { 
          countStopped++
            break; 
        }
      } 
    }
  }
  return {'Running':countRunning, 'Error':countError, 'Stopped':countStopped}
}

export const convertToSlug = (text:string) => {
    return text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export const jsonToCssVariables = (json:any) => {
  const options = {
    offset:0,
    pretty:true,
  }

  const offset = 0

  let count = 0
  let output = `:root {${options.pretty ? '\n' : ''}`

  for (let key in json) {
    if (count >= offset) {
      let value = json[key]

      if (!isNaN(value) && value !== 0) {
        value +=  'px'
      }

      output += `${options.pretty ? '\t' : ''}--${key}: ${value};${options.pretty ? '\n' : ''}`
    }

    count++
  }

  output += '}'

  return output
}

export const humanFileSize = (size: number | undefined): string => {
  if (!size) return '0 B';

  const i = Math.floor(Math.log(size) / Math.log(1024));
  const fractioned = size / Math.pow(1024, i);
  return fractioned.toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};