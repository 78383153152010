import React from 'react';
import css from '../LeftSideMenu.module.scss'
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';

const Footer = observer((props: { center?: boolean }) => {
  const { globalStore } = useStore()
  const { t } = useTranslation()
  return(
    <>
    {
      globalStore.customization?.footerHTML! ? 
      <div className={cx(css.footer, props.center && css.center)} dangerouslySetInnerHTML={{__html:globalStore.customization?.footerHTML!}}></div>
      :
      <>
        {t('general.developed')} <a href="https://www.veryant.com">Veryant</a><br />
        <div className={cx(css.footer, props.center && css.center)} >
          {(new Date()).getFullYear().toString()} Veryant. {t('general.allRightReserved')}
          <br/>
          isCOBOL WebClient 2023R1
        </div>
      </>
    }
  </>
)});

export default Footer;
