// .core
import React from 'react';
import ReactDOM from 'react-dom';
// components
import { Button, Icon } from 'components';
// libraries
import cx from 'classnames';
// styles
import css from './DialogDefault.module.scss';


export interface IDialogDefaultProps {
  
  bOpen?: boolean
  className?: string
  classNameContent?: string
  title?: string

  onToggle?(bOpen?: boolean): void

  onConfirm?(): void

  labelOk?: string
  labelCancel?: string
  footer?: boolean
}

export class DialogDefault extends React.PureComponent<IDialogDefaultProps> {
  
  static defaultProps: IDialogDefaultProps = {
    footer: true,
    title: 'DIALOG',
  };

  // =============== I N I T ===============
  //   componentDidMount() {}
  //   componentWillUnmount() {}

  dialogBg = React.createRef<HTMLDivElement>();  

  // =============== E V E N T S ===============
  onToggle = (event:any) => {
    const { bOpen, onToggle } = this.props;
    event.preventDefault();
    onToggle?.(!bOpen);
  };

  onToggleBg = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event && event.target && this.dialogBg.current && this.dialogBg.current == event.target) {
      this.onToggle(event);
    }
  };

  onConfirm = () => {
    const { onConfirm } = this.props;

    onConfirm?.();
  };

  render() {
    const { bOpen, children, className, classNameContent, title, onToggle, labelOk, labelCancel, footer } = this.props;
    if (!bOpen) return null;

    return ReactDOM.createPortal(
      <>
      <div ref={this.dialogBg} className={css.wBackground} onClick={this.onToggleBg}>
        <div className={cx(css.wDialog, className)}>
          {/* HEADER */}
          <Icon className={css.wClose} name="close" onClick={onToggle}/>
          <div className={cx(css.wHeader)}>
            <span>{title}</span>
          </div>

          {/* CONTENT */}
          <div className={cx(css.wContent, classNameContent)}>{children}</div>

          {/* FOOTER */}
          {footer ? (
            <div className={cx(css.wFooter)}>
              <Button color="neutral" label={labelCancel || 'Cancel'} size="small" onClick={this.onToggle} />
              <Button color="danger" label={labelOk || 'Confirm'} size="small" onClick={this.onConfirm} />
            </div>
          ) : null}
        </div>
      </div>
      </>,
      document.body,
    );
  }
}
