import React from "react";
import { observer } from "mobx-react";
import cx from 'classnames';
import { useStore } from "stores";
import css from '../Logs.module.scss'

export const HighlightedText = observer(({text}:{text: string|undefined}) => {
    const { logsStore } = useStore();
    // Split on highlight term and include term into parts, ignore case
    const parts = logsStore.searchTerm ? text?.split(new RegExp(`(${logsStore.searchTerm})`, 'gi')) : [text || ""];
    return <span> { parts?.map((part, i) => 
        <span key={i} className={cx({[css.highlight]:part.toLowerCase() === logsStore.searchTermLowerCase})}>
            { part }
        </span>)
    } </span>;
})