// core
import React, { createElement, ReactElement } from 'react'

// libraries
import classnames from 'classnames'

// style
import css from './Heading.module.scss'
import { IComponentProps } from 'components'
import { IButtonDefaultProps } from 'components/basic/Button/variants/ButtonDefault/ButtonDefault'

export interface IHeadingInterface extends IComponentProps {
  /**
   * title to display
   */
  title: string
  /**
   * Buttons to render above the card
   */
  button?: (ReactElement<IButtonDefaultProps> | null)
  /**
   * variant of heading
   */
  variant?: 'h1' | 'h2' | 'h3'
}

export const Heading = ({
  button,
  title,
  variant = 'h1',
  className,
  classes = {},
}: IHeadingInterface) => {
  return (
    <div className={classnames(css.root, className, classes.root)}>
      {createElement(variant, {
        children: title,
        className: classnames(css.heading, css[variant], classes.heading),
      })}
      {button ? (
        <div className={classnames(css.buttonsWrap, classes.buttonsWrap)}>{button}</div>
      ) : null}
    </div>
  )
}
