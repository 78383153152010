// core
import React, { useEffect } from 'react'

// libraries
import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import i18n from 'i18n'

// components
import { Menu } from './Partials/Menu'
// styles
import css from './LeftSideMenu.module.scss'

// stores
import { useStore } from 'stores'

import { observer } from 'mobx-react'
import { Logo } from '../Header/partials/Logo'
import Footer from './Partials/Footer'
import { useLocation } from 'react-router-dom'
import { OverviewMenu } from './Partials/OverviewMenu'
import { useTranslation } from 'react-i18next'


// export interface ILeftSideMenuProps {}

export const LeftSideMenu = observer(function LeftSideMenu() {
  const { globalStore } = useStore()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  useEffect(()=>{
    if(isTabletOrMobile) {
      globalStore.toggleLeftSideMenu(false)
    } else {
      globalStore.toggleLeftSideMenu(true)
    }
  },[isTabletOrMobile])
  const {pathname} = useLocation()
  const isOverview = pathname.includes('overview') || pathname === '/'
  return (
    <>
      <aside className={classnames(css.root, { [css.collapsed]: !globalStore.leftSideMenuOpen })}>
        <div className={css.logo}>
          <Logo />
        </div>
        {
          isOverview ? <OverviewMenu /> : <Menu />
        }
        <div className={css.developed}>
          <Footer />
        </div>
      </aside>
    </>
  )
})
