// .core
import React from 'react'
import cx from 'classnames'
// styles
import css from './ButtonIcon.module.scss'
import { Badge, Button, CoinColor, Icon } from 'components'

interface IButtonIconProps {
  children: React.ReactNode
  active: boolean
  className?: string,
  icon: string,
  onClick: () => void,
  badge?: string | number | undefined
  badgeColor?:CoinColor|undefined
}

export const ButtonIcon = ({ className, onClick, active, icon, children, badge, badgeColor}: IButtonIconProps) => {
  return (
    <Button.UI className={cx(css.wButtonIcon, {[css.active]: active}, className)}>
      {
        badge ?
          <Badge
            bOnBadge
            className={cx(css.changesTracking)}
            vertical='top'
            side='right'
            color={badgeColor?badgeColor:'danger'}
            size='medium'
            content={badge}
            onClick={onClick}
          >
            <Icon className={css.btnIcon} name={icon} /> 
          </Badge>
        :
        <Icon className={css.btnIcon} name={icon} /> 
      }
      <div className={css.btntxt} onClick={onClick}>{children}</div>      
    </Button.UI>
  )
}
