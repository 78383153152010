// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerboseModeRequest
 */
export interface VerboseModeRequest {
    /**
     * 
     * @type {string}
     * @memberof VerboseModeRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VerboseModeRequest
     */
    type?: VerboseModeRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VerboseModeRequest
     */
    enable?: boolean;
}

export function VerboseModeRequestFromJSON(json: any): VerboseModeRequest {
    return VerboseModeRequestFromJSONTyped(json, false);
}

export function VerboseModeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerboseModeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'enable': !exists(json, 'enable') ? undefined : json['enable'],
    };
}

export function VerboseModeRequestToJSON(value?: VerboseModeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'enable': value.enable,
    };
}

/**
* @export
* @enum {string}
*/
export enum VerboseModeRequestTypeEnum {
    SESSIONPOOL = 'SESSION_POOL',
    SERVER = 'SERVER'
}


