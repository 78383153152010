// core
import React from 'react'
// libraries
import { useLocation } from 'react-router-dom'
import cx from 'classnames'
// import i18n from 'i18n'

// components
import { Icon, Input, Button } from 'components'

// partials
import { MenuItem } from './MenuItem'

// styles
import css from '../LeftSideMenu.module.scss'

// data (will be fetched from somewhere later)
import { observer } from 'mobx-react'
import { useStore} from 'stores'
import { Flex } from 'components/containers'
import { useTranslation } from 'react-i18next'

export const OverviewMenu = observer(function AppsMenu() {
  const { overviewStore, globalStore } = useStore()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    overviewStore.setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className={css.menuFlex}>
        <ul className={cx(css.menuItems, css.scrollable)}>
          {
            overviewStore.isCluster ?
            <>
              <MenuItem activePath={pathname} path={'overview/server-config'} icon={'settings_applications'}>{t('overview.serConf')}</MenuItem>
              <div className={css.divider}></div>
              <Flex className={css.toolRow} spacing={2} bColumn={!globalStore.leftSideMenuOpen}>
                <div className={css.appsFilter}>
                  {!globalStore.toggleSearch && <Button color='neutralTrans' icon={'search'} onClick={()=>globalStore.setToggleSearch()} /> } 
                  {globalStore.toggleSearch &&
                  <Input
                  classNameInput={css.input}
                  type="text"
                  placeholder={t('overview.searchServerSp')}
                  value={overviewStore.searchTerm}
                  onChange={handleChange}
                  onClose={()=>globalStore.setToggleSearch()}
                  icon={{name:'x', position:'labelRight'}}
                  />}
                </div>
              </Flex>
              <MenuItem activePath={pathname} path={'overview/servers'} icon={'dns'}>{t('overview.server_plural')}</MenuItem>
              <MenuItem  activePath={pathname} path={'overview/sessionpools'} icon={'apps'}>{t('sessions.sp_plural')}</MenuItem>
            </>
            :
            <>
              <MenuItem  activePath={pathname} path={'overview'} icon={'apps'}>{t('overview.server_stats')}</MenuItem>
              <MenuItem activePath={pathname} path={'overview/server-config'} icon={'settings_applications'}>{t('overview.serConf')}</MenuItem>
            </>
          }
        </ul>
      </div>
      <div className={css.sideMenuArrow}>
        <Icon
          size="small"
          color="white"
          className={css.arrowIcon}
          name="keyboard_arrow_left"
          onClick={globalStore.toggleLeftSideMenu}
        />
      </div>
    </>
  )
})
