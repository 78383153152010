// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwingSession,
    SwingSessionFromJSON,
    SwingSessionFromJSONTyped,
    SwingSessionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sessions
 */
export interface Sessions {
    /**
     * 
     * @type {Array<SwingSession>}
     * @memberof Sessions
     */
    sessions?: Array<SwingSession>;
    /**
     * 
     * @type {Array<SwingSession>}
     * @memberof Sessions
     */
    closedSessions?: Array<SwingSession>;
}

export function SessionsFromJSON(json: any): Sessions {
    return SessionsFromJSONTyped(json, false);
}

export function SessionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sessions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessions': !exists(json, 'sessions') ? undefined : ((json['sessions'] as Array<any>).map(SwingSessionFromJSON)),
        'closedSessions': !exists(json, 'closedSessions') ? undefined : ((json['closedSessions'] as Array<any>).map(SwingSessionFromJSON)),
    };
}

export function SessionsToJSON(value?: Sessions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessions': value.sessions === undefined ? undefined : ((value.sessions as Array<any>).map(SwingSessionToJSON)),
        'closedSessions': value.closedSessions === undefined ? undefined : ((value.closedSessions as Array<any>).map(SwingSessionToJSON)),
    };
}


