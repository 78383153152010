// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaObject,
    MetaObjectFromJSON,
    MetaObjectFromJSONTyped,
    MetaObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {MetaObject}
     * @memberof Config
     */
    webConfig?: MetaObject;
    /**
     * Map of <session pool id> - <MetaObject>
     * @type {{ [key: string]: MetaObject; }}
     * @memberof Config
     */
    appConfig?: { [key: string]: MetaObject; };
}

export function ConfigFromJSON(json: any): Config {
    return ConfigFromJSONTyped(json, false);
}

export function ConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): Config {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'webConfig': !exists(json, 'webConfig') ? undefined : MetaObjectFromJSON(json['webConfig']),
        'appConfig': !exists(json, 'appConfig') ? undefined : (mapValues(json['appConfig'], MetaObjectFromJSON)),
    };
}

export function ConfigToJSON(value?: Config | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'webConfig': MetaObjectToJSON(value.webConfig),
        'appConfig': value.appConfig === undefined ? undefined : (mapValues(value.appConfig, MetaObjectToJSON)),
    };
}


