import { makeAutoObservable, runInAction } from 'mobx'
import { OverviewService, SessionsService } from 'services'
import {ServerInfo} from 'gen/models/ServerInfo'
import {SessionPoolInfo} from 'gen/models/SessionPoolInfo'
import { computedFn } from 'mobx-utils';
import { showToastOk } from 'components';
import i18n from 'i18n';

class OverviewStore {

  servers?: Array<ServerInfo> = [];
  sessionPools?: Array<SessionPoolInfo> = [];
  searchTerm:string='';
  spView:string='grid';
  orderBy:string='sp';

  constructor() {
    makeAutoObservable(this)
  }

  setSpView(view:string) {
    if(this.spView !== view) {
      this.spView = view;
    }
  }

  setOrderBy(by:string) {
    if(this.orderBy !== by) {
      this.orderBy = by;
    }
  }

  setSearchTerm(term:string) {
    if(this.searchTerm !== term) {
      this.searchTerm = term;
    }
  }

  loadOverview = async () => {
    try {
      const data = await OverviewService.getOverview();
      if(data) {
        runInAction(() => {
          this.servers = data.servers
          this.sessionPools = data.sessionPools
        })
      }
    } catch (err) {
      console.log('apiError', err)
    }
  }

  toggleVerbose(sp: SessionPoolInfo | ServerInfo, type:"sp" | "server") {
    if (typeof sp.verboseLogging === 'boolean') {
      OverviewService.setVerboseMode(sp.id!, !sp.verboseLogging, type)
      .then(()=>{
        setTimeout(()=>this.loadOverview(),2200)
      });
    }
  }

  toggleDrain(sp: SessionPoolInfo) {
    if(sp.drainMode) {
      return OverviewService.requestSessionPoolResume(sp.id!)
      .then(()=>{
        setTimeout(()=>this.loadOverview(),2200)
      });
    }
    return OverviewService.requestSessionPoolDrainMode(sp.id!)
    .then(()=>{
      setTimeout(()=>this.loadOverview(),2200)
    });
  }

  shutdown(sp: SessionPoolInfo) {
    SessionsService.getSessions('').then((result=>{
        let spSessions = result?.sessions?.filter(s=>s.sessionPoolId===sp.id)
        spSessions?.forEach(s=>{
          SessionsService.killSession(s.applicationPath!,s.id!);
        })
        showToastOk(i18n.t('ok.reqShutdownSessinos',{spLenght:spSessions?.length}))
    }))
    .catch((e: any) => {
      console.error(e);
    });
  }

  stop(id:string) {
    return OverviewService
    .requestSessionPoolStop(id)
    .then(()=>{
      setTimeout(()=>this.loadOverview(),2200)
    })
    .catch((e: any) => {
      console.error(e);
    });
  }

  drainAllSessionPools() {
    this.allSessionPoolIds?.forEach((id) => {
      OverviewService.requestSessionPoolDrainMode(id!, false);
    });
    showToastOk(i18n.t("ok.reqDrainSessinos",{spIdsLenght:this.allSessionPoolIds?.length}))
  }

  resumeAllSessionPools() {
    this.allSessionPoolIds?.forEach((id) => {
      OverviewService.requestSessionPoolResume(id!, false);
    });
    showToastOk(i18n.t('ok.reqResumeSessinos',{allspIds:this.allSessionPoolIds?.length}))
  }

  stopAllSessionPools() {
    this.allSessionPoolIds?.forEach((id) => {
      OverviewService.requestSessionPoolStop(id!, false);
    });
    showToastOk(i18n.t('ok.reqStopSessinos',{spSessions:this.allSessionPoolIds?.length}))
  }

  shutdownAllSessions() {
    SessionsService.getSessions('').then((result=> {
      let spSessions = result?.sessions;
      spSessions?.forEach(s=>{
        SessionsService.killSession(s.applicationPath!,s.id!);
      })
      showToastOk(i18n.t('ok.reqShutdownSessinos',{spLenght:spSessions?.length}))
    }))
  }

  forceKillAllSessions() {
    SessionsService.getSessions('').then((result=> {
      let spSessions = result?.sessions;
      spSessions?.forEach(s=>{
        SessionsService.forceKillSession(s.applicationPath!,s.id!);
      })
      showToastOk(i18n.t('ok.reqForceKillSessinos', {spSessions:spSessions?.length}))
    }))
  }
  
  // GETTERS & COMPUTED
  get allSessionPoolIds() {
    return this.sessionPools?.map(sp => sp.id);
  }
  
  get isThereAnyServerVerbose() {
    return this.servers?.filter((s)=>s.verboseLogging === true).length! > 0
  }
  get isThereAnySPVerbose() {
    return this.sessionPools?.filter((s)=>s.verboseLogging === true).length! > 0
  }

  get isCluster() {
    return this.servers && this.servers[0] ? this.servers[0].cluster: false
  }

  get filteredSPs() {
    return !this.searchTerm ?  
    this.sessionPools
    : this.sessionPools?.filter(item => item.id?.toLowerCase().includes(this.searchTerm.toLocaleLowerCase()));
  }
  get filteredServers() {
    return !this.searchTerm? 
    this.servers
    : this.servers?.filter(item => item.id?.toLowerCase().includes(this.searchTerm.toLocaleLowerCase()));
  }
  get hasRunningSessions() {
    let result:number=0;
    this.sessionPools?.forEach(sp=> {
      for (const [key, value] of Object.entries(sp.apps!)) {
        result= result + (value as number)
      }
    });
    return result>0
  };

  hasRunningInstances = computedFn(function hasRunningInstances(this: OverviewStore,apps:{ [key: string]: number; }){
    let result: number = 0;
    if(apps) {
      for (const [key, value] of Object.entries(apps)) {
          result = result + (value as number)
      }
    }
    return result > 0
  });


  appOnSP = computedFn(function appOnSP(this: OverviewStore,path?:string):number{
    let sumSP = 0
    this.sessionPools?.forEach(sp => {
      Object.entries(sp.apps!).forEach(app=>{
        if(app[0] === path)
        sumSP++
      })
    });
    return sumSP
  });
}

export default OverviewStore;