import { useEffect } from "react";
import { observer } from "mobx-react";
import { Button, IColumns, TableVirtualized, Tooltip } from "components";
import { useStore } from "stores";
import { RecordingInfo } from "gen";
import { dateFormat, humanFileSize, toPath, useRootOverflow } from "utils";
import css from '../Sessions.module.scss'
import cx from 'classnames';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface IRecordingsTableProps {
    app: string;
    sessionId?: string;
}

export const RecordingsTable = observer(({app, sessionId}: IRecordingsTableProps) => {
    const { recordingsAndThreadDumpsStore, sessionsStore, globalStore } = useStore();
    const {t} = useTranslation();
    const history = useHistory();
    
    const downloadRecording = (item: RecordingInfo) => {
        let url = toPath(globalStore.serverUrl!, app) + "/rest/recording/" + item.token + "/" + encodeURIComponent(item.sessionPoolId!) 
            + "/" + true + "/" + encodeURIComponent(item.fileName!);
        window.open(url, "_blank");
    };
    const viewSession = (id: string) => {
        history.push('/session/' + app +'/'+ id)
        sessionsStore.setActiveTab('view');
    }

    let defaultColumns: IColumns<RecordingInfo>[] = [
        {
            dataKey: 'fileName',
            label: t('recordings.fileName'),
            minWidth: 350,
            align: 'start',
            bSort:true,
            className: cx(css.defaultCell, css.gridAppCell),
            component: (fileName, rowItem) => 
                <div className={css.appCell}>
                    <Tooltip content={fileName}>
                        <div className={css.appCellLinks}>
                            <span onClick={() => sessionsStore.showPlayback(app, rowItem)}>
                                {fileName}
                            </span>
                        </div>
                    </Tooltip>
                </div>
        },
        {
            dataKey: 'instanceId',
            label: t('recordings.instanceId'),
            minWidth: 350,
            align: 'start',
            bSort:true,
            className: cx(css.defaultCell, css.gridAppCell),
            component: (instanceId) => 
                <div className={css.appCell}>
                    {
                        sessionsStore.canShowSessionDetail(instanceId as string) ?
                        <div className={css.appCellLinks}>
                            <Tooltip content={instanceId}>
                                <span onClick={() => viewSession(instanceId as string)}>
                                    {instanceId}
                                </span>
                            </Tooltip>
                        </div>
                        :
                        <Tooltip content={instanceId}>
                            <span>
                                {instanceId}
                            </span>
                        </Tooltip>
                    }
                </div>
        },
        {
            dataKey: 'startDate',
            label: t('recordings.startDate'),
            minWidth: 100,
            align: 'start',
            bSort:true,
            component: (startedAt) => <div className={css.Cell}>{dateFormat(Number(startedAt))}</div>
        },
        {
            dataKey: 'size',
            label: t('recordings.size'),
            minWidth: 100,
            align: 'start',
            bSort:true,
            component: (size) => <div className={css.Cell}>{humanFileSize(Number(size))}</div>
        },
        {
            dataKey: 'token',
            label: '',
            minWidth: 100,
            width: 100,
            align: 'end',
            component: (_token, rowData) => <Button icon={'cloud_download'} onClick={() => downloadRecording(rowData)} />
        },
    ];

    if (sessionId) {
        defaultColumns = defaultColumns.filter(col => col.dataKey !== 'instanceId');
    }

    useRootOverflow();

    useEffect(() => {
        recordingsAndThreadDumpsStore.initData(app, sessionId);
        return () => recordingsAndThreadDumpsStore.clearInterval();
    },[app, sessionId]);

    const filteredList = !sessionsStore.searchTerm
        ? recordingsAndThreadDumpsStore.recordings!
        : (recordingsAndThreadDumpsStore.recordings! as RecordingInfo[])?.filter(item =>
            item.fileName?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase())||
            item.instanceId?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase())
    );

    return (
        <div>
            {
                <div style={{overflowX: 'auto'}}>
                    <TableVirtualized
                        collection={filteredList}
                        minTableWidth={1055}
                        rowHeight={60}
                        rowClassName={css.ac}
                        threshold={500}
                        columns={defaultColumns}
                        className={cx(css.tableHeight, css.vTable)}
                        sortBy='startDate'
                        sortDirection='DESC'
                        />
                </div>
            }
            {
                (filteredList && filteredList.length >= 100) &&
                <div className={css.bottomNote}>{t('recordings.maxItems')}</div>
            }
        </div>
    )
})
