// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationInfo,
    ApplicationInfoFromJSON,
    ApplicationInfoFromJSONTyped,
    ApplicationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServerInfo
 */
export interface ServerInfo {
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    cluster?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    verboseLogging?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    websocketUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    connected?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerInfo
     */
    sessionPools?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ServerInfo
     */
    instances?: number;
    /**
     * 
     * @type {number}
     * @memberof ServerInfo
     */
    users?: number;
    /**
     * 
     * @type {number}
     * @memberof ServerInfo
     */
    connections?: number;
    /**
     * 
     * @type {number}
     * @memberof ServerInfo
     */
    instancesConnected?: number;
    /**
     * 
     * @type {Array<ApplicationInfo>}
     * @memberof ServerInfo
     */
    apps?: Array<ApplicationInfo>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerInfo
     */
    userList?: Array<string>;
}

export function ServerInfoFromJSON(json: any): ServerInfo {
    return ServerInfoFromJSONTyped(json, false);
}

export function ServerInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cluster': !exists(json, 'cluster') ? undefined : json['cluster'],
        'verboseLogging': !exists(json, 'verboseLogging') ? undefined : json['verboseLogging'],
        'websocketUrl': !exists(json, 'websocketUrl') ? undefined : json['websocketUrl'],
        'connected': !exists(json, 'connected') ? undefined : json['connected'],
        'sessionPools': !exists(json, 'sessionPools') ? undefined : json['sessionPools'],
        'instances': !exists(json, 'instances') ? undefined : json['instances'],
        'users': !exists(json, 'users') ? undefined : json['users'],
        'connections': !exists(json, 'connections') ? undefined : json['connections'],
        'instancesConnected': !exists(json, 'instancesConnected') ? undefined : json['instancesConnected'],
        'apps': !exists(json, 'apps') ? undefined : ((json['apps'] as Array<any>).map(ApplicationInfoFromJSON)),
        'userList': !exists(json, 'userList') ? undefined : json['userList'],
    };
}

export function ServerInfoToJSON(value?: ServerInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cluster': value.cluster,
        'verboseLogging': value.verboseLogging,
        'websocketUrl': value.websocketUrl,
        'connected': value.connected,
        'sessionPools': value.sessionPools,
        'instances': value.instances,
        'users': value.users,
        'connections': value.connections,
        'instancesConnected': value.instancesConnected,
        'apps': value.apps === undefined ? undefined : ((value.apps as Array<any>).map(ApplicationInfoToJSON)),
        'userList': value.userList,
    };
}


