import {ILogType} from './index';

// #TODO: find a way to generate this type based on files within `assets/icons/svg`
export type TIcon =
  | 'x'
  | 'x-circle'
  | 'info'
  | 'filter'
  | 'chevron-down'
  | 'chevron-up'
  | 'menu'
  | 'lock'
  | 'unlock'
  | 'apps'
  | 'list'

/**
 * Clock-wise positions
 *
 * @example
 *                          top
 *  top-left        ---------------------   top-right
 *                  |                   |
 *  left            |                   |   right
 *                  |                   |
 *  bottom-left     ---------------------   bottom-right
 *                          bottom
 */
export type TPos =
  | 'top'
  | 'topRight'
  | 'right'
  | 'bottomRight'
  | 'bottom'
  | 'bottomLeft'
  | 'left'
  | 'topLeft'

  export const LogTypes: ILogType[] = [
    {
      label: "Audit",
      url: "audit",
      delimiter: "|",
      lineDelimiter: "\n",
      downloadEnabled: true
    },
    {
      label: "Server",
      url: "server",
      delimiter: null,
      lineDelimiter: /\n(?=\d{4})/g,
      downloadEnabled: true
    },
    {
      label: "Admin",
      url: "admin",
      delimiter: null,
      lineDelimiter: /\n(?=\d{4})/g,
      downloadEnabled: true
    },
    {
      label: "Session Pool",
      url: "sessionpool",
      delimiter: null,
      lineDelimiter: /\n(?=\d{4})/g,
      downloadEnabled: true
    },
    {
      label: "Session",
      url: "session",
      delimiter: null,
      lineDelimiter: /\n(?=\d{4})/g,
      downloadEnabled: false,
      hide:true
    }
  ];