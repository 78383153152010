// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogResponse
 */
export interface LogResponse {
    /**
     * 
     * @type {number}
     * @memberof LogResponse
     */
    startOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof LogResponse
     */
    endOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof LogResponse
     */
    log?: string;
}

export function LogResponseFromJSON(json: any): LogResponse {
    return LogResponseFromJSONTyped(json, false);
}

export function LogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startOffset': !exists(json, 'startOffset') ? undefined : json['startOffset'],
        'endOffset': !exists(json, 'endOffset') ? undefined : json['endOffset'],
        'log': !exists(json, 'log') ? undefined : json['log'],
    };
}

export function LogResponseToJSON(value?: LogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startOffset': value.startOffset,
        'endOffset': value.endOffset,
        'log': value.log,
    };
}


