// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshTokenResult
 */
export interface RefreshTokenResult {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResult
     */
    accessToken?: string;
}

export function RefreshTokenResultFromJSON(json: any): RefreshTokenResult {
    return RefreshTokenResultFromJSONTyped(json, false);
}

export function RefreshTokenResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
    };
}

export function RefreshTokenResultToJSON(value?: RefreshTokenResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}


