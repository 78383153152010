import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import cx from 'classnames';
import { IColumns, Status, TableVirtualized, List, Heading, Tooltip } from "components";
import { useStore } from "stores";
import { ILogsEntry, useRootOverflow } from "utils";
import css from '../Logs.module.scss'
import cssTable from '../../../components/complex/Table/Table.module.scss'
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HighlightedText } from "./HighlightedText";

const TooltipElipsesItem = ({str, bNoHightlight=false}:{str?:string, bNoHightlight?: boolean}) => (
    <Tooltip content={str}>
        <span className={cx(cssTable.wCell, css.elips)}>
            {bNoHightlight? str : <HighlightedText text={str} />}
        </span>
    </Tooltip>
)
interface ILogsTableProps {
    type: 'audit' | 'server' | 'admin' | 'session';
    app: string;
    id?:string;
    className?:string;
}

export const LogsTable = observer(({type, app, id, className}:ILogsTableProps) => {
    const { logsStore } = useStore();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [columns, setColumns] = useState<IColumns<ILogsEntry>[]>([])
    const auditColumns: IColumns<ILogsEntry>[] = [
        {
            label:t('logs.timestamp'),
            dataKey:'timestamp',
            minWidth: 202,
            align: 'start',
            component: (timestamp) => <div className={cx(cssTable.wCell, css.statusAlign)}><HighlightedText text={timestamp as string} /></div>
        },
        {
            label:t('logs.subject'),
            dataKey:'subject',
            minWidth: 100,
            align: 'start',
            bSort: true
        },
        {
            label:t('logs.value'),
            dataKey:'value',
            minWidth: 100,
            align: 'start',
            bSort: true
        },
        {
            label:t('logs.user'),
            dataKey:'user',
            minWidth: 100,
            align: 'start',
            bSort: true,
            component: (user) => <TooltipElipsesItem str={user} />
        },
        {
            label:t('logs.secPath'),
            dataKey:'secured_path',
            minWidth: 150,
            align: 'start',
            bSort: true,
        },
        {
            label:t('logs.sourceIP'),
            dataKey:'ip',
            minWidth: 100,
            align: 'start',
        },
        {
            label:t('logs.detail'),
            dataKey:'detail',
            minWidth: 150,
            align: 'start',
            bSort: true,
            component: (detail) => <TooltipElipsesItem str={detail} />
        }
    ];

    useRootOverflow();

    useEffect(() => {
        if(type === 'session' && !pathname.includes('logs')) {
            logsStore.setType(type)
            if(id) {
                logsStore.loadLogsOverview(app).then(()=> {
                    logsStore.setInstanceId(id)
                    logsStore.loadSessionLogs(app,id)
                });
            }
        } else {
            logsStore.setInstanceId('')
        }
        setColumns([...auditColumns]);
        return () => logsStore.clearInterval();
    },[type, id, app]);

    const filter = () => {
        let result;
        if(logsStore.searchTerm.length <= 1) {
            return logsStore.logs
        }

        if(type === 'audit') {
            result = logsStore.logs?.filter(item =>
                item.timestamp?.toLowerCase().includes(logsStore.searchTermLowerCase) ||
                item.user?.toLowerCase().includes(logsStore.searchTermLowerCase) ||
                item.detail?.toLowerCase().includes(logsStore.searchTermLowerCase) ||
                item.value?.toLowerCase().includes(logsStore.searchTermLowerCase) ||
                item.subject?.toLowerCase().includes(logsStore.searchTermLowerCase)
            )
             
        } else {
            result = logsStore.logs?.filter(item =>
                item.msgs?.toLowerCase().includes(logsStore.searchTermLowerCase)
            )
        }

        return result;
    }
    const results = filter();

    return (
        <>
            <div style={{ overflowX: "auto" }}>
                {
                    type === 'audit'? 
                    <TableVirtualized
                        // onSort={sortTable}
                        collection={results}
                        minTableWidth={1700}
                        // bLoading={logsStore.loading}
                        rowHeight={60}
                        columns={columns} 
                        bscrollToBottom
                        rowClassName={css.logsRow}
                        className={cx(className, css.vTable)}
                    />
                    :
                    <>
                        {
                            (results && results.length > 0) &&
                            <Heading className={css.listHeading} variant='h3' title={t('logs.msgs')} />
                        }
                        <List
                            bstaticHeight={!logsStore.compact}
                            minListWidth={3000}
                            rowHeight={60}
                            bscrollToBottom
                            bVirtualized
                            collection={results}
                            rowClass={css.warningLine} 
                            item={(item)=><div className={css.logItem} ><HighlightedText text={item?.msgs} /></div>} 
                            className={className}
                            noData={{className:css.cardNoData}}
                            searched={logsStore.searchTermLowerCase}
                        />
                    </>
                }
            </div>
        </>
    )
})