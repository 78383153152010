import { showToastOk } from 'components';
import { basicApi, manageSessionsApi, getAuthenticationHeaders, handleRestError } from 'utils';
import {AccessService} from './AccessHandlerService';
import i18n from 'i18n';

class SessionsRestService {

  private static _instance:SessionsRestService = new SessionsRestService();
  
  constructor() {
    if(SessionsRestService._instance){
      throw new Error("Error: Instantiation failed: Use SessionsRestService.getInstance() instead of new.");
    }
    SessionsRestService._instance = this;
  }
  
  static getInstance():SessionsRestService
  {
    return SessionsRestService._instance;
  }

  async getAccessToken() {
    return await AccessService.getAccessToken();
  }

  async getSessions(path: string) {
    try {
        const appApi = basicApi(getAuthenticationHeaders(await this.getAccessToken()), path);
        return await appApi.getSessions();
    } catch (e) {
      handleRestError(i18n.t('error.loadSessions'),e)
    }
  }

  async getRecordings(path: string, sessionId?: string) {
    try {
        const appApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
        return await appApi.listRecordings({instanceId: sessionId != null ? sessionId : undefined});
    } catch (e) {
      handleRestError(i18n.t('error.loadRecordings'),e)
    }
  }

  async getThreadDumps(path: string, sessionId?: string) {
    try {
        const appApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
        return await appApi.listThreadDumps({instanceId: sessionId != null ? sessionId : undefined});
    } catch (e) {
      handleRestError(i18n.t('error.loadThreadDumps'),e)
    }
  }

  async getStats(path: string) {
    try {
        const appApi = basicApi(getAuthenticationHeaders(await this.getAccessToken()), path);
        return await appApi.getStats();
    } catch (e) {
      handleRestError(i18n.t('error.loadStats'),e)
    }
  }

  async getSession(path: string, id: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      return await SessionsApi.getSession({id})
    } catch (e) {
      handleRestError(i18n.t('error.loadSession',{id:id}),e)
    }
  }

  async startRecording(path: string, id: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      const data = await SessionsApi.startRecording({id})
      if(data) {
        showToastOk(i18n.t('ok.recordingStarted'))
      }
      return data
    } catch (e) {
      handleRestError(i18n.t('error.startRecording'),e)
    }
  }

  async stopRecording(path: string, id: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      const data = await SessionsApi.stopRecording({id})
      if(data) {
        showToastOk(i18n.t('ok.recordingStopped'))
      }
      return data
    } catch (e) {
      handleRestError(i18n.t('error.couldNotStopRecording'),e)
    }
  }

  async forceKillSession(path: string, id: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      SessionsApi.shutdown({id, force:'true'});
      showToastOk(i18n.t('ok.appForceTerminated'))
      } catch (e) {
      handleRestError(i18n.t('error.swingAppShutdownFailed'),e)
    }
  }

  async killSession(path: string, id: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      SessionsApi.shutdown({id});
      showToastOk(i18n.t('Application signalled to exit.'))
    } catch (e) {
      handleRestError(i18n.t('error.couldNotKillSwingApp'),e)
    }
  }

  async requestThreadDump(path: string, instanceId: string) {
    try {
      const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
      SessionsApi.requestThreadDump({instanceId});
      showToastOk(
        i18n.t("ok.threadDumpreqWarningDialog")
      );
    } catch (e) {
      handleRestError(i18n.t('error.threadDumpFailed'),e)
    }
  }
  
  async toggleStatisticsLogging(path: string, instanceId: string, enabled: boolean) {
	  try {
		  const SessionsApi = manageSessionsApi(path, getAuthenticationHeaders(await this.getAccessToken()));
		  await SessionsApi.toggleStatisticsLogging({instanceId, enabled});
      showToastOk(
        `${i18n.t('ok.statisticLogs')} ${enabled?i18n.t('general.enabled'):i18n.t('general.disabled')}.`
      );
	  } catch (e) {
      handleRestError(i18n.t('error.couldNotToggleStatisticLogs'),e)
	  }
  }
  
}

export const SessionsService = SessionsRestService.getInstance();