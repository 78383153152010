// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     * 
     * @type {boolean}
     * @memberof LogRequest
     */
    backwards?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LogRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof LogRequest
     */
    max?: number;
}

export function LogRequestFromJSON(json: any): LogRequest {
    return LogRequestFromJSONTyped(json, false);
}

export function LogRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backwards': !exists(json, 'backwards') ? undefined : json['backwards'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'max': !exists(json, 'max') ? undefined : json['max'],
    };
}

export function LogRequestToJSON(value?: LogRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backwards': value.backwards,
        'offset': value.offset,
        'max': value.max,
    };
}


