// .core
import React from 'react'
// components
import { INoDataProps, NoData } from '../../basic/NoData/NoData'
import { Loader } from '../../basic/Loader/Loader'
// libraries
import cx from 'classnames'
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import { ScrollParams } from 'react-virtualized/dist/commonjs/Grid'
// import { InfiniteLoader } from 'react-virtualized/dist/commonjs/InfiniteLoader'
import { List as ListVirtualized, ListRowProps } from 'react-virtualized/dist/commonjs/List'
// styles
import css from './List.module.scss'
import 'react-virtualized/styles.css'
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { Button } from 'components'
// declarations
export type IRowData<T> = T & { index: number }

export interface IListProps<T> {
  /**
   * Whether data fetching is in progress, renders a `<Loader.Line />` at the top of `List`
   */
  bLoading?: boolean
  bVirtualized?: boolean
  className?: string
  rowClass?:string
  /**
   * List of data being used by List's items
   */
  collection?: T[]
  /**
   * Custom component rendered per item in collection
   *
   * @default
   * <ListItem />
   */
  item: (rowData?: IRowData<T>) => JSX.Element
  /**
   * Config for what to display when there are no records
   */
  noData?: INoDataProps
  /**
   * Custom height of each row
   *
   * @default '40px'
   */
  rowHeight?: number
  /**
   * Distance from the bottom of the list at which the `onReachEnd` is called
   *
   * @default 0
   */
  threshold?: number
  /**
   * Event fired everytime the `collection` changes in any way (add, reorder, delete)
   */
  // #UNUSED
  //   onChange?(): void
  /**
   * Event fired when scroll reaches end of the list
   *
   * Enabled on when `bVirtualized === true`
   */
  onReachEnd?(): void
  /**
   * Event fired when and item (or group of items) is reordered
   */
  onReorder?(): void
  bscrollToBottom: boolean
  bstaticHeight: boolean
  minListWidth?: number
  searched?: string
}

interface IState {
  scrolledtoBottom?: boolean;
  scrollCount: number;
}

/**
 * Component for rendering any type of list (vertical, horizontal, grid based)
 * Supports
 */
export class List<T> extends React.Component<IListProps<T>, IState> {
  static defaultProps = {
    rowHeight: 50,
    treshold: 0,
    bscrollToBottom:false,
    bstaticHeight:true
  }
  private cache;
  constructor(props:IListProps<T>) {
    super(props);
    this.state = {
      scrolledtoBottom: props.bscrollToBottom,
      scrollCount: 2,
    };
    const cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 60
    });
    this.cache = cache;
  }

  refList = React.createRef<ListVirtualized>()

  componentDidMount() {
    window.addEventListener("resize", ()=>this.recalculateSize());
    if(this.props.bscrollToBottom) {
      setTimeout(() => this.scrollTo('bottom'), 200)
    }
  }

  componentDidUpdate(prevProps:IListProps<T>) {
    const { collection, bscrollToBottom, bstaticHeight, searched } = this.props;
    const { scrolledtoBottom } = this.state;
    if (bscrollToBottom && collection?.length !== prevProps.collection?.length) {
      if(!bstaticHeight) {
        this.cache.clearAll()
        this.refList.current?.recomputeRowHeights();
      }
      if(scrolledtoBottom) {
        this.scrollTo('bottom');
      }
    }
    if( searched !== prevProps.searched) {
      this.refList.current?.forceUpdateGrid();
    }
  }

  componentWillUnmount() {
      window.removeEventListener("resize", ()=>this.recalculateSize());
  }

  recalculateSize() {
    setTimeout(() =>{
      if(!this.props.bstaticHeight) {
        this.cache.clearAll()
        this.refList.current?.recomputeRowHeights();
      }
    },300)
  }

  onScroll = (e: ScrollParams) => {
    const { threshold, onReachEnd } = this.props
    if (e.scrollTop >= e.scrollHeight - e.clientHeight - (threshold || 0)) {
      onReachEnd?.()
      this.setState({scrolledtoBottom:true})
    } else {
      this.setState({scrolledtoBottom:false})
    }
  }

  /**
   * Scroll to bottom & top of List/Grid
   */

  public scrollTo = (position: 'bottom' | 'top') => {
    const element = document.querySelector('.ReactVirtualized__List');
    if(element) {
      switch (position) {
        case 'bottom':
            const maxScrollTop = element.scrollHeight - element.clientHeight;
            if (maxScrollTop > element.scrollTop) {
              this.refList.current?.scrollToPosition(maxScrollTop); // Ref to <List /> component instance (or Grid)
              requestAnimationFrame(()=>this.scrollTo('bottom'));
            }
          break;
        case 'top':
          const current = element.scrollTop;
          if (current > 0) {
            this.refList.current?.scrollToPosition(0); // Ref to <List /> component instance (or Grid)
            requestAnimationFrame(()=>this.scrollTo('top'));
          }
          break;
      }
    }
  };

  public isScrollable = () => {
    const element = document.querySelector('.ReactVirtualized__List');
    if(element) {
      return element.scrollHeight > element.clientHeight;
    }
  };


  public scrollToggle = () => {
    if(this.state.scrolledtoBottom) {
      this.scrollTo('top');
    } else {
      this.scrollTo('bottom');
    }
  }

  // #UNUSED
  /**
   * Event that scrolls to a row based on provided index
   * @param index Index of row to scroll to
   */
  onScrollTo(index: number) {
    this.refList.current?.scrollToRow(index)
  }

  renderNoData = () => <div className={cx(css.wListVirtualized)} ><NoData {...this.props.noData} /></div>

  renderRow = ({ index, parent, key, style }: ListRowProps): JSX.Element => {
    const { collection, item,bstaticHeight } = this.props

    return <CellMeasurer 
      key={key}
      cache={this.cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      {/* {isScrolling ? (
        <Skeleton.Table bLoading count={1} style={style} />
      ) : ( */}
          <div key={key} style={{ ...style }} className={bstaticHeight?css.noWrap:''}>
            {item(collection && { ...collection[index], index })}
          </div>
      {/* )} */}
    </CellMeasurer>
  }

  renderList = () => {
    const { bVirtualized, collection, item, rowHeight, rowClass, bstaticHeight, minListWidth } = this.props
    return bVirtualized ? (
      <div className={css.wList}>
        {
          collection?.length ? 
            <AutoSizer>
            {({ height, width }) => {
              let makeWidth = width
              if (minListWidth) {
                makeWidth = width < minListWidth ? minListWidth : width
              }
              return (
                <ListVirtualized
                  ref={this.refList}
                  rowRenderer={this.renderRow}
                  rowCount={collection?.length || 0}
                  rowHeight={!bstaticHeight ? this.cache.rowHeight: rowHeight!}
                  className={cx(css.wListVirtualized)}
                  height={height}
                  overscanRowCount={5}
                  deferredMeasurementCache={this.cache}
                  width={bstaticHeight ? makeWidth : width}
                  onScroll={this.onScroll}
                />
              )}
              }
            </AutoSizer>
          :
          this.renderNoData()
        }
        {
          this.isScrollable() &&
          <Button className={css.fab} icon={this.state.scrolledtoBottom?'arrow_upward':'arrow_downward'} onClick={()=>this.scrollToggle()} />
        }
      </div>
    ) 
    : (
      <div className={css.wListRegular}>
        {collection
          ? collection.map((row, index) => (
              <div key={'row_' + index} className={rowClass} style={{ height: rowHeight }}>
                {item({ ...row, index })}
              </div>
            ))
          : this.renderNoData()}
      </div>
    )
  }

  render() {
    const { bLoading, className } = this.props
  
    return (
      <div className={cx(className)}>
        {/* LOADER */}
        <Loader.Line bLoading={bLoading} />
        {this.renderList()}
      </div>
    )
  }
}
