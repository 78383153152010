// core
import React, { useState, useCallback, Fragment } from 'react';

// libraries
import cx from 'classnames';

// components
import { Avatar, Badge, Icon, Divider } from 'components/basic';
import { Popover } from 'components/complex';

// styles
import css from './RightToggleMenu.module.scss';
import { AccessService } from 'services';
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

interface IRightToggleMenuProps {
  bsmall?:boolean
}

export const RightToggleMenu = observer(({bsmall=false}:IRightToggleMenuProps) => {
  const {permissionsStore, globalStore} = useStore();
  const [expanded, setExpanded] = useState(false);
  const [expandedLang, setExpandedLang] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const {t,i18n} = useTranslation(); 
  const logout = () => {
    AccessService.logout();
  }

  const handleToggleExpand = useCallback(() => {
    setExpanded(val=>!val);
    if(expandedLang) {
      setExpandedLang(val=>!val);
    }
  }, [setExpanded, expanded]); 

  const changeLang = (code:string) => {
    i18n.changeLanguage(code)
    setExpanded(val=>!val);
  }

  return (
    <div className={css.root}>
      <Popover
        darkBP
        backdrop
        width={240}
        className={cx(css.popover,{[css.smaller]:bsmall})}
        open={expanded}
        side="right"
        onBlur={handleToggleExpand}>
        { globalStore.availableLangs.length > 1 &&
        <div className={cx(css.MenuItem)} onClick={()=>setExpandedLang((val)=>!val)} >
          <div>{t('general.language')}</div>
          <Icon name={!expandedLang?'expand_more':'expand_less'}/>
        </div>
        }
        {
          expandedLang &&
          <>
            {
              globalStore.availableLangs.filter(l=>l.code!==i18n.language).map(lang => (
                <Fragment key={lang.code}>
                  <Divider margin={0}/>
                  <div key={lang.code} className={cx(css.MenuItem)} onClick={()=>changeLang(lang.code)} >
                    <div>{lang.name}</div>
                    <Icon name="translate"/>
                  </div>
                </Fragment>
              ))
            }
          </>
        }
        <Divider margin={0}/>
        <div className={cx(css.MenuItem)} onClick={logout}>
          <div>{t('general.logout')}</div>
          <Icon name="person_outline"/>
        </div>
        <Divider margin={0}/>
        <a className={cx(css.MenuItem, css.danger)} href={globalStore.serverUrl}>
          <div>{t('general.exit')}</div>
          <Icon name="exit_to_app"/>
        </a>
      </Popover>
      <Badge
        className={css.avatar}
        vertical="bottom"
        color="blue"
        content={<Icon size="mini" name={expanded ? 'expand_less' : 'expand_more'}/>}
        onClick={handleToggleExpand}
        >
        <Avatar 
          shape={'expandableCircle'} 
          bExpanded={expanded} 
          label={expanded && isDesktopOrLaptop ? t('general.hello', {user:permissionsStore.get('user')}):(permissionsStore.get('user') as string).charAt(0)}
        />
      </Badge>
    </div>
  );
});
