// .core
import React from 'react';

// components
import { Spinner } from 'components';

// libraries
import cx from 'classnames';

// styles
import css from './Branded.module.scss';
// assets
import Logo from 'assets/images/webswing basic default.svg';
import { ApiServerUrl, toPath } from 'utils';

interface IBrandedProps {
  children?: React.ReactNode
  className?: string
}

export const Branded = ({ children, className }: IBrandedProps) => (
  <div className={cx(css.wFullscreenBranded, className)}>{children ?? (
    <>
      <img src={toPath(ApiServerUrl,Logo)} height={124} alt="Webswing - Admin Console"/>
      <Spinner/>
    </>
  )}</div>
);
