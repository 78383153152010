// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceManagerStatus
 */
export interface InstanceManagerStatus {
    /**
     * 
     * @type {string}
     * @memberof InstanceManagerStatus
     */
    status?: InstanceManagerStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InstanceManagerStatus
     */
    server?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceManagerStatus
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceManagerStatus
     */
    errorDetails?: string;
}

export function InstanceManagerStatusFromJSON(json: any): InstanceManagerStatus {
    return InstanceManagerStatusFromJSONTyped(json, false);
}

export function InstanceManagerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceManagerStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'server': !exists(json, 'server') ? undefined : json['server'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorDetails': !exists(json, 'errorDetails') ? undefined : json['errorDetails'],
    };
}

export function InstanceManagerStatusToJSON(value?: InstanceManagerStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'server': value.server,
        'error': value.error,
        'errorDetails': value.errorDetails,
    };
}

/**
* @export
* @enum {string}
*/
export enum InstanceManagerStatusStatusEnum {
    Starting = 'Starting',
    Running = 'Running',
    Stopped = 'Stopped',
    Stopping = 'Stopping',
    Error = 'Error'
}


