// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IComponentProps } from 'components'

// styles
import css from './Point.module.scss'

export interface IPointProps extends IComponentProps {
  /**
   * Styling of the point, default theme color will be used if none specified.
   */
  color?: 'white' | 'neutral' | 'primary' | 'success' | 'warning' | 'danger'
}

export const Point = ({ color = 'primary', className }: IPointProps) => {
  return <div className={classnames(css.root, css[color], className)} />
}
