// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaObject
 */
export interface MetaObject {
    /**
     * 
     * @type {string}
     * @memberof MetaObject
     */
    message?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof MetaObject
     */
    fields?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MetaObject
     */
    data?: { [key: string]: object; };
}

export function MetaObjectFromJSON(json: any): MetaObject {
    return MetaObjectFromJSONTyped(json, false);
}

export function MetaObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function MetaObjectToJSON(value?: MetaObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'fields': value.fields,
        'data': value.data,
    };
}


