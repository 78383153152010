// .core
import React, {useEffect} from 'react'
import { useParams, useHistory } from 'react-router-dom'
// components
import { Button, Icon } from 'components';
// services
// styles
import cx from 'classnames';
import css from './SessionView.module.scss'
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
import { observer } from 'mobx-react';
import { SessionTabs } from './partials/SessionTabs';
import { SessionToolBar } from './partials/SessionNavCard';
import { changeTitle } from 'views/routes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
interface IParams {
    app: string
    id: string
}

export const SessionView= observer((): JSX.Element => {
    const {sessionsStore, globalStore} = useStore();
    const history = useHistory();
    const {app, id} = useParams<IParams>();
    const {t} = useTranslation();
    useEffect(() => {
      globalStore.updateApp(app);
      sessionsStore.refreshSession(app, id);
      return () => sessionsStore.clearInterval();
    },[app]);

    changeTitle(t('routes.session') +' - ' +app);

    return ( 
      <div className={css.root}>
        <Button.UI className={cx(cssTab.wTab, css.backButton)} color={'neutral'} onClick={() => history.goBack()}>
          <Icon name='close' size='mini' /><span>{t('sessions.closePreview')}</span>
        </Button.UI>
        <SessionToolBar app={app} id={id} />
        <SessionTabs app={app} id={id} />
      </div>
    )
})
