// core
import { useState, useEffect } from 'react';
import cx from 'classnames';
// components
import { StatusBar } from 'components'
import FormiumConfig from '../Applications/Partials/FormiumConfig'
// routes
import { changeTitle } from '../routes';
// utils
import { ApiServerUrl, hiddenServerConfigFields } from 'utils'
// styles
import css from '../Applications/Applications.module.scss'
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import { ButtonGroup } from 'components/basic/Button/variants/ButtonGroup/ButtonGroup';
import { useTranslation } from 'react-i18next';

export const ConfigServer = observer(() => {
  const {permissionsStore, globalStore, configStore } = useStore()
  const [loadingMessage, setLoadingMessage] = useState<string>('loading..');
  const {t,i18n} = useTranslation();
  useEffect(() => {
    configStore.loadConfig();
    globalStore.loadInfo().then(()=> {
      setLoadingMessage('');
    });
    globalStore.updateApp('');

    return () => {
      configStore.clearConfigData()
    }
  },[]);

  changeTitle(i18n.t('routes.serconfig'));

  return (
    <>
      <div className={css.StatusPanel}>
        <span className={css.StatusPanelLabel}>Status: </span>
          <StatusBar status={globalStore.info?.status!} loadMessage={loadingMessage} />
      </div>
      <FormiumConfig
        readonly={!permissionsStore.get('configEdit')} 
        identify={'serverConfig'} 
        path={ApiServerUrl}
        value={configStore.config?.webConfig} 
        hide={hiddenServerConfigFields}
        translations={i18n.getDataByLanguage(i18n.language)?.translation}
        lang={i18n.language}
      />
      <ButtonGroup
        className={cx(css.floatingButtons,{[css.activeApply]: configStore.activeApply})}
        buttons={
          [
            {label: i18n.t('Apply'),size:'small', color:'blue', onClick: ()=>configStore.setConfig()},
            {label:i18n.t('Reset'),size:'small', color:'white', onClick: ()=>configStore.resetConfig()}
          ]
        } 
      />
    </>
  )
})
