import React from 'react';
import { AreaChart as Chart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import css from './Charts.module.scss'
import {useStore} from 'stores';
import { observer } from 'mobx-react';
import { Flex, Heading } from 'components';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export const Charts = observer(() => {
    const {sessionsStore} = useStore();
    const {t} = useTranslation();
    const tooltipStyle = { 
        fontSize: "12px",
        lineHeight: 'normal'
    }
    const dateOptions = {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
      };
const timeTick = (value:any) => {
   return value.toLocaleString('en-US', dateOptions); 
}
return (
    <div className={css.root}>
        <Flex itemsPerRow={2}>
            <Flex bColumn className={css.lineChart}>
                <Heading className={css.charsHeading} variant="h3" title={t('sessions.memory')} />
                <ResponsiveContainer width="100%" height="100%">
                <Chart
                    className={css.chart}
                    data={sessionsStore.getMemoryStats.dataset}
                    margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient id="colorMemoryAllocated" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorMemoryUsed" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis className={css.axisFont} dataKey="x" tickFormatter={timeTick} minTickGap={30} />
                    <YAxis className={css.axisFont} tickFormatter={sessionsStore.getMemoryStats.tickFormat} />
                    <Tooltip
                        wrapperStyle={tooltipStyle}
                        labelFormatter={LabelFormat}
                    />
                    <Area type="monotone" dataKey="memoryUsed" stackId="1" stroke="#82ca9d" fillOpacity={1} fill="url(#colorMemoryUsed)" />
                    <Area type="monotone" dataKey="memoryAllocated" stackId="1" stroke="#8884d8" fillOpacity={1} fill="url(#colorMemoryAllocated)" />
                </Chart>
                </ResponsiveContainer>
            </Flex>
            <Flex bColumn className={css.lineChart}>
                <Heading className={css.charsHeading} variant="h3" title={t('sessions.cpu')} />
                <ResponsiveContainer width="100%" height="100%">
                    <Chart
                        className={css.chart}
                        data={sessionsStore.getCpuStats.dataset}
                        margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorCpuUtilization" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="x" tickFormatter={timeTick} />
                        <YAxis tickFormatter={sessionsStore.getCpuStats.tickFormat} />
                        <Tooltip
                            wrapperStyle={tooltipStyle}
                            labelFormatter={LabelFormat}
                        />
                        <Area type="monotone" dataKey="cpuUtilization" stackId="1" stroke="#ffc658" fillOpacity={1} fill="url(#colorCpuUtilization)"  />
                    </Chart>
                </ResponsiveContainer>
            </Flex>
            <Flex bColumn className={css.lineChart}>
                <Heading className={css.charsHeading} variant="h3" title={t('sessions.bandwidth')} />
                <ResponsiveContainer width="95%" height="100%">
                    <Chart
                        className={css.chart}
                        data={sessionsStore.getBandwidthStats.dataset}
                        margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorInboundSize" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F5444A" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#F5444A" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorOutboundSize" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="x" tickFormatter={timeTick} />
                        <YAxis tickFormatter={sessionsStore.getBandwidthStats.tickFormat} />
                        <Tooltip
                            wrapperStyle={tooltipStyle}
                            labelFormatter={LabelFormat}
                        />
                        <Area type="monotone" dataKey="inboundSize" stackId="1" stroke="#F5444A" fillOpacity={1} fill="url(#colorInboundSize)" />
                        <Area type="monotone" dataKey="outboundSize" stackId="1" stroke="#ffc658" fillOpacity={1} fill="url(#colorOutboundSize)" />
                    </Chart>
                </ResponsiveContainer>
            </Flex>
            <Flex bColumn className={css.lineChart}>
                <Heading className={css.charsHeading} variant="h3" title={t('sessions.latency')} />
                <ResponsiveContainer width="100%" height="100%">
                    <Chart
                        className={css.chart}
                        data={sessionsStore.getLatencyStats.dataset}
                        margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorlatencyNetworkTransfer" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorlatencyServerRendering" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorlatencyClientRendering" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F5444A" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#F5444A" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorlatencyPing" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="x" tickFormatter={timeTick} />
                        <YAxis tickFormatter={sessionsStore.getLatencyStats.tickFormat} />
                        <Tooltip
                            wrapperStyle={tooltipStyle}
                            labelFormatter={LabelFormat}
                        />
                        <Area isAnimationActive={false} type="monotone" label={t('sessions.latencyNetTransfer')} dataKey="latencyNetworkTransfer" stackId="1" stroke="#8884d8" fillOpacity={1} fill="url(#colorlatencyNetworkTransfer)" />
                        <Area isAnimationActive={false} type="monotone" dataKey="latencyServerRendering" stackId="1" stroke="#82ca9d" fillOpacity={1} fill="url(#colorlatencyServerRendering)" />
                        <Area isAnimationActive={false} type="monotone" dataKey="latencyClientRendering" stackId="1" stroke="#F5444A" fillOpacity={1} fill="url(#colorlatencyClientRendering)" />
                        <Area isAnimationActive={false} type="monotone" dataKey="latencyPing" stackId="1" stroke="#ffc658" fillOpacity={1} fill="url(#colorlatencyPing)" />
                    </Chart>
                </ResponsiveContainer>
            </Flex>
        </Flex>
    </div>
    )
})

const LabelFormat = (value:any) => {
    return (`${i18n.t('sessions.time')}: ${value.toLocaleString('en-US')}`);
}