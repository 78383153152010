import React, { useEffect, useState } from "react";
import css from '../Sessions.module.scss'
import cx from 'classnames';
import { SwingSession } from "gen";
import { useStore } from "stores";
import { observer } from "mobx-react";
import { Icon, Flex, Tooltip } from "components";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IUserCellProps {
    bSession?:boolean,
    session:SwingSession
}

export const AppCell = observer(({bSession=false, session}: IUserCellProps) => {
    const {app} = useParams<{app:string}>();
    const history = useHistory();
    const {permissionsStore, globalStore, sessionsStore} = useStore();
    const {t} = useTranslation();
    const [now, setNow] = useState(Date.now());

    const view = (session: SwingSession) => {
        history.push('/session' + session.applicationPath +'/'+ session.id)
        sessionsStore.setActiveTab('view');
    }

    const onStop = () => {
        sessionsStore.stopRecording(session, bSession)
        // handleReset();
    }

    const isRecording = (session: SwingSession) => {
        return session.recordingStatus === 'WAITING_FOR_RECORDING_APPROVAL' || session.recordingStatus === 'RECORDING'
    }

    const getRecordingTimeElapsed = (session: SwingSession): string => {
        if (!session.recordingFile || !session.recordingFile.startDate) {
            return t('sessions.recording');
        }

        let delta = Math.abs(now - session.recordingFile.startDate) / 1000;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }

    useEffect(() => {
        const interval = setInterval(() => setNow(Date.now()), 500);
        return () => {
          clearInterval(interval);
        };
    }, []);

    return (
        <>
            {
            isRecording(session) ?
                <div className={cx(css.Cell, css.recordCell)}>
                    <Tooltip content={ t('sessions.instance')+' '+t('sessions.id')+': '+ session.id}>
                        { bSession ?
                            <>{session.application}</>
                            :
                            <span onClick={()=>view(session)}>
                                {session.application} 
                            </span>
                        }
                    </Tooltip>
                    <Flex justifyContent='space-between'>
                        <Flex className={css.conter} alignItems={'center'}>
                            <Icon name={'fiber_manual_record'} color={'danger'} size={'small'} className={css.rightSpaceIcon} />
                            {
                                session.recordingStatus === 'WAITING_FOR_RECORDING_APPROVAL'?
                                t('sessions.waitingApproval')
                                :
                                getRecordingTimeElapsed(session)
                            }
                        </Flex>
                        {
                            permissionsStore.get('startRecording') &&
                            <Icon name={'stop'} color={'txt'} onClick={onStop} />
                        }
                    </Flex>
                </div>
            :
                <div className={css.appCell}>
                    {
                        bSession?
                        <>
                            <span className={css.label}>{t('sessions.app')}:</span>
                            <div className={css.appCellLinks} >
                                <Tooltip content={t('sessions.instance')+' '+t('sessions.id')+': '+ session.id}>
                                    <span>
                                        { session.application }
                                    </span>
                                </Tooltip>
                                {
                                    (session?.recordingFile && permissionsStore.get('playbackRecording')) &&
                                    <Tooltip content={t('sessions.playRecording')}>
                                        <span>
                                            <Icon name='play_arrow' onClick={() => sessionsStore.showPlayback(session.applicationPath!, session.recordingFile!)} />
                                        </span>
                                    </Tooltip>
                                }
                                {
                                    session.recordingStatus === 'DENIED_RECORDING_BY_USER' &&
                                    <Tooltip content={t('sessions.recordingDenied')}>
                                        <span>
                                            <Icon size='small' color='danger' name='report' />
                                        </span>
                                    </Tooltip>
                                }
                            </div>
                        </>
                        :
                        <div className={css.appCellLinks}>
                            <Tooltip content={ t('sessions.instance')+' '+t('sessions.id')+': '+ session.id}>
                                <span onClick={()=>view(session)}>
                                    {session.application} 
                                </span>
                            </Tooltip>

                            {
                                (session?.recordingFile && permissionsStore.get('playbackRecording')) &&
                                <Tooltip content={t('sessions.playRecording')}>
                                    <span>
                                        <Icon name='play_arrow' onClick={() => sessionsStore.showPlayback(session.applicationPath!, session.recordingFile!)} />
                                    </span>
                                </Tooltip>
                            }
                            {
                                session.recordingStatus === 'DENIED_RECORDING_BY_USER' &&
                                <Tooltip content={t('sessions.recordingDenied')}>
                                    <span>
                                        <Icon size='small' color='danger' name='report' />
                                    </span>
                                </Tooltip>
                            }
                        </div>
                    }
                </div>
            }
        </>
      
    )
})

