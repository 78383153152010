// .core
import React, {useCallback, useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import cx from 'classnames';

// components
import { IColumns, Icon, SearchFilter, TableVirtualized, Button, Flex, Heading } from 'components';
// styles
import css from './Logs.module.scss'
import cssTable from '../../components/complex/Table/Table.module.scss'
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import { SessionLogInfo, SwingSession } from 'gen';
import { changeTitle } from 'views/routes';
import { dateFormat, useRootOverflow } from 'utils';
import { useTranslation } from 'react-i18next';
import { HighlightedText } from './partial/HighlightedText';
import { StatusCell } from 'views/Sessions/partials/StatusCell';
import { UserCell } from 'views/Sessions/partials/UserCell';

interface LogParamTypes {
    app:string;
}
interface ISessionLogInfo extends SessionLogInfo, SwingSession {
}

export const LogsInstancesList = observer(() => {
    const {sessionsStore, logsStore, globalStore} = useStore()
    const history = useHistory()
    const {t} = useTranslation()
    const { app} = useParams<LogParamTypes>();
    const showInstanceLog = useCallback((params) => {
        history.push(`/logs/session/${app}/${params.rowData.instanceId}/${encodeURIComponent(params.rowData.sessionPoolId)}`)
    },[app, history])

    useRootOverflow();

    useEffect(() => {
        globalStore.updateApp(app);
        logsStore.loadLogsOverview(app)
        sessionsStore.setAppPath(app);
        sessionsStore.refresh();
        return () => sessionsStore.clearInterval();
    },[app]);

    changeTitle(t('routes.logs')+' - ' +app);
    
    const running = sessionsStore.sessions.sessions;
    const closed = sessionsStore.sessions.closedSessions;
    
    let sessions:SwingSession[] | undefined
    sessions = running?.length ? [...running]: undefined
    if(closed?.length) {
        sessions = sessions?.length ? [...sessions, ...closed]:undefined
    }
    let results = logsStore.appInstanceIds.map(s => {
        const session = sessions?.filter((session)=>session.id === s.instanceId)[0]
        return {
            ...s,
            ...session
        } as ISessionLogInfo
    })

    results = !logsStore.searchTerm
    ? results
    : results?.filter(item =>
        item.instanceId?.toLowerCase().includes(logsStore.searchTerm.toLocaleLowerCase()) ||
        item.user?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase()) ||
        item.status?.toLowerCase().includes(sessionsStore.searchTerm.toLocaleLowerCase())
    );

    const handleSeach = (event:React.ChangeEvent<HTMLInputElement>) => {
        logsStore.setSearchTerm(event.target.value);
    };

    return (
        <div className={css.root}>
            <Flex justifyContent={'space-between'} alignItems='center'>
                <Heading variant='h2' title={t('logs.chooseAppInst')}/>
                {!logsStore.filterToggle && <Button color='neutral' icon={'search'} onClick={()=>logsStore.setFilterToggle()} /> }
                {
                logsStore.filterToggle && 
                    <SearchFilter 
                        value={logsStore.searchTerm} 
                        onChange={handleSeach} 
                        onClose={()=>logsStore.setFilterToggle()}
                    />
                }
            </Flex>
            <div className={css.scrollx}>
                <TableVirtualized
                    collection={results}
                    minTableWidth={1200}
                    bLoading={logsStore.loading}
                    rowHeight={60}
                    threshold={500}
                    className={css.vTable}
                    sortBy='lastModified'
                    sortDirection='DESC'
                    columns={[
                        {
                            label:t('sessions.plural'),
                            dataKey:'instanceId',
                            minWidth: 386,
                            align: 'start',
                            bSort: true,
                            className: css.pointer,
                            component: (instanceId) => <div className={cx(cssTable.wCell, css.statusAlign)}><HighlightedText text={instanceId as string} /></div>
                        },
                        {
                            dataKey: 'user',
                            label: t('logs.user'),
                            minWidth: 100,
                            align: 'start',
                            bSort:true,
                            component: (user, row) => <UserCell session={row} />
                        },
                        {
                            dataKey: 'status',
                            label: t('general.status'),
                            align: 'start',
                            minWidth: 100,
                            bSort:true,
                            className: css.pointer,
                            component: (connected,row) => <StatusCell session={row} />
                        },
                        {
                            dataKey: 'startedAt',
                            label: t('sessions.startTime'),
                            minWidth: 135,
                            align: 'start',
                            bSort:true,
                            className: css.pointer,
                            component: (startedAt) => <div className={cx(cssTable.wCell, css.statusAlign)}>{dateFormat(Number(startedAt))}</div>
                        },
                        {
                            dataKey: 'endedAt',
                            label: t('sessions.endTime'),
                            minWidth: 135,
                            align: 'start',
                            bSort:true,
                            className: css.pointer,
                            component: (endedAt) => <div className={cx(cssTable.wCell, css.statusAlign)}>{dateFormat(Number(endedAt))}</div>
                        },
                        {
                            label:t('overview.lastModified'),
                            dataKey:'lastModified',
                            minWidth: 250,
                            align: 'start',
                            bSort: true,
                            component: (lastModified) => <span className={css.fullWColl}>
                                {dateFormat(Number(lastModified))}
                                <Icon name={'arrow_forward_ios'} />
                                </span>
                        }
                    ] as IColumns<ISessionLogInfo>[]} 
                    onRowClick={showInstanceLog}
                />
            </div>
        </div>
    )
})
