// .core
import React, { ReactNode } from 'react'
// libraries
import classnames from 'classnames'
// styles
import css from './Card.module.scss'
import { Heading } from 'components/complex'
import { IComponentProps } from 'components'

interface ICardProps extends IComponentProps {
  className?: string
  titleClassName?: string
  children: React.ReactNode
  margin?: string
  title?: string
  onClick?(): void
}

export const Card: React.FC<ICardProps> = ({
  children,
  className,
  titleClassName,
  margin,
  title,
  onClick,
  classes = {},
}: ICardProps) => {
  const _onClick = () => {
    onClick?.()
  }

  return (
    <>
      {/* TITLE */}
      {title && <Heading className={classnames(css.heading, titleClassName)} variant="h3" title={title} />}
      <div
        className={classnames(css.wCard, onClick && ['gClickable', css.clickable], className)}
        style={{ margin }}
        onClick={_onClick}>
        {/* CONTENT */}
        <div className={classnames(css.wContent, classes.content)}>{children}</div>
      </div>
    </>
  )
}

export interface ICardContentProps extends IComponentProps {
  children: ReactNode
  onClick?(): void
}

export const CardContent = ({ children, className, onClick }: ICardContentProps) => {
  const _onClick = () => {
    onClick?.()
  }
  return <div className={classnames(css.cardContent, className)} onClick={_onClick}>{children}</div>
}
