import PermissionsStore from './PermissionsStore';
import GlobalStore from './GlobalStore';
import OverviewStore from './OverviewStore';
import ConfigStore from './ConfigStore';
import SessionsStore from './SessionsStore';
import LogsStore from './LogsStore';
import RecordingsAndThreadDumpsStore from './RecordingsAndThreadDumpsStore';

export class RootStore {
  permissionsStore: PermissionsStore;
  globalStore: GlobalStore;
  overviewStore: OverviewStore;
  configStore: ConfigStore;
  sessionsStore: SessionsStore;
  logsStore: LogsStore;
  recordingsAndThreadDumpsStore: RecordingsAndThreadDumpsStore;

  constructor() {
    this.permissionsStore = new PermissionsStore(this);
    this.globalStore = new GlobalStore();
    this.overviewStore = new OverviewStore();
    this.configStore = new ConfigStore();
    this.sessionsStore = new SessionsStore(this);
    this.logsStore = new LogsStore(this);
    this.recordingsAndThreadDumpsStore = new RecordingsAndThreadDumpsStore();
  }
}
export const store = new RootStore