// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstanceManagerStatus,
    InstanceManagerStatusFromJSON,
    InstanceManagerStatusFromJSONTyped,
    InstanceManagerStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BasicApplicationInfo
 */
export interface BasicApplicationInfo {
    /**
     * 
     * @type {string}
     * @memberof BasicApplicationInfo
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicApplicationInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicApplicationInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof BasicApplicationInfo
     */
    runningInstances?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicApplicationInfo
     */
    enabled?: boolean;
    /**
     * 
     * @type {Array<InstanceManagerStatus>}
     * @memberof BasicApplicationInfo
     */
    status?: Array<InstanceManagerStatus>;
}

export function BasicApplicationInfoFromJSON(json: any): BasicApplicationInfo {
    return BasicApplicationInfoFromJSONTyped(json, false);
}

export function BasicApplicationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicApplicationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': !exists(json, 'path') ? undefined : json['path'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'runningInstances': !exists(json, 'runningInstances') ? undefined : json['runningInstances'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(InstanceManagerStatusFromJSON)),
    };
}

export function BasicApplicationInfoToJSON(value?: BasicApplicationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'url': value.url,
        'name': value.name,
        'runningInstances': value.runningInstances,
        'enabled': value.enabled,
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(InstanceManagerStatusToJSON)),
    };
}


