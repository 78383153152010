// .core
import React from 'react';
// components
import { Content, Header, LeftSideMenu } from 'components';
// libraries
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from 'views/routes';
import { PageNotFound } from 'views/misc/PageNotFound/PageNotFound';
import { ErrorView } from 'views/misc/ErrorView/ErrorView';
import { observer } from 'mobx-react';
// styles
import css from './Layout.module.scss'
import { useStore } from 'stores';

interface ILayoutWrapper {
  children: React.ReactNode
}

export const Layout = () => (
    <LayoutWrapper>
      <div className={css.root}>
        <LeftSideMenu />
        <Content> 
          <Header />
          <RoutesContainer />
        </Content>
      </div>
    </LayoutWrapper>
);

export const LayoutWrapper = observer( ({children}:ILayoutWrapper) => {
  const {globalStore} = useStore();
  return (
  <>
    {/* theming */}
    <style dangerouslySetInnerHTML={{__html: globalStore.customization?.themeVariables!}}></style>
    {
      globalStore.showErrorView ? <ErrorView header={globalStore.errorView?.header} bLoginBtn={globalStore.errorView?.bLoginBtn} subtitle={globalStore.errorView?.subtitle} /> : children
    }
  </>
  )
});

export const RoutesContainer = () => {
  const { search } = useLocation()
  const routeParam = new URLSearchParams(search).get("route");
  return (
    <div className={css.container}>
      <Switch>
          {ROUTES.map(page => (
            <Route key={page.path} exact={page.exact} path={'/' + page.path} component={page.component}/>
          ))}
          <Route path='/'>
            {
              routeParam ? 
              <Redirect to={routeParam} />
              :
              <Redirect to='/overview' />
            }
          </Route>
        <PageNotFound />
      </Switch>
    </div>
  )
};