// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActiveSessionsInfo,
    ActiveSessionsInfoFromJSON,
    ActiveSessionsInfoToJSON,
    ApplicationInfo,
    ApplicationInfoFromJSON,
    ApplicationInfoToJSON,
    BasicApplicationInfo,
    BasicApplicationInfoFromJSON,
    BasicApplicationInfoToJSON,
    Permissions,
    PermissionsFromJSON,
    PermissionsToJSON,
    Sessions,
    SessionsFromJSON,
    SessionsToJSON,
} from '../models';

/**
 * no description
 */
export class BasicApi extends runtime.BaseAPI {

    /**
     * Returns count of all live application sessions
     * Get Number of Active Sessions
     */
    async activeSessionsCountRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/activeSessionsCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Returns count of all live application sessions
     * Get Number of Active Sessions
     */
    async activeSessionsCount(): Promise<number> {
        const response = await this.activeSessionsCountRaw();
        return await response.value();
    }

    /**
     * Returns count of connected, disconnected and total count of active sessions
     * Get Number of Active Sessions
     */
    async activeSessionsInfoRaw(): Promise<runtime.ApiResponse<ActiveSessionsInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/activeSessionsInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionsInfoFromJSON(jsonValue));
    }

    /**
     * Returns count of connected, disconnected and total count of active sessions
     * Get Number of Active Sessions
     */
    async activeSessionsInfo(): Promise<ActiveSessionsInfo> {
        const response = await this.activeSessionsInfoRaw();
        return await response.value();
    }

    /**
     * Returns applications png file
     * Get Application Icon
     */
    async getIconRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/appicon`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Returns applications png file
     * Get Application Icon
     */
    async getIcon(): Promise<string> {
        const response = await this.getIconRaw();
        return await response.value();
    }

    /**
     * Return detailed information of this Server or Application
     * Get Path Info
     */
    async getInfoRaw(): Promise<runtime.ApiResponse<ApplicationInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationInfoFromJSON(jsonValue));
    }

    /**
     * Return detailed information of this Server or Application
     * Get Path Info
     */
    async getInfo(): Promise<ApplicationInfo> {
        const response = await this.getInfoRaw();
        return await response.value();
    }

    /**
     * Returns basic information about available applications
     * Get Available Applications
     */
    async getPathsRaw(): Promise<runtime.ApiResponse<Array<BasicApplicationInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/paths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicApplicationInfoFromJSON));
    }

    /**
     * Returns basic information about available applications
     * Get Available Applications
     */
    async getPaths(): Promise<Array<BasicApplicationInfo>> {
        const response = await this.getPathsRaw();
        return await response.value();
    }

    /**
     * Resolves if current user has permissions to perform selected actions
     * Get User Permissions
     */
    async getPermissionsRaw(): Promise<runtime.ApiResponse<Permissions>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionsFromJSON(jsonValue));
    }

    /**
     * Resolves if current user has permissions to perform selected actions
     * Get User Permissions
     */
    async getPermissions(): Promise<Permissions> {
        const response = await this.getPermissionsRaw();
        return await response.value();
    }

    /**
     * Returns all live and finished server sessions
     * Get All Sessions
     */
    async getSessionsRaw(): Promise<runtime.ApiResponse<Sessions>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionsFromJSON(jsonValue));
    }

    /**
     * Returns all live and finished server sessions
     * Get All Sessions
     */
    async getSessions(): Promise<Sessions> {
        const response = await this.getSessionsRaw();
        return await response.value();
    }

    /**
     * Stats are used to show usage graphs (CPU, MEM, Net)
     * Get Aggregated Stats
     */
    async getStatsRaw(): Promise<runtime.ApiResponse<{ [key: string]: { [key: string]: number; }; }>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Stats are used to show usage graphs (CPU, MEM, Net)
     * Get Aggregated Stats
     */
    async getStats(): Promise<{ [key: string]: { [key: string]: number; }; }> {
        const response = await this.getStatsRaw();
        return await response.value();
    }

    /**
     * Public URL of webswing server connected to this admin server
     * Webswing Server public URL
     */
    async getUrlRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Public URL of webswing server connected to this admin server
     * Webswing Server public URL
     */
    async getUrl(): Promise<string> {
        const response = await this.getUrlRaw();
        return await response.value();
    }

    /**
     * Current Version of webswing using git-describe format
     * Webswing Server Version
     */
    async getVersionRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Current Version of webswing using git-describe format
     * Webswing Server Version
     */
    async getVersion(): Promise<string> {
        const response = await this.getVersionRaw();
        return await response.value();
    }

}
