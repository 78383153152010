// styles
import scssVariables from 'styles/_colors.module.scss'

export const gColors = scssVariables

export const hiddenServerConfigFields = [
    "enabled", 
    "path", 
    "name", 
    "webHomeDir", 
    "webFolder", 
    "restrictedResources", 
    "langFolder", 
    "icon", 
    "allowedCorsOrigins", 
    "uploadMaxSize", 
    "maxClients", 
    "sessionMode", 
    "monitorEdtEnabled",
    "loadingAnimationDelay", 
    "allowStealSession", 
    "autoLogout", 
    "goodbyeUrl", 
    "recordingsFolder",
    "recordingConsentRequired",
    "mirroringConsentRequired",

    // Veryant specific entries
    "serverDebug",
    "remoteDebugPort",
    "launcherConfig",
    "mainClass",
    "args"
];
export const hiddenWebConfigFields = [
    "adminConsoleUrl",
    "path",
    "restUsers",
    "webSecurity",

    // Veryant specific entries
    "serverDebug",
    "remoteDebugPort",
    "launcherConfig",
    "mainClass",
    "args"
];