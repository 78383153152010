import React from 'react';
import css from '../SessionView.module.scss'
import { Card, List } from 'components';
import { dateFormat, parseObject, toPath } from 'utils';
import { SwingSession } from 'gen';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { ThreadDumpInfo } from 'gen/models/ThreadDumpInfo';

interface IWarningsProps {
    session:SwingSession
}

export const Warnings = ({session}:IWarningsProps) => {
    const {t} = useTranslation();
    const {globalStore, permissionsStore} = useStore();

    const showThreadDump = (item: ThreadDumpInfo) => {
		let url = toPath(globalStore.serverUrl!, session.applicationPath!) + "/rest/threadDump/" + item.token 
            + "/" + encodeURIComponent(item.sessionPoolId!) + "/" + false + "/" + encodeURIComponent(item.fileName!);
        window.open(url, "_blank");
    }
    const tdCollection = () => {
        if(parseObject(session.threadDumps!).isEmpty()) {
            return []
        }
        return Object.entries(session.threadDumps!).map((e) => ( { key:e[0], value:e[1] } ));
    }

    return (
        <div className={css.Warnings}>
            {
                (session.warnings && session.warnings.length > 0) &&
                <Card title={t('sessions.warningMsg')}>
                    <List 
                        rowHeight={60} 
                        collection={session.warnings!.map((s)=>({s:s}))} 
                        rowClass={css.warningLine} 
                        item={(item)=><div className={css.warningsListItem}>{item?.s}</div>} 
                    />
                </Card>
            }
            {
                (session.warningHistory && session.warningHistory.length > 0) &&
                <Card title={t('sessions.historyWarnMsgs')}>
                    <List 
                        rowHeight={60} 
                        collection={session.warningHistory!.map((s)=>({s:s}))} 
                        rowClass={css.warningLine} 
                        item={(item)=><div className={css.warningsListItem}>{item?.s}</div>} 
                    />
                </Card>
            }
            { 
                (!parseObject(session.threadDumps!).isEmpty() && permissionsStore.get('getThreadDump')) &&
                <Card title={t('sessions.threadDumps')} >
                    <List 
                        rowHeight={60} 
                        collection={tdCollection()} 
                        rowClass={css.warningLine} 
                        item={
                            (item:any) => <div className={css.warningsListItem}>
                            {item && <a onClick={()=>showThreadDump(item.value)}>{dateFormat(Number(item?.value.timestamp))} - {item?.value.reason}</a>}
                            </div>
                        }
                    />
                </Card>
            }
        </div>
    )
}