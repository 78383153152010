// .core
import {useCallback, useState } from 'react'
// components
import { Avatar, Card, CardContent, Chip, Flex, Heading, Icon } from 'components'
// libraries
import cx from 'classnames'
// styles
import css from '../Overview.module.scss'
import cssButton from 'components/basic/Button/variants/ButtonDefault/ButtonDefault.module.scss'
import {SessionPoolInfo, ServerInfo} from 'gen/models'
import { SpPie } from './SpPie'
import { AppsSPCell } from './AppsSPCell'
import { Dropdown } from 'components/basic/Dropdown/Dropdown'
import { useStore } from 'stores'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ApiServerUrl } from 'utils'

interface IServerCardProps {
  className?: string,
  onClick?(): void,
  sp: SessionPoolInfo
  clusterservers?: ServerInfo[]
}


export const SpCard = ({
  className,
  onClick,
  sp,
  clusterservers=[],
}: IServerCardProps) => {
  const [appsToggleOpen, setAppsToggleOpen] = useState(false)
  const {logsStore, overviewStore, globalStore} = useStore();
  const history = useHistory();
  const {t} = useTranslation();
  const getSpApps = () => {
    return Object.entries(sp.apps!).map((e) => {
      const path = globalStore.paths?.find((path)=>path.path === e[0])
      return { name:path?.name, path:e[0], value:e[1] }
    });
  }

  const getSpvsCluster = () => {
    const spApps = Object.entries(sp.apps!);
    return spApps.length + ' / ' + clusterservers[0].apps?.length;
  }
  const showLogs = useCallback((id) => {
    logsStore.setSpLogId(id);
    history.push('logs/sessionpool')
  }, [history])

  const drainModeTxt = () => {
    return sp.drainMode ? t('overview.resume') : t('overview.drainMode')
  }

  return (
    <Card onClick={onClick} className={cx(css.spCard, className)}>
    <CardContent>
          <Flex justifyContent='space-between'>
            <Heading
              variant={'h2'}
              classes={{ heading: css.cardHeading }}
              title={sp.id!}
            />
            <Dropdown
              position='right'
              buttons={[
                {label: t('overview.verboseMode'), icon:'info', onClick: () => overviewStore.toggleVerbose(sp,'sp'), shouldShow: !sp.stopped},
                {label: t('routes.logs'), icon:'list_alt', onClick: () => showLogs(sp.id!)},
                {label: drainModeTxt(), icon:'opacity', onClick: () => overviewStore.toggleDrain(sp), shouldShow: !sp.stopped},
                {label: t('overview.stop'), icon:'power_settings_new', onClick: () => overviewStore.stop(sp.id!), shouldShow: !overviewStore.hasRunningInstances(sp.apps!) && !sp.stopped},
                {label: t('overview.shutdownSessions'), icon:'power_settings_new', onClick: () => overviewStore.shutdown(sp), shouldShow: overviewStore.hasRunningInstances(sp.apps!) && !sp.stopped}
                // buttons:[{label: 'Force kill sessions', onClick:()=>overviewStore.forceKill(sp.id!)}],
              ]}
              >
                <span className={cx(cssButton.wButtonDefault,cssButton.medium, cssButton.neutral, cssButton.icon)}>
                  <Icon name={'more_vert'} />
                </span>
            </Dropdown>
          </Flex>
          <Flex spacing={2} className={cx(css.header, {[css.chipsLine]:overviewStore.isThereAnySPVerbose})}>
            {
              sp.verboseLogging &&
              <Chip size='small'><Icon name='check_circle_outline' size='mini' /> {t('overview.verboseMode')}</Chip>
            }
            {
              sp.drainMode && <Chip size='small' color='warning' ><Icon name='opacity' size='mini' /> {t('overview.drainMode')}</Chip>
            }
            {
              sp.stopped ? 
                <Chip size='small' color='danger' >{t('overview.stopped')}</Chip>
                :
                <Chip size='small' color='success' >{t('overview.running')}</Chip>
            }
          </Flex>
          <Flex spacing={3} flexWrap={'wrap'}>
            <Flex flex={1}>
              <SpPie apps={sp.apps} width={130} height={130} />
            </Flex>
            <Flex flex={2}>
              <table className={css.spTable}>
                <tbody>
                  <tr>
                    <td><strong>{t('overview.maxSessinos')}:</strong></td>
                    <td>{sp.maxInstances == -1 ? t('overview.unlimited'): sp.maxInstances }</td>
                  </tr>
                  <tr>
                    <td><strong>{t('general.priority')}:</strong></td>
                    <td>{sp.priority}</td>
                  </tr>
                  <tr onClick={()=>setAppsToggleOpen(!appsToggleOpen)}>
                    <td><strong>{t('routes.apps')}:</strong></td>
                    <td>
                      {
                        <AppsSPCell dialogHeader={t('overview.availableAppsforSP')} label={getSpvsCluster()}>
                          <table className={css.spTable}>
                            <thead>
                              <tr>
                                <td>{t('routes.apps')}</td>
                                <td>{t('overview.running')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              {appsToggleOpen && getSpApps().map((entry, index) => (
                                <tr key={entry.name}>
                                  <td>
                                  <Flex spacing={2}>
                                    <Avatar shape='square' src={ApiServerUrl +  entry.path + '/rest/appicon'} />
                                    <div>
                                      {entry.name}
                                      <small>{entry.path}</small>
                                    </div>
                                  </Flex>
                                  </td>
                                  <td>{entry.value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>  
                        </AppsSPCell>
                      }
                      </td>
                  </tr>
                </tbody>
              </table>
            </Flex>
          </Flex>
    </CardContent>
  </Card>
  )
}