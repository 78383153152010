import css from '../Sessions.module.scss'
import { SwingSession, SwingSessionStatusEnum } from "gen";
import { Tooltip, Status } from "components";
import { dateFormat } from "utils";
import { useTranslation } from 'react-i18next';
import { NamedTupleMember } from 'typescript';

export interface ISwingSession {
    connected?: boolean;
    detached?: boolean;
    disconnectedSince?: number;
    status?: SwingSessionStatusEnum;
}

interface IStatusCellProps {
    session: ISwingSession
}

export const StatusCell = ({session}:IStatusCellProps) => {
    const {t}= useTranslation()
    return (
        <>
            {
                (() => {

                    if(!session.hasOwnProperty('status')) {
                        return <Status className={css.Cell} color={'warning'} label={t('status.archived')} />
                    }

                    if (session.connected) {
                        return <Status className={css.Cell} color={'success'} label={t('status.connected')} />
                    }

                    switch (session.status) {
                        case 'RUNNING':
                            if (session.detached) {
                                return <Status className={css.Cell} color={'danger'} label={t('status.detached')} />
                            }
                            return <Tooltip content={t('sessions.disconnectedAt', {time:dateFormat(session.disconnectedSince!)})}>
                                <span><Status className={css.Cell} color={'danger'} label={t('status.disconnected')} /></span>
                            </Tooltip>
                        default:
                            if (session.detached) {
                                return <Status className={css.Cell} color={'resolved'} label={t('status.detached')} />
                            }
                            return <Status className={css.Cell} color={'closed'} label={session.status} />
                    }
                }).call(this)
            }
        </>
    )
}

