// core
import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import cx from 'classnames'
import { useTranslation } from 'react-i18next';
// components
import {Button, Popover, Flex, NoData, Coin } from 'components'
import FormiumConfig from './Partials/FormiumConfig'
import { TabButton } from './Partials/TabButton';
import { ManageApp } from './Partials/ManageApp/ManageApp';
import { DropItem } from './Partials/DropItem';
// routes
import { changeTitle } from '../routes';
// styles
import css from './Applications.module.scss'
// stores
import { useStore } from 'stores';
//utils
import {ApiServerUrl, hiddenServerConfigFields, hiddenWebConfigFields, toPath} from 'utils';


export const AppsSwing = observer(() => {
  const {permissionsStore, configStore, globalStore} = useStore();
  const { app } = useParams<{app:string}>();
  const [dropOpen, setDropOpen] = useState<boolean>(false)
  const {t, i18n} = useTranslation();
  const handleToggleExpand = useCallback(() => {
    if(globalStore.info?.cluster){
      setDropOpen(prev => !prev);
    } else {
      configStore.openTab(configStore.allOrOne)
    }
    
  }, [setDropOpen]);

  const handleDropdownClick = useCallback((key) => {
    configStore.openTab(key)
    setDropOpen(prev => !prev);
  }, [setDropOpen]);

  const getAppConfigBtnText = () => {
    if(!globalStore.isCluster || configStore.hasAllInactive || configStore.allOrOne ==='allID') {
      return t('config.appConfig')
    }

    return `${t('config.appConfig')} (${configStore.allOrOne})`
  };


  useEffect(() => {
    return () => {
      configStore.clearConfigData()
    }
  },[]);

  useEffect(() => {
    configStore.loadConfig(app);
    globalStore.updateApp(app);
    if(globalStore.toggleAddApp) {
      globalStore.setToggleAddApp(false)
    }
  },[app]);


  changeTitle(t('routes.appConfig'));

  return (
    <>
      <ManageApp app={app} />
      <Flex className={css.stickyBtnGroup} >
        <TabButton
          name='webConfig' 
          icon='web' 
          onClick={()=>configStore.openTab('webConfig')} 
          active={configStore.activeTab === 'webConfig'}
          >
          <div className={css.appTabText} onClick={()=>configStore.openTab('webConfig')}>{t('config.webConfig')}</div>
        </TabButton>
        <TabButton
          name='appConfig'
          icon='av_timer'
          onClick={handleToggleExpand}
          active={configStore.activeTab === 'allID' || configStore.isTab()}
        >
          <div className={css.appTabText} onClick={()=>configStore.openTab(configStore.allOrOne)}>
            {getAppConfigBtnText()}
          </div>
          <Popover
            backdrop
            className={cx(css.popover)}
            open={dropOpen}
            side="left"
            onBlur={handleToggleExpand}>
            <ul className={css.popList}>
              {
                !configStore.hideALL && 
                <li className={css.fitem} onClick={()=>handleDropdownClick('allID')}>
                  {t('config.allSessionConfig')}
                  {configStore.allConfigWasEdited && <Coin color='danger' size='small' className={css.coin}>  </Coin>}
                </li>
              }

              {!configStore.hasAllInactive &&
                <>
                <div className={css.appLabel}>{t('config.activeSP')}:</div>
                {configStore.activeTabs.map((item,i)=>(
                  <DropItem key={item.key} bLast={configStore.activeTabs.length === i+1} item={item} onClick={()=>handleDropdownClick(item.key)} />
                ))}
                </>
              }

              {!configStore.hasAllActive &&
                <>
                  <div className={css.appLabel}>{t('config.inactiveSP')}:</div>
                  {configStore.inactiveTabs.map((item,i)=>(
                    <DropItem key={item.key} bLast={configStore.inactiveTabs.length === i+1} item={item} onClick={()=>handleDropdownClick(item.key)} />
                  ))}
                </>
              }
            </ul>
          </Popover>
        </TabButton>
      </Flex>
      <div className={cx(css.appTabContent)}>
        { configStore.activeTab === 'webConfig' && 
            <FormiumConfig
              readonly={!permissionsStore.get('configSwingEdit')} 
              identify={app} 
              path={toPath(ApiServerUrl,app)} 
              value={configStore.config?.webConfig} 
              hide={hiddenWebConfigFields}
              translations={i18n.getDataByLanguage(i18n.language)?.translation}
              lang={i18n.language}
            />
        }
        { 
          configStore.activeTab === 'allID' && !configStore.hideALL && 
          <FormiumConfig
            readonly={!permissionsStore.get('configSwingEdit')} 
            identify={'allID'}
            path={toPath(ApiServerUrl,app)} 
            list={configStore.config?.appConfig}
            hide={hiddenServerConfigFields}
            translations={i18n.getDataByLanguage(i18n.language)?.translation}
            lang={i18n.language}
          />
        }
        {configStore.tabs.map((item)=>(
          <React.Fragment key={item.key}>
            {
              configStore.isTab(item.key) &&
              <FormiumConfig
                readonly={!permissionsStore.get('configSwingEdit')} 
                identify={item.key} 
                path={toPath(ApiServerUrl,app)} 
                value={configStore.config?.appConfig![item.key]} 
                istab={true}
                hide={hiddenServerConfigFields}
                translations={i18n.getDataByLanguage(i18n.language)?.translation}
                lang={i18n.language}
              />
            }
          </React.Fragment>
        ))}
        {
          configStore.hasAllInactive && configStore.activeTab !== 'webConfig' &&
          <NoData message={t('apps.noActiveSps')}>
            <Button icon={'refresh'} color={'neutral'} onClick={()=>configStore.loadConfig(app)} />
          </NoData>
        }
      </div>
      <Button.Group 
        className={cx(css.floatingButtons,{[css.activeApply]: configStore.activeApply})}
        buttons={
          [
            {label: t('general.apply'),size:'small', color:'blue', onClick: ()=>configStore.setConfig(app)},
            {label:t('general.reset'),size:'small', color:'white', onClick: ()=>configStore.resetConfig(app)}
          ]
        } 
      />
    </>
  )
})
