// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Overview,
    OverviewFromJSON,
    OverviewToJSON,
    VerboseModeRequest,
    VerboseModeRequestFromJSON,
    VerboseModeRequestToJSON,
} from '../models';

export interface OverviewApiRequestSessionPoolDrainModeRequest {
    id: string;
}

export interface OverviewApiRequestSessionPoolReloadWebsocketUrlsRequest {
    id: string;
}

export interface OverviewApiRequestSessionPoolResumeRequest {
    id: string;
}

export interface OverviewApiRequestSessionPoolStopRequest {
    id: string;
}

export interface OverviewApiSetVerboseModeRequest {
    verboseModeRequest: VerboseModeRequest;
}

/**
 * no description
 */
export class OverviewApi extends runtime.BaseAPI {

    /**
     * Info about cluster server, session pools and their stats
     * Get information about cluster overview
     */
    async getOverviewRaw(): Promise<runtime.ApiResponse<Overview>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewFromJSON(jsonValue));
    }

    /**
     * Info about cluster server, session pools and their stats
     * Get information about cluster overview
     */
    async getOverview(): Promise<Overview> {
        const response = await this.getOverviewRaw();
        return await response.value();
    }

    /**
     * Forces a reload of admin console websocket URLs
     * Reload AC Websocket URLs
     */
    async requestAdminConsoleReloadWebsocketUrlsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview/reloadWebsocketUrls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Forces a reload of admin console websocket URLs
     * Reload AC Websocket URLs
     */
    async requestAdminConsoleReloadWebsocketUrls(): Promise<void> {
        await this.requestAdminConsoleReloadWebsocketUrlsRaw();
    }

    /**
     * Requests SessionPool to turn into drain mode
     * Request drain mode
     */
    async requestSessionPoolDrainModeRaw(requestParameters: OverviewApiRequestSessionPoolDrainModeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestSessionPoolDrainMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview/sessionpool/drain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests SessionPool to turn into drain mode
     * Request drain mode
     */
    async requestSessionPoolDrainMode(requestParameters: OverviewApiRequestSessionPoolDrainModeRequest): Promise<void> {
        await this.requestSessionPoolDrainModeRaw(requestParameters);
    }

    /**
     * Forces a reload of session pool websocket URLs
     * Reload SP Websocket URLs
     */
    async requestSessionPoolReloadWebsocketUrlsRaw(requestParameters: OverviewApiRequestSessionPoolReloadWebsocketUrlsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestSessionPoolReloadWebsocketUrls.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview/sessionpool/reloadWebsocketUrls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Forces a reload of session pool websocket URLs
     * Reload SP Websocket URLs
     */
    async requestSessionPoolReloadWebsocketUrls(requestParameters: OverviewApiRequestSessionPoolReloadWebsocketUrlsRequest): Promise<void> {
        await this.requestSessionPoolReloadWebsocketUrlsRaw(requestParameters);
    }

    /**
     * Requests SessionPool resume
     * Request resume
     */
    async requestSessionPoolResumeRaw(requestParameters: OverviewApiRequestSessionPoolResumeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestSessionPoolResume.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview/sessionpool/resume/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests SessionPool resume
     * Request resume
     */
    async requestSessionPoolResume(requestParameters: OverviewApiRequestSessionPoolResumeRequest): Promise<void> {
        await this.requestSessionPoolResumeRaw(requestParameters);
    }

    /**
     * Requests SessionPool stop
     * Request stop
     */
    async requestSessionPoolStopRaw(requestParameters: OverviewApiRequestSessionPoolStopRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestSessionPoolStop.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/overview/sessionpool/stop/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests SessionPool stop
     * Request stop
     */
    async requestSessionPoolStop(requestParameters: OverviewApiRequestSessionPoolStopRequest): Promise<void> {
        await this.requestSessionPoolStopRaw(requestParameters);
    }

    /**
     * Requests Server or session pool to enable/disable verbose logging
     * Set verbose logging
     */
    async setVerboseModeRaw(requestParameters: OverviewApiSetVerboseModeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verboseModeRequest === null || requestParameters.verboseModeRequest === undefined) {
            throw new runtime.RequiredError('verboseModeRequest','Required parameter requestParameters.verboseModeRequest was null or undefined when calling setVerboseMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/overview/verbose`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerboseModeRequestToJSON(requestParameters.verboseModeRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests Server or session pool to enable/disable verbose logging
     * Set verbose logging
     */
    async setVerboseMode(requestParameters: OverviewApiSetVerboseModeRequest): Promise<void> {
        await this.setVerboseModeRaw(requestParameters);
    }

}
