import { showToastOk, showToastErr } from './../components/basic/Toast/Toast';
import { VerboseModeRequestTypeEnum } from 'gen/models/VerboseModeRequest';
import { overviewApi, getAuthenticationHeaders, handleRestError} from 'utils';
import {AccessService} from './AccessHandlerService';
import i18n from 'i18n';


class OverviewRestService {
  
  private static _instance:OverviewRestService = new OverviewRestService();
  
  
  constructor() {
    if(OverviewRestService._instance){
      throw new Error("Error: Instantiation failed: Use OverviewRestService.getInstance() instead of new.");
    }
    OverviewRestService._instance = this;
  }
  
  public static getInstance():OverviewRestService
  {
    return OverviewRestService._instance;
  }

  async getOverview() {
    try {
      const Api = overviewApi(getAuthenticationHeaders(await this.getAccessToken()));
      return await Api.getOverview();
    } catch (e) {
        handleRestError(i18n.t('error.getOverview'),e)
    }
  }

  async setVerboseMode(id: string, enable: boolean, type: 'sp' | 'server') {
    try {
      const Api = overviewApi(getAuthenticationHeaders(await this.getAccessToken()));
      await Api.setVerboseMode({
        verboseModeRequest: {
          enable, id, type: type === 'server' ? VerboseModeRequestTypeEnum.SERVER : VerboseModeRequestTypeEnum.SESSIONPOOL
        }
      });
      showToastOk(i18n.t("overview.verboseMode")+' '+ (enable ? i18n.t('general.enabled'):i18n.t('general.disabled')))
    } catch (e) {
      handleRestError(i18n.t('error.updateVerboseFailed'),e)
    }
  }

  async requestSessionPoolDrainMode(id: string, message = true) {
    try {
      const Api = overviewApi(getAuthenticationHeaders(await this.getAccessToken()));
      let promise = await Api.requestSessionPoolDrainMode({id});
      if(message) {
        showToastOk(i18n.t('ok.spDrainActivated'))
      }
      return promise;
    } catch (e) {
      handleRestError(i18n.t('error.cannotSetDrain'),e)
    }
  }

  async requestSessionPoolResume(id: string, message = true) {
    try {
      const Api = overviewApi(getAuthenticationHeaders(await this.getAccessToken()));
      let promise = await Api.requestSessionPoolResume({id});
      if(message) {
        showToastOk(i18n.t('ok.spResumed'))
      }
      return promise;
    } catch (e) {
      handleRestError(i18n.t('error.cannotResumeSP'),e)
    }
  }

  async requestSessionPoolStop(id: string, message = true) {
    try {
      const Api = overviewApi(getAuthenticationHeaders(await this.getAccessToken()));
      let promise = await Api.requestSessionPoolStop({id});
      if (message) {
        showToastOk(i18n.t('ok.spStopped'))
      }
      return promise;
    } catch (e) {
      handleRestError(i18n.t('error.cannotStopSP'),e)
    }
  }

  private async getAccessToken() {
    return await AccessService.getAccessToken();
  }
  
}

export const OverviewService = OverviewRestService.getInstance();