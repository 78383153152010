// .core
import React, {useState, useEffect} from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
// Services 
import { ConfigService } from 'services'
// components
import { Button, Dialog, Card, AvatarWithTitle, Flex, StatusBar, showToastOk } from 'components'
// styles
import css from './ManageApp.module.scss'
// utils
import { ApiServerUrl } from 'utils'
// stores
import { useStore } from 'stores'

interface IManageAppProps {
  app:string,
  className?: string,
  onClick?(): void
}

export const ManageApp = observer(function ManageApp({
  app,
  className,
  onClick,
}: IManageAppProps) {
  const {permissionsStore, globalStore} = useStore();
  const info = globalStore.info!;
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [stoppable, setStoppable] = useState<boolean>(false);
  const [startable, setStartable] = useState<boolean>(false);
  const [confirmKillallDialogOpen, setConfirmKillallDialogOpen] = useState<boolean>(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState<boolean>(false);
  const history = useHistory()
  const {t} = useTranslation()
  const handleStop = ()=> {
    if(info.runningInstances !== 0) {
      setConfirmKillallDialogOpen(true)
    } else {
      stop();
    }
  }

  const loadInfo = (appPath:string) => {
    globalStore.loadInfo(appPath)
    .then(()=>{
      globalStore.clearInterval();
      setStoppable(globalStore.info?.enabled!)
      setStartable(!globalStore.info?.enabled)
    })
    .then(()=> {
      globalStore.setInterval(() => {
        setLoadingMessage('');
        loadInfo(appPath);
      }, 5000)
    })
    .catch(()=>{
      globalStore.clearInterval();
    })
  }

  const toggle = (bopen:boolean) => {
    setConfirmKillallDialogOpen(bopen);
  };
  const start = () => {
    setLoadingMessage('Requesting Start');
    setStoppable(false);
    setStartable(false);
    ConfigService
      .start(app?app:'')
      .then(() => {
        loadInfo(app)
        showToastOk(t('ok.successStartApp'));
      })
  }
  const stop = () => {
    setLoadingMessage('Requesting Stop');
    setStoppable(false);
    setStartable(false);
    ConfigService
    .stop(app?app:'')
    .then(() => {
      loadInfo(app)
      showToastOk(t('ok.successStopApp'));
      if(confirmKillallDialogOpen) {
        setConfirmKillallDialogOpen(false);
      }
    })
  }

  const remove = () => {
    setLoadingMessage('Uninstalling app');
    setStoppable(false);
    setStartable(false);
    ConfigService
      .remove(app)
      .then(() => {
        globalStore.removePath(app)
        showToastOk(t('ok.successRemoveApp'));
        setStartable(true);
        history.push('/apps')
      })
  }

  useEffect(
    () => {
      setLoadingMessage('');
      loadInfo(app);
      return () => globalStore.clearInterval();
    },
    [app]
  )
  
  return (
    <Card className={cx(css.root, className)} onClick={onClick}>
      <Flex style={{padding: '0 1rem'}}>
        <Flex bColumn className={css.AvatarCol} flex={2}>
          <AvatarWithTitle
            size='medium'
            src={ApiServerUrl + "/" + app + '/rest/appicon'}
            title={info?.name}
            className={css.manageApp}
          />
        </Flex>
        <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'} >
          <span className={css.label}>{t('general.status')}:</span>
          <StatusBar status={globalStore.info?.status!} loadMessage={loadingMessage} />
        </Flex>
        <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'}>
          <span className={css.label}>{t('apps.path')}:</span>
          <a href={info?.url} target='_blank' rel="noreferrer">{info?.path}</a>
        </Flex>
        <Flex flex={2} justifyContent={'flex-end'} alignItems={'center'}>
          { stoppable && permissionsStore.get('stop') && <Button color={'danger'} size={'small'} label={t('apps.disable')} onClick={handleStop}/>}
          { startable && permissionsStore.get('start') && <Button label={t('apps.enable')} size={'small'} style={{marginRight:'6px'}} onClick={start}/>}
          { startable && permissionsStore.get('remove') && <Button color={'danger'} size={'small'} label={t('apps.remove')} onClick={()=>setConfirmRemoveDialogOpen(b=>!b)}/>}
        </Flex>
      </Flex>
      <Dialog
        bOpen={confirmKillallDialogOpen}
        title={t("sessions.warning")}
        footer={true}
        onToggle={toggle}
        onConfirm={stop}
      >
        {
          globalStore.info?.runningInstances === 1 ?
          t('apps.runningInstanceKillQ'):
          t('apps.runningInstancesKillQ',{runningInstances:globalStore.info?.runningInstances})
        }
      </Dialog>
      <Dialog
        bOpen={confirmRemoveDialogOpen}
        title={t('apps.remove')+' '+app}
        footer={true}
        onToggle={(b:boolean)=>setConfirmRemoveDialogOpen(b)}
        onConfirm={remove}
      >
        {t('apps.AreuSureRemove', {app})}
      </Dialog>
    </Card>
  )
})
