import React from "react";
import css from '../Sessions.module.scss';
import cx from 'classnames';
import { SwingSession } from "gen";
// OS
import {ReactComponent as Windows} from 'assets/icons/svg/windows.svg'
import {ReactComponent as Apple} from 'assets/icons/svg/apple.svg'
import {ReactComponent as Android} from 'assets/icons/svg/android.svg'
import {ReactComponent as Linux} from 'assets/icons/svg/linux.svg'
// Browsers
import {ReactComponent as Chrome} from 'assets/icons/svg/chrome.svg'
import {ReactComponent as Firefox} from 'assets/icons/svg/firefox.svg'
import {ReactComponent as Opera} from 'assets/icons/svg/opera.svg'
import {ReactComponent as Safari} from 'assets/icons/svg/safari.svg'
import {ReactComponent as Edge} from 'assets/icons/svg/edge.svg'



interface IUserCellProps {
    session:SwingSession,
}

export const UserCell = ({session}:IUserCellProps) => {

    const getOsIcon = (os: string) => {
        if (os != null) {
          if (os === "Windows") {
            return <Windows width={12} title={'windows'} />;
          }
          if (os === "Mac" || os === "IPhone") {
            return <Apple width={12} title={'apple'} />;
          }
          if (os === "Linux") {
            return <Linux width={12} title={'linux'} />;
        }
        if (os === "Android") {
              return <Android width={12} title={'android'} />;
          }
          return "";
        }
    }
      
    const getBrowserIcon =(b: string) => {
    if (b != null) {
        if (b.indexOf("IE") >= 0) {
            return <Edge width={12} title={'internet explorer'} />;
        }
        b = b.toLowerCase();
        if (b.indexOf("edge") >= 0) {
            return <Edge width={12} title={'Edge'} />;
    }
    if (b.indexOf("chrome") >= 0) {
            return <Chrome width={12} title={'Chrome'} />;
        }
        if (b.indexOf("firefox") >= 0) {
            return <Firefox width={12} title={'Firefox'} />;
        }
        if (b.indexOf("safari") >= 0) {
            return <Safari width={12} title={'Safari'} />;
        }
        if (b.indexOf("opera") >= 0) {
            return <Opera width={12} title={'opera'} />;
        }
        return "";
    }
    }

    return (
        <div className={cx(css.Cell,css.userCell)}>
            {session?.user ?? 'N/A' }
            {getOsIcon(session?.userOs!)}
            {getBrowserIcon(session?.userBrowser!)}
        </div>
    )
}