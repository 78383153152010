// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LogRequest,
    LogRequestFromJSON,
    LogRequestToJSON,
    LogResponse,
    LogResponseFromJSON,
    LogResponseToJSON,
    LogsOverview,
    LogsOverviewFromJSON,
    LogsOverviewToJSON,
} from '../models';

export interface ViewLogsApiDownloadAdminConsoleLogsRequest {
    token: string;
}

export interface ViewLogsApiDownloadServerLogsRequest {
    id: string;
    type: string;
    token: string;
}

export interface ViewLogsApiGetAdminConsoleLogsRequest {
    logRequest: LogRequest;
}

export interface ViewLogsApiGetServerLogsRequest {
    id: string;
    type: string;
    logRequest: LogRequest;
}

export interface ViewLogsApiGetSessionLogsRequest {
    sessionPoolId: string;
    instanceId: string;
    appPath: string;
    logRequest: LogRequest;
}

export interface ViewLogsApiGetSessionPoolLogsRequest {
    id: string;
    logRequest: LogRequest;
}

/**
 * no description
 */
export class ViewLogsApi extends runtime.BaseAPI {

    /**
     * Downloads log files as a single zip file
     * Download Admin Console logs
     */
    async downloadAdminConsoleLogsRaw(requestParameters: ViewLogsApiDownloadAdminConsoleLogsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling downloadAdminConsoleLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/logs/console/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Downloads log files as a single zip file
     * Download Admin Console logs
     */
    async downloadAdminConsoleLogs(requestParameters: ViewLogsApiDownloadAdminConsoleLogsRequest): Promise<Blob> {
        const response = await this.downloadAdminConsoleLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Downloads log files as a single zip file
     * Download Server Logs
     */
    async downloadServerLogsRaw(requestParameters: ViewLogsApiDownloadServerLogsRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadServerLogs.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling downloadServerLogs.');
        }

        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling downloadServerLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/logs/server/{id}/{type}/{token}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Downloads log files as a single zip file
     * Download Server Logs
     */
    async downloadServerLogs(requestParameters: ViewLogsApiDownloadServerLogsRequest): Promise<object> {
        const response = await this.downloadServerLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns requested range of admin console logs
     * Request Admin Console Log Content
     */
    async getAdminConsoleLogsRaw(requestParameters: ViewLogsApiGetAdminConsoleLogsRequest): Promise<runtime.ApiResponse<LogResponse>> {
        if (requestParameters.logRequest === null || requestParameters.logRequest === undefined) {
            throw new runtime.RequiredError('logRequest','Required parameter requestParameters.logRequest was null or undefined when calling getAdminConsoleLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/logs/console`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogRequestToJSON(requestParameters.logRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogResponseFromJSON(jsonValue));
    }

    /**
     * Returns requested range of admin console logs
     * Request Admin Console Log Content
     */
    async getAdminConsoleLogs(requestParameters: ViewLogsApiGetAdminConsoleLogsRequest): Promise<LogResponse> {
        const response = await this.getAdminConsoleLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns overview of log sources
     * Get logs overview
     */
    async getLogsOverviewRaw(): Promise<runtime.ApiResponse<LogsOverview>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/logs/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogsOverviewFromJSON(jsonValue));
    }

    /**
     * Returns overview of log sources
     * Get logs overview
     */
    async getLogsOverview(): Promise<LogsOverview> {
        const response = await this.getLogsOverviewRaw();
        return await response.value();
    }

    /**
     * Returns requested range of server logs from defined log type
     * Request Server Log Content
     */
    async getServerLogsRaw(requestParameters: ViewLogsApiGetServerLogsRequest): Promise<runtime.ApiResponse<LogResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServerLogs.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getServerLogs.');
        }

        if (requestParameters.logRequest === null || requestParameters.logRequest === undefined) {
            throw new runtime.RequiredError('logRequest','Required parameter requestParameters.logRequest was null or undefined when calling getServerLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/logs/server/{id}/{type}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogRequestToJSON(requestParameters.logRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogResponseFromJSON(jsonValue));
    }

    /**
     * Returns requested range of server logs from defined log type
     * Request Server Log Content
     */
    async getServerLogs(requestParameters: ViewLogsApiGetServerLogsRequest): Promise<LogResponse> {
        const response = await this.getServerLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns requested range of session logs
     * Request Session Log Content
     */
    async getSessionLogsRaw(requestParameters: ViewLogsApiGetSessionLogsRequest): Promise<runtime.ApiResponse<LogResponse>> {
        if (requestParameters.sessionPoolId === null || requestParameters.sessionPoolId === undefined) {
            throw new runtime.RequiredError('sessionPoolId','Required parameter requestParameters.sessionPoolId was null or undefined when calling getSessionLogs.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling getSessionLogs.');
        }

        if (requestParameters.appPath === null || requestParameters.appPath === undefined) {
            throw new runtime.RequiredError('appPath','Required parameter requestParameters.appPath was null or undefined when calling getSessionLogs.');
        }

        if (requestParameters.logRequest === null || requestParameters.logRequest === undefined) {
            throw new runtime.RequiredError('logRequest','Required parameter requestParameters.logRequest was null or undefined when calling getSessionLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sessionPoolId !== undefined) {
            queryParameters['sessionPoolId'] = requestParameters.sessionPoolId;
        }

        if (requestParameters.instanceId !== undefined) {
            queryParameters['instanceId'] = requestParameters.instanceId;
        }

        if (requestParameters.appPath !== undefined) {
            queryParameters['appPath'] = requestParameters.appPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/logs/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogRequestToJSON(requestParameters.logRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogResponseFromJSON(jsonValue));
    }

    /**
     * Returns requested range of session logs
     * Request Session Log Content
     */
    async getSessionLogs(requestParameters: ViewLogsApiGetSessionLogsRequest): Promise<LogResponse> {
        const response = await this.getSessionLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns requested range of session pool logs
     * Request Session Pool Log Content
     */
    async getSessionPoolLogsRaw(requestParameters: ViewLogsApiGetSessionPoolLogsRequest): Promise<runtime.ApiResponse<LogResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSessionPoolLogs.');
        }

        if (requestParameters.logRequest === null || requestParameters.logRequest === undefined) {
            throw new runtime.RequiredError('logRequest','Required parameter requestParameters.logRequest was null or undefined when calling getSessionPoolLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/logs/sessionpool/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogRequestToJSON(requestParameters.logRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogResponseFromJSON(jsonValue));
    }

    /**
     * Returns requested range of session pool logs
     * Request Session Pool Log Content
     */
    async getSessionPoolLogs(requestParameters: ViewLogsApiGetSessionPoolLogsRequest): Promise<LogResponse> {
        const response = await this.getSessionPoolLogsRaw(requestParameters);
        return await response.value();
    }

}
