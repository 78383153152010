// .core
import { FC, useEffect } from 'react';
// services
import { AccessService } from 'services';
import { ApiContextPath } from 'utils';
import { useStore } from 'stores';
// styles
import './styles/index.scss';
// router
import { BrowserRouter as Router } from 'react-router-dom';
// components
import { Loader, Toast } from 'components'
import { Layout } from 'components/layout/Layout';
import { observer } from 'mobx-react';

const App : FC = observer( () => {
  const {globalStore, permissionsStore} = useStore();
  const appInit = () => {
    AccessService.healthCheck().then((status) => {
      if(status === 'error') {
        globalStore.toggleErrorView(true,{header:'error.healthFailed', bLoginBtn:false, subtitle:'error.healthFailedSubtitle'});
      } else {
        AccessService.getAccessToken().then((token) => {
          if (token && token.length) {
            permissionsStore.reload();
            globalStore.loadAvailableLangs();
            globalStore.loadServerUrl();
            globalStore.loadCustomizationConfig();
          }
        });
      }
    });
  }
  const shouldShowLoader = () => {
    return permissionsStore.checkingPermissions && !globalStore.showErrorView
  }

  useEffect(()=>{
    appInit();
  },[])

  if(shouldShowLoader()) {
    return (
      <Loader.Branded/>
    );
  }
  return (
    <>
      <Router basename={ApiContextPath}>
        <Layout />
      </Router>
      <Toast />
    </>
  );
});

export default App;