// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveSessionsInfo
 */
export interface ActiveSessionsInfo {
    /**
     * 
     * @type {number}
     * @memberof ActiveSessionsInfo
     */
    connected?: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveSessionsInfo
     */
    disconnected?: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveSessionsInfo
     */
    total?: number;
}

export function ActiveSessionsInfoFromJSON(json: any): ActiveSessionsInfo {
    return ActiveSessionsInfoFromJSONTyped(json, false);
}

export function ActiveSessionsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveSessionsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connected': !exists(json, 'connected') ? undefined : json['connected'],
        'disconnected': !exists(json, 'disconnected') ? undefined : json['disconnected'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function ActiveSessionsInfoToJSON(value?: ActiveSessionsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connected': value.connected,
        'disconnected': value.disconnected,
        'total': value.total,
    };
}


