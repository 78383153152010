// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Permissions map. * key - string * value - boolean 
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * 
     * @type {string}
     * @memberof Permissions
     */
    user?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    overview?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    configView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    configSwingEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    sessions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    configEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    start?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    stop?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    remove?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    create?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    logsView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    startRecording?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    playbackRecording?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    startMirrorView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    requestThreadDump?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    getThreadDump?: boolean;
}

export function PermissionsFromJSON(json: any): Permissions {
    return PermissionsFromJSONTyped(json, false);
}

export function PermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : json['user'],
        'overview': !exists(json, 'overview') ? undefined : json['overview'],
        'configView': !exists(json, 'configView') ? undefined : json['configView'],
        'configSwingEdit': !exists(json, 'configSwingEdit') ? undefined : json['configSwingEdit'],
        'sessions': !exists(json, 'sessions') ? undefined : json['sessions'],
        'configEdit': !exists(json, 'configEdit') ? undefined : json['configEdit'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'stop': !exists(json, 'stop') ? undefined : json['stop'],
        'remove': !exists(json, 'remove') ? undefined : json['remove'],
        'create': !exists(json, 'create') ? undefined : json['create'],
        'logsView': !exists(json, 'logsView') ? undefined : json['logsView'],
        'startRecording': !exists(json, 'startRecording') ? undefined : json['startRecording'],
        'playbackRecording': !exists(json, 'playbackRecording') ? undefined : json['playbackRecording'],
        'startMirrorView': !exists(json, 'startMirrorView') ? undefined : json['startMirrorView'],
        'requestThreadDump': !exists(json, 'requestThreadDump') ? undefined : json['requestThreadDump'],
        'getThreadDump': !exists(json, 'getThreadDump') ? undefined : json['getThreadDump'],
    };
}

export function PermissionsToJSON(value?: Permissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'overview': value.overview,
        'configView': value.configView,
        'configSwingEdit': value.configSwingEdit,
        'sessions': value.sessions,
        'configEdit': value.configEdit,
        'start': value.start,
        'stop': value.stop,
        'remove': value.remove,
        'create': value.create,
        'logsView': value.logsView,
        'startRecording': value.startRecording,
        'playbackRecording': value.playbackRecording,
        'startMirrorView': value.startMirrorView,
        'requestThreadDump': value.requestThreadDump,
        'getThreadDump': value.getThreadDump,
    };
}


