// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServerInfo,
    ServerInfoFromJSON,
    ServerInfoFromJSONTyped,
    ServerInfoToJSON,
    SessionPoolInfo,
    SessionPoolInfoFromJSON,
    SessionPoolInfoFromJSONTyped,
    SessionPoolInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface Overview
 */
export interface Overview {
    /**
     * 
     * @type {Array<ServerInfo>}
     * @memberof Overview
     */
    servers?: Array<ServerInfo>;
    /**
     * 
     * @type {Array<SessionPoolInfo>}
     * @memberof Overview
     */
    sessionPools?: Array<SessionPoolInfo>;
}

export function OverviewFromJSON(json: any): Overview {
    return OverviewFromJSONTyped(json, false);
}

export function OverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Overview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'servers': !exists(json, 'servers') ? undefined : ((json['servers'] as Array<any>).map(ServerInfoFromJSON)),
        'sessionPools': !exists(json, 'sessionPools') ? undefined : ((json['sessionPools'] as Array<any>).map(SessionPoolInfoFromJSON)),
    };
}

export function OverviewToJSON(value?: Overview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'servers': value.servers === undefined ? undefined : ((value.servers as Array<any>).map(ServerInfoToJSON)),
        'sessionPools': value.sessionPools === undefined ? undefined : ((value.sessionPools as Array<any>).map(SessionPoolInfoToJSON)),
    };
}


