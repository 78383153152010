import { loginApi, ApiServerUrl, handleRestError, getSecuritySuffix } from 'utils'
import { ConfigService } from 'services'
import i18n from 'i18n'

class AccessHandlerService {

  private accessToken: string | null | undefined;
  private refreshing = false;
 
  private static _instance:AccessHandlerService = new AccessHandlerService();

  constructor() {
      if(AccessHandlerService._instance){
          throw new Error("Error: Instantiation failed: Use AccessHandlerService.getInstance() instead of new.");
      }
      AccessHandlerService._instance = this;
      this.init();
  }

  static getInstance():AccessHandlerService
  {
      return AccessHandlerService._instance;
  }

  async getAccessToken(force?: boolean) {
    if (!this.accessToken || this.accessToken == null || !this.accessToken.length || force) {
      if (this.refreshing) {
        await this.refreshingFinished();
        
        if (this.accessToken && this.accessToken != null && this.accessToken.length) {
          return this.accessToken;
        }
      }
      
      await this.refreshToken();
    }
    
    return this.accessToken || "";
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  clearTokens() {
    this.accessToken = null!;
  }

  async displayLogin() {
    const params: string[] = [];
    const urlParams = new URLSearchParams(window.location.search);
    const securityToken = urlParams.get('securityToken');
    if (securityToken !== undefined && securityToken != null) {
      params.push('securityToken=' + securityToken);
    }
    const realm = urlParams.get('realm');
    if (realm !== undefined && realm != null) {
      params.push('realm=' + realm);
    }

    params.push('securitySuffix=' + getSecuritySuffix());

    const serverUrl = await ConfigService.getServerUrl();
    if(serverUrl) {
      window.location.href = serverUrl + '/adminConsoleLogin.html?' + params.join('&');
    }
  }

  async logout() {
    this.clearTokens();
    window.location.href = ApiServerUrl + '/rest/logout?X-webswing-securitySuffix=' + getSecuritySuffix();
  }

  async healthCheck() {
    try {
      const appApi = loginApi({});
      const result = await appApi.health();
      return result || "error";
	  } catch (e) {
        handleRestError(i18n.t('error.healthCheck'), e)
    }
    return "error";
  }
  
  private init() {
    setInterval(() => {
      this.getAccessToken(true)
    }, 60000);
  }

  private async refreshingFinished() {
    let count = 0;
    while (this.refreshing && count < 100) {
      count++;
      await new Promise(r => setTimeout(r, 500));
    }
  }

  private async refreshToken() {
    this.refreshing = true;
    
	  try {
      const appApi = loginApi({});
      const result = await appApi.refreshToken().catch(() => {});
      
      if (result && result.accessToken && result.accessToken != null && result.accessToken.length) {
        this.setAccessToken(result.accessToken);
      } else {
        // else login
        this.clearTokens();
        this.displayLogin();
      }
	  } catch (e) {
        handleRestError(i18n.t('error.refreshToken'),e)
	  } finally {
      this.refreshing = false;
    }
  }

}

export const AccessService = AccessHandlerService.getInstance();