// core
import React, { ReactNode, useEffect, useRef, useState } from 'react'

import classnames from 'classnames'

// components
import { Button, IComponentProps, Icon, Tooltip } from 'components'
import { IButtonDefaultProps } from '../Button/variants/ButtonDefault/ButtonDefault'

// style
import css from './Dropdown.module.scss'

export interface IBtndropProps  extends IButtonDefaultProps {
  buttons?: IButtonDefaultProps[]
  shouldShow?: boolean | null | string
  sDisabled?:string
}

export interface IDropdownProps extends IComponentProps {
  children?: ReactNode
  active?: string;
  color?: string;
  size?: string;
  position?:'left'|'right';
  buttons: IBtndropProps[]
}

/**
 * Count badge displayed at the top corner for any component which it wraps
 */
export const Dropdown = ({
  color='',
  size='',
  position='left',
  active='',
  buttons,
  children,
  className,
  classes = {},
}: IDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e:any) => {
      if (!dropdownRef.current?.contains(e.target)) {
          setClickedOutside(true);
          setOpen(false)
      }
  };

  const handleClickInside = () => setClickedOutside(false);
  const handleClick = (btn:any) => {
    if(btn.sDisabled) {
      return
    }
    setClickedOutside(true);
    setOpen(false)
    btn.onClick();
  };

  const shouldShow = (btn:IBtndropProps) => {
    let isNotActive = true
    if (active) {
      isNotActive = active !== btn.label
    }
    return (null == btn.shouldShow || btn.shouldShow) && isNotActive
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div
      className={classnames(css.root, className)}
      onClick={handleClickInside}
      ref={dropdownRef} 
      >
      <Button.UI color={color} size={size} onClick={()=>setOpen(!open)}>
        {children ? children :<>{active} <Icon name={open ? 'expand_less': 'expand_more'} /></> }
      </Button.UI>
      <nav 
        className={classnames(css.menu,css[position], {[css.menuActive]: open})}
        >
          {
            buttons.filter(btn=>shouldShow(btn)).map((btn)=> (
              <React.Fragment key={btn.label}>
                <Button.UI
                className={classnames(
                  css.button,
                  btn.className,
                  {
                    [css[btn.color!]]:btn.color
                  },
                  {
                    [css.icon]: btn.icon,
                  },
                  {
                    [css.disabled]: btn.sDisabled,
                  }
                )}
                style={btn.style}
                type={btn.type || 'button'}
                bDisabled={btn.bDisabled}
                >
                  {
                    btn.sDisabled ?
                    <Tooltip content={btn.sDisabled}>
                      <span className={classnames(css.btnText, {[css.fullW]: !btn.buttons})} onClick={()=>handleClick(btn)}>
                        <ItemContent icon={btn.icon} label={btn.label} />
                      </span>
                    </Tooltip>
                    :
                    <span className={classnames(css.btnText, {[css.fullW]: !btn.buttons})} onClick={()=>handleClick(btn)}>
                      <ItemContent icon={btn.icon} label={btn.label} />
                    </span>
                  }
                  {btn.buttons && <Icon name={'expand_more'} className={css.expandIcon} onClick={()=>setSubOpen(!subOpen)} />}
              </Button.UI>
              {
                subOpen && btn.buttons?.map((btn)=> (
                  <Button.UI
                    key={btn.label}
                    className={classnames(
                      css.button,
                      css.sub,
                      btn.className,
                    )}
                    style={btn.style}
                    type={btn.type || 'button'}
                    onClick={()=>handleClick(btn)}
                  >
                    <span className={css.btnText}>
                      {btn.label}
                    </span>
                  </Button.UI>
                ))
              }
              </React.Fragment>
            ))
          }
      </nav>
    </div>
  )
}

const ItemContent = ({icon,label}:{icon?: React.ReactNode, label?:string}) => (
  <>
    {icon && typeof icon === 'string' ? (
      <Icon name={icon} />
    ) : null}
    {label}
  </>
)
