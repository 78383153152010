// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Config,
    ConfigFromJSON,
    ConfigToJSON,
} from '../models';

export interface ManageConfigurationApiGetMetaRequest {
    config: Config;
}

export interface ManageConfigurationApiResolveRequest {
    type: ResolveTypeEnum;
    resolve: string;
    sessionPoolId?: string;
}

export interface ManageConfigurationApiSaveConfigRequest {
    config: Config;
}

export interface ManageConfigurationApiSearchVariablesRequest {
    type: SearchVariablesTypeEnum;
    search: string;
    sessionPoolId?: string;
}

/**
 * no description
 */
export class ManageConfigurationApi extends runtime.BaseAPI {

    /**
     * Returns the saved configuration described by Meta-objects
     * Get Config
     */
    async getConfigRaw(): Promise<runtime.ApiResponse<Config>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigFromJSON(jsonValue));
    }

    /**
     * Returns the saved configuration described by Meta-objects
     * Get Config
     */
    async getConfig(): Promise<Config> {
        const response = await this.getConfigRaw();
        return await response.value();
    }

    /**
     * Operation accepts a json object which is annotated with meta information required to generate configuration web UI
     * Describe Configuration
     */
    async getMetaRaw(requestParameters: ManageConfigurationApiGetMetaRequest): Promise<runtime.ApiResponse<Config>> {
        if (requestParameters.config === null || requestParameters.config === undefined) {
            throw new runtime.RequiredError('config','Required parameter requestParameters.config was null or undefined when calling getMeta.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/metaConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigToJSON(requestParameters.config),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigFromJSON(jsonValue));
    }

    /**
     * Operation accepts a json object which is annotated with meta information required to generate configuration web UI
     * Describe Configuration
     */
    async getMeta(requestParameters: ManageConfigurationApiGetMetaRequest): Promise<Config> {
        const response = await this.getMetaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Replaces all variables in a given string and returns the resolved string
     * Resolve Variables.
     */
    async resolveRaw(requestParameters: ManageConfigurationApiResolveRequest): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling resolve.');
        }

        if (requestParameters.resolve === null || requestParameters.resolve === undefined) {
            throw new runtime.RequiredError('resolve','Required parameter requestParameters.resolve was null or undefined when calling resolve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.resolve !== undefined) {
            queryParameters['resolve'] = requestParameters.resolve;
        }

        if (requestParameters.sessionPoolId !== undefined) {
            queryParameters['sessionPoolId'] = requestParameters.sessionPoolId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/variables/resolve/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Replaces all variables in a given string and returns the resolved string
     * Resolve Variables.
     */
    async resolve(requestParameters: ManageConfigurationApiResolveRequest): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.resolveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Saves the submitted configuration to persistent store
     * Set Config
     */
    async saveConfigRaw(requestParameters: ManageConfigurationApiSaveConfigRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.config === null || requestParameters.config === undefined) {
            throw new runtime.RequiredError('config','Required parameter requestParameters.config was null or undefined when calling saveConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigToJSON(requestParameters.config),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Saves the submitted configuration to persistent store
     * Set Config
     */
    async saveConfig(requestParameters: ManageConfigurationApiSaveConfigRequest): Promise<Array<string>> {
        const response = await this.saveConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * **Return list of first 10 variables which start with, or contain given string, the search sequence.**   Result contains only first 10 found variables whose start, or contain search sequence. Result is made, at first, from alphabetically sorted group of found variables, whose names start with search sequence. At second, following another sorted group of found variables, whose names contains search sequence. 
     * Find Variables
     */
    async searchVariablesRaw(requestParameters: ManageConfigurationApiSearchVariablesRequest): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling searchVariables.');
        }

        if (requestParameters.search === null || requestParameters.search === undefined) {
            throw new runtime.RequiredError('search','Required parameter requestParameters.search was null or undefined when calling searchVariables.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sessionPoolId !== undefined) {
            queryParameters['sessionPoolId'] = requestParameters.sessionPoolId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/variables/search/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * **Return list of first 10 variables which start with, or contain given string, the search sequence.**   Result contains only first 10 found variables whose start, or contain search sequence. Result is made, at first, from alphabetically sorted group of found variables, whose names start with search sequence. At second, following another sorted group of found variables, whose names contains search sequence. 
     * Find Variables
     */
    async searchVariables(requestParameters: ManageConfigurationApiSearchVariablesRequest): Promise<{ [key: string]: string; }> {
        const response = await this.searchVariablesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ResolveTypeEnum {
    Basic = 'Basic',
    SwingInstance = 'SwingInstance',
    SwingApp = 'SwingApp'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchVariablesTypeEnum {
    Basic = 'Basic',
    SwingInstance = 'SwingInstance',
    SwingApp = 'SwingApp'
}
