// .core
import React, { useState } from 'react'
// components
import { Chip, Status, Dialog } from 'components'
// styles
import css from './StatusBar.module.scss'
// utils
import { observer } from 'mobx-react'
import { useStore } from 'stores'
import { InstanceManagerStatus, InstanceManagerStatusStatusEnum } from 'gen'
import { useTranslation } from 'react-i18next'

interface IStatusBarProps {
  loadMessage?: string,
  className?: string,
  status: InstanceManagerStatus[],
  onClick?(): void
}

export const StatusBar = observer(function StatusBar({
  loadMessage='',
  className,
  onClick,
  status,
}: IStatusBarProps) {
  const {globalStore} = useStore();
  const countRunning = globalStore.countStatuses(status,'Running');
  const countStopped = globalStore.countStatuses(status,'Stopped');
  const countError = globalStore.countStatuses(status,'Error');
  const {t} = useTranslation();
  const [showErrorStatusDetailModal, setShowErrorStatusDetailModal] = useState<boolean>(false);

  const canShowErrorStatusDetail = () => {
    return status != null && status.length == 1 && status[0].status == InstanceManagerStatusStatusEnum.Error 
    && status[0].errorDetails != null && status[0].errorDetails.length > 0;
  }

  const showSingleErrorStatusDetail = () => {
    if (canShowErrorStatusDetail()) {
        // only for single error status
        setShowErrorStatusDetailModal(true);
      }
  };

  const getErrorStatusDetail = () => {
    if (canShowErrorStatusDetail()) {
      return status[0].errorDetails;
    }
    return "";
  };

  return (<>
      <span className={css.root}>
      {loadMessage ? <Chip color={'neutral'} label={loadMessage} /> :
          <span className={canShowErrorStatusDetail() ? css.hasError : ""}>
            {countRunning ? <Status color={'success'} label={countError ? countRunning + ' -'+ t('apps.running'): t('apps.running')} /> : null }
            {countStopped ? <Status color={'warning'} label={t('apps.stopped')}/> : null }
            {countError ? <Status color={'danger'} label={t('apps.error')} onClick={showSingleErrorStatusDetail} /> : null }
          </span>
        }
      </span>
      <Dialog
        bOpen={showErrorStatusDetailModal}
        title={t("error.securityModule.dialogTitle")}
        footer={false}
        onToggle={() => setShowErrorStatusDetailModal(false)}>
        {
          <div className={css.errorDetailsContent}>
            <pre>
              {getErrorStatusDetail()}
            </pre>
          </div>
        }
      </Dialog>
    </>
  )
})