// ========== #DELETE ==========
interface II {
  text: string
  num: number
  bool: boolean
  date: Date
}
class TestForm {
  init: II = {
    text: '',
    num: 0,
    bool: false,
    date: new Date()
  }
  // valid = Yup.object().shape({
  //   text: Yup.string(),
  //   num: Yup.number(),
  //   bool: Yup.boolean(),
  //   date: Yup.date()
  // })
}
export const _FORM = new TestForm()
// ===== ^^^^^ ===== ^^^^^ =====

export const SHUT_UP_TYPESCRIPT = ''
