// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RecordingInfo,
    RecordingInfoFromJSON,
    RecordingInfoToJSON,
    SwingSession,
    SwingSessionFromJSON,
    SwingSessionToJSON,
    ThreadDumpInfo,
    ThreadDumpInfoFromJSON,
    ThreadDumpInfoToJSON,
} from '../models';

export interface ManageSessionsApiGetMetricsRequest {
    instanceId: string;
}

export interface ManageSessionsApiGetSessionRequest {
    id: string;
}

export interface ManageSessionsApiListRecordingsRequest {
    instanceId?: string;
}

export interface ManageSessionsApiListThreadDumpsRequest {
    instanceId?: string;
}

export interface ManageSessionsApiRequestThreadDumpRequest {
    instanceId: string;
}

export interface ManageSessionsApiShutdownRequest {
    id: string;
    force?: string | null;
}

export interface ManageSessionsApiStartRecordingRequest {
    id: string;
}

export interface ManageSessionsApiStopRecordingRequest {
    id: string;
}

export interface ManageSessionsApiToggleStatisticsLoggingRequest {
    instanceId: string;
    enabled: boolean;
}

/**
 * no description
 */
export class ManageSessionsApi extends runtime.BaseAPI {

    /**
     * Returns session metrics for single session
     * Get Session Metrics
     */
    async getMetricsRaw(requestParameters: ManageSessionsApiGetMetricsRequest): Promise<runtime.ApiResponse<SwingSession>> {
        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling getMetrics.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/metrics/{instanceId}`.replace(`{${"instanceId"}}`, encodeURIComponent(String(requestParameters.instanceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwingSessionFromJSON(jsonValue));
    }

    /**
     * Returns session metrics for single session
     * Get Session Metrics
     */
    async getMetrics(requestParameters: ManageSessionsApiGetMetricsRequest): Promise<SwingSession> {
        const response = await this.getMetricsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns details of single session
     * Get Session details
     */
    async getSessionRaw(requestParameters: ManageSessionsApiGetSessionRequest): Promise<runtime.ApiResponse<SwingSession>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/session/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwingSessionFromJSON(jsonValue));
    }

    /**
     * Returns details of single session
     * Get Session details
     */
    async getSession(requestParameters: ManageSessionsApiGetSessionRequest): Promise<SwingSession> {
        const response = await this.getSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * List recording files from recording folder
     * List recordings
     */
    async listRecordingsRaw(requestParameters: ManageSessionsApiListRecordingsRequest): Promise<runtime.ApiResponse<Array<RecordingInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.instanceId !== undefined) {
            queryParameters['instanceId'] = requestParameters.instanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/record/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecordingInfoFromJSON));
    }

    /**
     * List recording files from recording folder
     * List recordings
     */
    async listRecordings(requestParameters: ManageSessionsApiListRecordingsRequest): Promise<Array<RecordingInfo>> {
        const response = await this.listRecordingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List thread dump files from thread dumps folder
     * List thread dumps
     */
    async listThreadDumpsRaw(requestParameters: ManageSessionsApiListThreadDumpsRequest): Promise<runtime.ApiResponse<Array<ThreadDumpInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.instanceId !== undefined) {
            queryParameters['instanceId'] = requestParameters.instanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/threadDump/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThreadDumpInfoFromJSON));
    }

    /**
     * List thread dump files from thread dumps folder
     * List thread dumps
     */
    async listThreadDumps(requestParameters: ManageSessionsApiListThreadDumpsRequest): Promise<Array<ThreadDumpInfo>> {
        const response = await this.listThreadDumpsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requests server to create a new thread dump for selected instance ID
     * Create Thread Dump
     */
    async requestThreadDumpRaw(requestParameters: ManageSessionsApiRequestThreadDumpRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling requestThreadDump.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/threadDump/{instanceId}`.replace(`{${"instanceId"}}`, encodeURIComponent(String(requestParameters.instanceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests server to create a new thread dump for selected instance ID
     * Create Thread Dump
     */
    async requestThreadDump(requestParameters: ManageSessionsApiRequestThreadDumpRequest): Promise<void> {
        await this.requestThreadDumpRaw(requestParameters);
    }

    /**
     * Requests clean session shutdown or forced session process kill if force parameter is true
     * Request session shutdown
     */
    async shutdownRaw(requestParameters: ManageSessionsApiShutdownRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shutdown.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/session/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests clean session shutdown or forced session process kill if force parameter is true
     * Request session shutdown
     */
    async shutdown(requestParameters: ManageSessionsApiShutdownRequest): Promise<void> {
        await this.shutdownRaw(requestParameters);
    }

    /**
     * Requests server to start session recording
     * Start Session Recording
     */
    async startRecordingRaw(requestParameters: ManageSessionsApiStartRecordingRequest): Promise<runtime.ApiResponse<SwingSession>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling startRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/record/start/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwingSessionFromJSON(jsonValue));
    }

    /**
     * Requests server to start session recording
     * Start Session Recording
     */
    async startRecording(requestParameters: ManageSessionsApiStartRecordingRequest): Promise<SwingSession> {
        const response = await this.startRecordingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requests server to stop session recording
     * Stop Session Recording
     */
    async stopRecordingRaw(requestParameters: ManageSessionsApiStopRecordingRequest): Promise<runtime.ApiResponse<SwingSession>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stopRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/record/stop/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwingSessionFromJSON(jsonValue));
    }

    /**
     * Requests server to stop session recording
     * Stop Session Recording
     */
    async stopRecording(requestParameters: ManageSessionsApiStopRecordingRequest): Promise<SwingSession> {
        const response = await this.stopRecordingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sets current value of statistics logging for this session
     * Set statistics logging value
     */
    async toggleStatisticsLoggingRaw(requestParameters: ManageSessionsApiToggleStatisticsLoggingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling toggleStatisticsLogging.');
        }

        if (requestParameters.enabled === null || requestParameters.enabled === undefined) {
            throw new runtime.RequiredError('enabled','Required parameter requestParameters.enabled was null or undefined when calling toggleStatisticsLogging.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/toggleStatisticsLogging/{instanceId}/{enabled}`.replace(`{${"instanceId"}}`, encodeURIComponent(String(requestParameters.instanceId))).replace(`{${"enabled"}}`, encodeURIComponent(String(requestParameters.enabled))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets current value of statistics logging for this session
     * Set statistics logging value
     */
    async toggleStatisticsLogging(requestParameters: ManageSessionsApiToggleStatisticsLoggingRequest): Promise<void> {
        await this.toggleStatisticsLoggingRaw(requestParameters);
    }

}
