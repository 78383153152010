// tslint:disable
// eslint-disable
/**
 * Webswing REST API
 * The public REST API provided by Webswing Server
 *
 * The version of the OpenAPI document: API version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ManageApplicationsApiCreateAppRequest {
    appPath: string | null;
    appName: string;
}

export interface ManageApplicationsApiRemoveAppRequest {
    appPath: string | null;
}

export interface ManageApplicationsApiStartAppRequest {
    appPath: string | null;
}

export interface ManageApplicationsApiStopAppRequest {
    appPath: string | null;
}

/**
 * no description
 */
export class ManageApplicationsApi extends runtime.BaseAPI {

    /**
     * Creates new application on defined path
     * Create Application
     */
    async createAppRaw(requestParameters: ManageApplicationsApiCreateAppRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appPath === null || requestParameters.appPath === undefined) {
            throw new runtime.RequiredError('appPath','Required parameter requestParameters.appPath was null or undefined when calling createApp.');
        }

        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName','Required parameter requestParameters.appName was null or undefined when calling createApp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appPath !== undefined) {
            queryParameters['appPath'] = requestParameters.appPath;
        }

        if (requestParameters.appName !== undefined) {
            queryParameters['appName'] = requestParameters.appName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/create`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates new application on defined path
     * Create Application
     */
    async createApp(requestParameters: ManageApplicationsApiCreateAppRequest): Promise<void> {
        await this.createAppRaw(requestParameters);
    }

    /**
     * Removes existing application on defined path
     * Remove Application
     */
    async removeAppRaw(requestParameters: ManageApplicationsApiRemoveAppRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appPath === null || requestParameters.appPath === undefined) {
            throw new runtime.RequiredError('appPath','Required parameter requestParameters.appPath was null or undefined when calling removeApp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appPath !== undefined) {
            queryParameters['appPath'] = requestParameters.appPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/remove`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes existing application on defined path
     * Remove Application
     */
    async removeApp(requestParameters: ManageApplicationsApiRemoveAppRequest): Promise<void> {
        await this.removeAppRaw(requestParameters);
    }

    /**
     * Initialize application on defined path and make it accessible for users
     * Enable Application
     */
    async startAppRaw(requestParameters: ManageApplicationsApiStartAppRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appPath === null || requestParameters.appPath === undefined) {
            throw new runtime.RequiredError('appPath','Required parameter requestParameters.appPath was null or undefined when calling startApp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appPath !== undefined) {
            queryParameters['appPath'] = requestParameters.appPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/start`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Initialize application on defined path and make it accessible for users
     * Enable Application
     */
    async startApp(requestParameters: ManageApplicationsApiStartAppRequest): Promise<void> {
        await this.startAppRaw(requestParameters);
    }

    /**
     * Stops the application configured on defined path and makes it unavailable for users
     * Disable Application
     */
    async stopAppRaw(requestParameters: ManageApplicationsApiStopAppRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appPath === null || requestParameters.appPath === undefined) {
            throw new runtime.RequiredError('appPath','Required parameter requestParameters.appPath was null or undefined when calling stopApp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appPath !== undefined) {
            queryParameters['appPath'] = requestParameters.appPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/stop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stops the application configured on defined path and makes it unavailable for users
     * Disable Application
     */
    async stopApp(requestParameters: ManageApplicationsApiStopAppRequest): Promise<void> {
        await this.stopAppRaw(requestParameters);
    }

}
