// .core
import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'

// styles
import css from './Logs.module.scss'
import { useStore } from 'stores';
import { LogsTable } from './partial/LogsTable';
import { observer } from 'mobx-react';
import { HeadingRow } from './partial/HeadingRow';
import { parseObject, toPath } from 'utils';
import { changeTitle } from 'views/routes';
import { useTranslation } from 'react-i18next';

interface LogParamTypes {
    type: "audit" | "server" | "admin" | "session";
    app:string;
    id:string;
    spId:string;
}

export const Logs = observer(() => {

    const {logsStore, globalStore} = useStore()
    const { type, app, id, spId } = useParams<LogParamTypes>();
    const {t} = useTranslation()

    const loadData = () => {
        if(parseObject(logsStore.logsOverview).isEmpty()) {
            logsStore.loadLogsOverview(app)
            .then(()=> {
                logsStore.refresh(app,type)
            });
        } else {
            logsStore.refresh(app,type)
        }
    }

    changeTitle(t('routes.logs')+' - ' +type);

    
    useEffect(() => {
        globalStore.updateApp(app);
    },[app]);
    
    useEffect(()=> {
        logsStore.setType(type)
        if(type ==='session' && app && id) {
            logsStore.setInstanceId(id);
            logsStore.setSpLogId(spId);
        } else {
            logsStore.setInstanceId('');
        }
        loadData()
        return () => logsStore.clearInterval();
    },[type, id, spId])

    return (
        <div className={css.root}>
            {
                logsStore.type?.url === 'admin' &&
                <HeadingRow 
                    title={t('logs.admin')}
                    type={type}
                    onDownloadClick={()=>logsStore.downloadLogs(type)}
                />
            }
            {
                (type === 'audit' || type === 'server') &&
                <HeadingRow 
                    title={logsStore.serverLogId!}
                    buttons={logsStore.dropdownServerButtons}
                    dropdownLabel={t('logs.clusterServer')}
                    type={type}
                    onDownloadClick={()=>logsStore.downloadLogs(type)}
                />
            }
            {
                logsStore.type?.url === 'sessionpool' &&
                <HeadingRow 
                    title={logsStore.spLogId!}
                    buttons={logsStore.dropdownSpButtons}
                    dropdownLabel={t('logs.sp')}
                    type={type}
                    onDownloadClick={()=>logsStore.downloadLogs(type)}
                />
            }
            {
                (type === 'session' && id) &&
                <HeadingRow 
                    title={t('logs.appInst')+' '+ id}
                    type={type}
                    onDownloadClick={()=>logsStore.downloadSessionLogs(toPath('',app),logsStore.spLogId,id)}
                />
            }
            <LogsTable type={type} app={app} id={id} />
        </div>
    )
})
