// .core
import React, { ReactNode } from 'react'
// components
import { Icon } from '../Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './Chip.module.scss'
import { CoinColor, CoinSize } from '../Coin/Coin'
interface IChipProps {
  children?: ReactNode
  bDisabled?: boolean
  className?: string
  label?: string
  color?: CoinColor
  type?: 'fill' | 'outline'
  onClick?(): void
  toggleHover?(): void
  onDelete?(): void
  /**
   * Coin size
   */
   size?: CoinSize
}

export const Chip = ({
  children,
  bDisabled,
  className,
  color = 'blue',
  size = 'medium',
  label,
  type = 'fill',
  onClick,
  toggleHover,
  onDelete,
}: IChipProps) => {
  const _onClick = () => {
    !bDisabled && onClick && onClick()
  }

  const _onDelete = () => {
    !bDisabled && onDelete && onDelete()
  }

  return (
    <div
      className={cx(
        css.wChip,
        css[type],
        css[size],
        css[color],
        onClick && 'gClickable',
        bDisabled && css.bDisabled,
        className
      )}
      onMouseEnter={toggleHover}
       onMouseLeave={toggleHover}
      onClick={_onClick}>
      {/* LABEL */}
      {label && <span>{label}</span>}
      {children? children:null}
      {/* DELETE */}
      {onDelete && <Icon name="x-circle" onClick={_onDelete} />}
    </div>
  )
}
