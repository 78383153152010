// .core
import React from 'react'
// components
import { IButtonDefaultProps } from '../ButtonDefault/ButtonDefault'
// libraries
// import Ink from 'react-ink'
import cx from 'classnames'
// styles
import css from '../ButtonDefault/ButtonDefault.module.scss'

interface IButtonUIProps extends IButtonDefaultProps {
  children: React.ReactNode
}

/**
 * `UI wrapper` variant a button
 *
 * Wrapps its children in a HTML button
 */
export const ButtonUI = ({ bDisabled, children, className, onClick, color = '', size= ''}: IButtonUIProps) => {
  return (
    <button className={cx(css.wButtonDefault, className, css[color], css[size])} disabled={bDisabled} onClick={onClick}>
      {children}

      {/* {!bNoRipple && <Ink />} */}
    </button>
  )
}
