// core
import React, { useEffect } from "react";
import cx from 'classnames'
// services
import {useStore} from 'stores';
// components
import { PageHeader, NoData, Button, Flex, Grid, Icon } from 'components';
import {ServerCard} from './partials/ServerCard'
import {SpCard} from './partials/SpCard'
// utils
import { useInterval } from 'utils'

// translation
import cssTab from 'components/containers/Tabs/Tabs.module.scss';
import cssButton from 'components/basic/Button/variants/ButtonDefault/ButtonDefault.module.scss'
import { changeTitle } from '../routes';
import { observer } from "mobx-react";
import { TableView } from "./partials/TableView";
import { useLocation } from "react-router-dom";
import { Dropdown } from "components/basic/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";

export const Overview = observer(() => {
  changeTitle('Overview')
  const { overviewStore, globalStore } = useStore()
  const { pathname } = useLocation()
  const { t } = useTranslation();
  const isServers = pathname.includes('servers')
  const isSessionpools = pathname.includes('sessionpools')
  
  useEffect(()=>{
    overviewStore.loadOverview();
    globalStore.loadPaths();
  },[])
  useInterval(() => {
    overviewStore.loadOverview();
    globalStore.loadPaths();
  }, 5000);

  return (
    <div>
      { 
        !isSessionpools && 
        <>
          <PageHeader title={overviewStore.isCluster? t('overview.clusters'): t('overview.server')} />
          <Grid container spacing="sm">
            {
            overviewStore.filteredServers?.length ? overviewStore.filteredServers?.map(item => (
              <Grid key={item.id} item xs={12} md={6}>
                <ServerCard server={item} />
              </Grid>
            )):
            <NoData message={t('overview.noServers')} />
            }
          </Grid>
        </>
      }
      {
        (!isServers && overviewStore.isCluster) &&
        <>
          <Flex justifyContent={'space-between'} alignItems='center'>
            <PageHeader title={t('sessions.sp_plural')} />
            <Flex spacing={2}>
              <Button icon="apps" color={'grid' === overviewStore.spView?'blue':'neutral'} onClick={() => overviewStore.setSpView('grid')} />
              <Button icon="list" color={'table' === overviewStore.spView?'blue':'neutral'} onClick={() => overviewStore.setSpView('table')} />
              {overviewStore.sessionPools!.length>0 &&
                <Dropdown
                  position='right'
                  buttons={
                    [
                      {label: t('overview.drainAll'), icon:'opacity', onClick:()=>overviewStore.drainAllSessionPools()},
                      {label: t('overview.resumePools'), icon:'restore', onClick:()=>overviewStore.resumeAllSessionPools()},
                      !overviewStore.hasRunningSessions ?
                      {
                        label: t('overview.stopAllPools'), 
                        icon:'power_settings_new', 
                        onClick:()=>overviewStore.stopAllSessionPools(),
                      }:
                      {
                        label: t('overview.shutdownAllSessions'), 
                        icon:'power_settings_new', 
                        onClick:()=>overviewStore.shutdownAllSessions(), 
                        buttons:[{label: t('overview.forceKillAllSessions'), onClick:()=>overviewStore.forceKillAllSessions()}],
                      }
                    ]
                  }
                >
                  <span className={cx(cssButton.wButtonDefault,cssButton.medium, cssButton.neutral, cssButton.icon)}>
                    <Icon name={'more_vert'} />
                  </span>
                </Dropdown>
              }
            </Flex>
          </Flex>
          <div className={cx(cssTab.wTabContent)}>
            {
              overviewStore.spView === 'grid' &&
              <Grid container spacing="sm">
                {overviewStore.filteredSPs?.length? overviewStore.filteredSPs?.map(pool => (
                  <Grid key={pool.id} item xs={12} md={6}>
                    <SpCard clusterservers={overviewStore.servers} sp={pool} />
                  </Grid>

                )):<NoData message={t('overview.noSps')} />}
              </Grid>
            }
            {
              overviewStore.spView === 'table' &&
              <TableView sp={overviewStore.sessionPools!} />
            }
          </div>
        </>
      }
    </div>
  );
});