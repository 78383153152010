// .core
import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
// components
import { Card, AvatarWithTitle, Flex, StatusBar } from 'components'
// styles
import css from './AppHeader.module.scss'
// utils
import { ApiServerUrl } from 'utils'
// stores
import { useStore } from 'stores'

interface IAppHeaderProps {
  app:string,
  className?: string,
  onClick?(): void
}

export const AppHeader = observer(function AppHeader({
  app,
  className,
  onClick,
}: IAppHeaderProps) {
  const {globalStore, sessionsStore} = useStore();
  const sessions = sessionsStore.sessions
  const appObject = globalStore.paths?.find(path=>path.path===`/${app}`)
  const {t} = useTranslation()
  
  return (
    <Card className={cx(css.root, className)} onClick={onClick}>
      <Flex style={{padding: '0 1rem'}}>
        <Flex bColumn className={css.AvatarCol} flex={2}>
          {
            appObject ?
            <>
              <AvatarWithTitle
                size='medium'
                src={ApiServerUrl + "/" + app + '/rest/appicon'}
                title={appObject?.name}
              />
            </>
            :
            <AvatarWithTitle
              size='medium'
              icon='apps'
              title={'All Sessions'}
            />
          }
        </Flex>
        {
          appObject !== undefined && <>
            <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'} >
              <span className={css.label}>{t('general.status')}:</span>
              <StatusBar status={appObject?.status!} loadMessage={''} />
            </Flex>
            <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'}>
              <span className={css.label}>{t('apps.path')}:</span>
              <a href={appObject?.url} target='_blank' rel="noreferrer">{appObject?.path}</a>
            </Flex>
          </>
        }
        <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'}>
          <span className={css.label}>{t('status.running')}:</span>
          {sessions.sessions?.length}
        </Flex>
        <Flex bColumn flex={1} className={css.smCol} justifyContent={'center'}>
          <span className={css.label}>{t('status.finished')}:</span>
          {sessions.closedSessions?.length}
        </Flex>
      </Flex>
    </Card>
  )
})
