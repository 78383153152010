// core
import React from 'react';
import cx from 'classnames';
// components
import { Coin, Switch } from 'components';

// styles
import css from '../Applications.module.scss';
import { observer } from 'mobx-react';
import { useStore } from 'stores';
import { ITab } from 'utils';

export interface IDropItemProps {
  bLast:boolean
  item:ITab
  onClick: (key:string)=>void
}

export const DropItem = observer(({bLast,item, onClick}:IDropItemProps) => {
  const {configStore} = useStore()
  return (
    <li className={cx({[css.fitem]:bLast})} key={item.key}>
      <Switch className={css.popSwitch} position={'right'} value={item.activeSP} onClick={()=>configStore.activeSpSwitch(item)}>
        <span onClick={()=>onClick(item.key)}>
        {item.key}
        {item.wasEdited && <Coin color='danger' size='small' className={css.coin}>  </Coin>}
        </span>
      </Switch>
    </li>
  );
});