import React from "react";
import cx from 'classnames';
import { IComponentProps, Icon, showToastOk } from "components";
import { useStore } from "stores";
import css from '../Sessions.module.scss'
import { Dropdown } from "components/basic/Dropdown/Dropdown";
import { SwingSession, SwingSessionStatusEnum } from "gen";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

interface IActionCellProps extends IComponentProps{
    bSession?: boolean,
    session:SwingSession,
}

export const ActionCell = observer(({bSession=false, session, className, classes}:IActionCellProps) => {
    const history = useHistory();
    const {permissionsStore, sessionsStore } = useStore();
    const {t} = useTranslation();
    const kill = (session:SwingSession, force?:boolean) => {
        if (isFinished(session)) {
            return;
        }
        if(force) {
            sessionsStore.forceKill(session)
        } else {
            sessionsStore.kill(session)
        }
        history.goBack()
    }

    const view = (session: SwingSession) => {
        history.push('/session' + session.applicationPath +'/'+ session.id)
        sessionsStore.setActiveTab('view');
    }

    const logs = (session: SwingSession) => {
        history.push('/session' + session.applicationPath +'/'+ session.id)
        sessionsStore.setActiveTab('logs');
    }

    const copyID = () => {
        if(session.id) {
            navigator.clipboard.writeText(session.id);
            showToastOk(t('sessions.copyInstIDMsg'));
        }
    }

    const isFinished = (session: SwingSession) => {
        return session.status !== SwingSessionStatusEnum.RUNNING;
    };
    
    return (
        <Dropdown
            className={cx(css.actionCellDropdown,className)}
            buttons={
                bSession ?
                [
                    {shouldShow: permissionsStore.get('requestThreadDump') && !isFinished(session), label: t('sessions.threadDump'), icon:'list', onClick:()=>sessionsStore.requestThreadDump(session, bSession)},
                    {shouldShow: permissionsStore.get('startRecording') && session.canRecord && !isFinished(session), label: t('sessions.record'), icon:'fiber_manual_record', onClick:()=>sessionsStore.startRecording(session, bSession)},
                    {label: t('sessions.copyInstID'), icon:'content_copy', onClick:()=>copyID()},
                    {
                        shouldShow: !isFinished(session),
                        label: t('sessions.shutdown'),
                        icon:'power_settings_new', 
                        onClick:()=>kill(session), 
                        buttons:[{label: t('sessions.forceKill'), icon:'power_settings_new', onClick:()=>kill(session, true)}]
                    },
                ]
                :
                [
                    {label: t('sessions.view'), icon:'view_quilt', onClick:()=>view(session)},
                    {shouldShow: permissionsStore.get('requestThreadDump') && !isFinished(session), label: t('sessions.threadDump'), icon:'list', onClick:()=>sessionsStore.requestThreadDump(session)},
                    {shouldShow: permissionsStore.get('startRecording') && session.canRecord && !isFinished(session), label: t('sessions.record'), icon:'fiber_manual_record', onClick:()=>sessionsStore.startRecording(session)},
                    {label: t('sessions.copyInstID'), icon:'content_copy', onClick:()=>copyID()},
                    {shouldShow: permissionsStore.get('logsView') && session.loggingEnabled, label: t('routes.logs'), icon:'list', onClick:()=>logs(session)},
                    {
                        shouldShow: !isFinished(session),
                        label: t('sessions.shutdown'), 
                        icon:'power_settings_new', 
                        color:'danger',
                        onClick:()=>sessionsStore.kill(session), 
                        buttons:[{label: t('sessions.forceKill'), icon:'power_settings_new', onClick:()=>sessionsStore.forceKill(session)}]
                    },
                ]
            }
        >
            <Icon name={'more_vert'} />
        </Dropdown>
    )
})