// core
import React, { memo } from 'react'

import cx from 'classnames'

// components
import { IComponentProps } from 'components'

// styles
import css from './Backdrop.module.scss'

export interface IBackdropProps extends IComponentProps {
  /**
   * Whether bacdrop should be visible
   */
  dark?:boolean
  visible: boolean
  /**
   * Css z-index value
   */
  zIndex?: number
  /**
   * Callback to run on backdrop click
   */
  onClick?: (e: any) => void
}

export const Backdrop = memo(function Backdrop({
  visible,
  dark=false,
  zIndex,
  onClick,
  className,
}: IBackdropProps) {
  return (
    <div
      className={cx(css.root, visible ? css.visible : '', className, {[css.dark]:dark})}
      style={{ zIndex }}
      onClick={onClick}
    />
  )
})
